<template>
  <div class="is-inline-flex">
    <component v-if="link" :is="disabled ? 'span' : 'router-link'" :to="link" class="action-button">
      <VDropdown popper-class="table-action-popper" :disabled="!tooltipText" :triggers="['hover']" :popper-triggers="['hover']">
        <button class="button is-small is-info is-outlined" :disabled="disabled" :class="buttonClass">
          <i :class="iconClass"></i>
        </button>
        <template #popper>
          {{ tooltipText }}
        </template>
      </VDropdown>
    </component>
    <a href="#" @click.prevent="" v-else class="action-button">
      <VDropdown popper-class="table-action-popper" :disabled="!tooltipText" :triggers="['hover']" :popper-triggers="['hover']">
        <button class="button is-small is-info is-outlined" :disabled="disabled" :class="buttonClass" @click="$attrs.onClick">
          <i :class="iconClass"></i>
        </button>
        <template #popper>
          {{ tooltipText }}
        </template>
      </VDropdown>
    </a>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "TableActionButton",
  props: {
    link: {
      type: [String, Object],
    },
    iconClass: {
      type: [String, Array, Object],
      required: true
    },
    buttonClass: {
      type: [String, Array, Object],
    },
    tooltipText: {
      type: String
    },
    tooltipPosition: {
      type: String,
      default: 'is-bottom'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.action-button {
  margin-left: 10px;
  i.mdi {
    padding-top: 2px;
  }
}
</style>
<style>
.table-action-popper.table-action-popper {
  .v-popper__arrow-inner {
    border-color: #2c7bff;
  }
  .v-popper__arrow-outer {
    border: none;
  }
  .v-popper__inner {
    background: #2c7bff !important;
    color: #fff !important;
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 400;
    box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
    border: none;
  }
}
</style>