import {TableFilter, FilterType} from "@/models/TableFilter";

const filterTypeMapping = Object.freeze({
  text: FilterType.TEXT,
  textarea: FilterType.TEXT,
  number: FilterType.NUMBER,
  select: FilterType.ENUM,
  radio: FilterType.ENUM,
  checkbox: FilterType.ENUM,
  datetime: FilterType.DATETIMERANGE
})

export default function (customFields) {
  const filters = []
  for (const [,field] of Object.entries(customFields)) {
    const filterKind = filterTypeMapping[field.kind]
    if (!filterKind) {
      continue
    }
    const options = []
    if (filterKind == FilterType.ENUM) {
      field.options.choices.forEach(choice => {
        options.push({key: choice, value: choice})
      })
    }
    filters.push(new TableFilter({
      name: `custom_fields[${field.key}]`,
      type: filterKind,
      displayName: field.label,
      enumValues: options
    }))
  }
  return filters
}
