export default {
  namespaced: true,
  state: {
    tableSettings: {}
  },
  getters: {
    getSettingsFor: state => tableId => {
      if (!Object.hasOwn(state.tableSettings, tableId)) {
        return []
      }
      return state.tableSettings[tableId]
    }
  },
  mutations: {
    setTableSettings(state, tableSettings) {
      state.tableSettings = tableSettings
    }
  },
  actions: {
    storeTableSettings(context, tableSettings) {
      const tableData = {}
      tableSettings.forEach(data => {
        if (!Object.hasOwn(tableData, data.table_id)) {
          tableData[data.table_id] = []
        }
        tableData[data.table_id].push(data)
      })
      context.commit('setTableSettings', tableData)
    }
  }
}
