<template>
  <div class="blank-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BlankLayout"
}
</script>

<style scoped>
  .blank-wrapper {
    padding: 30px;
  }
</style>