<template>
  <div>
    <slot name="offline" v-if="this.isOffline"></slot>
  </div>
</template>

<script>
export default {
  name: "Network",
  data() {
    return {
      isOffline: null
    }
  },
  methods: {
    offlineStatusHandler() {
      this.isOffline = true
    }
  },
  beforeMount() {
    this.isOffline = !window.navigator.onLine
  },
  mounted() {
    window.addEventListener('offline', this.offlineStatusHandler)
  },
  beforeUnmount() {
    window.removeEventListener('offline', this.offlineStatusHandler)
  }
}
</script>
