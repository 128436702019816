<template>
  <div v-if="tableId" class="saved-filters-container">
    <b-modal v-model="editModal" :can-cancel="false">
      <ld-modal-content :title="`${editModalAction == 'create' ? 'Create' : 'Update'} Saved Filter`">
        <template #default>
          <div>
            <input-with-validation field-label="Filter Name" b-name="input" v-model="editingObject.name"></input-with-validation>
            <input-with-validation v-if="!editingObject.is_system" field-label="Is Default" b-name="switch" v-model="editingObject.is_default"></input-with-validation>
            <input-with-validation v-if="$store.state.currentUser.role.kind == 'lookdeep'" field-label="Is System" b-name="switch" v-model="editingObject.is_system"></input-with-validation>
          </div>
        </template>
        <template #footer>
          <div class="is-centered">
            <button class="button is-rounded is-text" @click="editModal = false">Cancel</button>
            <button class="button is-rounded is-primary" @click="saveEditedSetting(editingObject, this.editModalAction)">Save</button>
          </div>
        </template>
      </ld-modal-content>
    </b-modal>
    <input-with-validation class="view-autocomplete" field-label="Saved Filters">
      <template #custom>
        <b-autocomplete :model-value="selectedSettingModel" field="name" open-on-focus :data="filteredTableViews" icon="filter-variant" @select="view => { selectSetting(view, true) }">
          <template #default="{ option }">
            <div class="autocomplete__row">
              <span class="setting-name">{{ option.name }}</span>
              <template v-if="!option.is_ld_default">
                <b-tag v-if="option.is_default" type="is-success" size="is-small" rounded>default</b-tag>
                <button @click.stop="openEditModal(option)" class="button is-primary is-small autocomplete__edit">
                  <i class="mdi mdi-pencil-outline"></i>
                </button>
                <button @click.stop="deleteConfirmation(option)" class="button is-danger is-small autocomplete__edit">
                  <i class="mdi mdi-delete-outline"></i>
                </button>
              </template>
            </div>
          </template>
        </b-autocomplete>
      </template>
    </input-with-validation>
    <div class="save-buttons">
      <input-with-validation field-label="&nbsp;" v-if="isSettingAltered">
        <template #custom>
          <button class="button is-text" @click="saveFilters">Save</button>
        </template>
      </input-with-validation>
      <input-with-validation field-label="&nbsp;">
        <template #custom>
          <button class="button is-primary is-light" @click="saveAsFilter">Save As</button>
        </template>
      </input-with-validation>
    </div>
    <input-with-validation field-label="&nbsp;" class="ml-auto" v-if="!hideColumnSelector">
      <template #custom>
        <b-dropdown multiple position="is-bottom-left" class="settings-dropdown">
          <template #trigger>
            <b-button type="is-primary" class="column-dropdown-button" icon-left="view-column-outline" icon-right="menu-down">
              <span class="pr-2">Columns</span>
            </b-button>
          </template>
          <table-column-settings v-if="selectedColumnForSettings" :column="selectedColumnForSettings"
                                 v-model:settings="columnSettings[selectedColumnForSettings.attrs.field]"
                                 @close="selectedColumnForSettings = null"></table-column-settings>
          <draggable v-else v-model="availableColumns" item-key="attrs.field" @change="columnsOrderChanged">
            <template #item="{element: column}">
              <b-dropdown-item class="column-dropdown-item" custom>
                <b-checkbox v-model="selectedColumnsModel" :native-value="column.attrs.field" @change="columnsOrderChanged"><span>{{ column.attrs.label }}</span></b-checkbox>
                <div class="column-settings-icons">
                  <i title="Column is frozen" v-if="columnSettings[column.attrs.field]?.is_sticky" class="mdi mdi-pin"></i>
                  <i title="Column wrapping is enabled" v-if="columnSettings[column.attrs.field]?.is_wrapped" class="mdi mdi-wrap"></i>
                </div>
                <i @click.stop="openColumnSettings(column)" class="mdi mdi-dots-vertical pl-3"></i>
              </b-dropdown-item>
            </template>
          </draggable>
        </b-dropdown>
      </template>
    </input-with-validation>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InputWithValidation from "@/components/validation/InputWithValidation.vue";
import LdModalContent from "@/components/LdModalContent.vue";
import to from "@/lib/to";
import draggable from "vuedraggable";
import TableColumnSettings from "@/components/table/TableColumnSettings";

const SYSTEM_DEFAULT_FILTER = Object.freeze({ name: 'System Default', is_ld_default: true })
export default {
  name: "TableUserSettings",
  components: {TableColumnSettings, LdModalContent, InputWithValidation, draggable},
  emits: ['setting-changed', 'update:defaultFilter', 'update:selectedColumns', 'column-settings'],
  props: {
    tableId: { type: String },
    shouldLoadDefault: { type: Boolean },
    currentFilters: { type: Object, default: () => { return {} } },
    defaultFilter: { type: Object, default: null, required: false },
    hasDefaultFilters: { type: Boolean, default: false, required: false },
    order: { type: Object, default: null, required: false },
    perPage: { type: Number, default: null, required: false },
    defaultPerPage: { type: Number, default: null, required: false },
    defaultColumns: { type: Array, default: null, required: false },
    selectedColumns: { type: Array, default: () => [], required: false },
    hideColumnSelector: { type: Boolean, required: false, default: false }
  },
  computed: {
    ...mapGetters('TableFiltersStore', ['getSettingsFor']),
    hasSettings() {
      return this.tableSettings.length > 0
    },
    filteredTableViews() {
      let arr = this.tableSettings
      if (this.hasDefaultFilters) {
        arr = [SYSTEM_DEFAULT_FILTER].concat(this.tableSettings)
      }
      // only lookdeep user can see system setting/view
      if (this.$store.state.currentUser.role.kind != 'lookdeep') {
        arr = arr.filter(x => !x.is_system)
      }
      return arr
    },
    isSettingAltered() {
      if (!this.selectedSetting || this.selectedSetting.is_ld_default) {
        return false
      }
      const originalFilters = this.selectedSetting.metadata.filters;
      if (Object.keys(originalFilters).length != Object.keys(this.currentFilters).length) {
        return true
      }

      for (const [key, value] of Object.entries(originalFilters)) {
        if (!Object.hasOwn(this.currentFilters, key)) {
          return true
        }
        if (this.currentFilters[key] && (value.length != this.currentFilters[key].length)) {
          return true
        }
        if (this.arrayToSortedString(value) != this.arrayToSortedString(this.currentFilters[key])) {
          return true
        }
      }

      const originalOrder = this.selectedSetting.metadata.order
      if (originalOrder) {
        if (originalOrder.field != this.order.field || originalOrder.direction != this.order.direction) {
          return true
        }
      }

      const originalPerPage = this.selectedSetting.metadata.per_page
      if (originalPerPage) {
        if (originalPerPage && this.perPage != originalPerPage) {
          return true
        }
      } else {
        if (this.perPage != this.defaultPerPage) {
          return true
        }
      }

      const originalSelectedColumns = this.selectedSetting.metadata.visible_columns
      if (originalSelectedColumns) {
        if (this.selectedColumnsModel.toString() != originalSelectedColumns.toString()) {
          return true
        }
      } else {
        if (this.selectedColumnsModel.toString() != this.defaultColumns.map(x => x.attrs.field).toString()) {
          return true
        }
      }

      const originalAvailableColumns = this.selectedSetting.metadata.available_columns
      if (originalAvailableColumns) {
        if (originalAvailableColumns.toString() != this.availableColumns.map(x => x.attrs.field).toString()) {
          return true;
        }
      }

      const originalColumnSettings = this.selectedSetting.metadata.column_settings
      if (originalColumnSettings) {
        if (JSON.stringify(originalColumnSettings) !== JSON.stringify(this.columnSettings)) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    ...mapActions('TableFiltersStore', ['storeTableSettings']),
    arrayToSortedString(arr) {
      if (!Array.isArray(arr)) {
        return ''
      }
      return arr.slice().sort().toString()
    },
    openEditModal(setting) {
      document.body.click()
      this.openModal({...setting}, 'update')
    },
    deleteConfirmation(setting) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to delete '${setting.name}' filter ?`,
        type: 'is-danger',
        confirmText: 'Yes',
        focusOn: 'cancel',
        confirmCallback: async (value, { close }) => {
          const [err] = await to(this.$http.delete(`/table_user_settings/${setting.id}`))
          if (!err) {
            await this.fetchTableSettings()
            if (!this.selectedSetting?.is_ld_default && this.selectedSetting?.id == setting.id) {
              this.selectSetting(SYSTEM_DEFAULT_FILTER, true)
            }
            close()
          }
        }
      })
    },
    /**
     *
     * @param editingObject {Object}
     * @param action {('create', 'update')}
     */
    async saveEditedSetting(editingObject, action = 'update') {
      if (editingObject) {
        editingObject.metadata = {
          filters: action == 'create' || editingObject.id == this.selectedSetting?.id ? {...this.currentFilters} : {...editingObject.metadata.filters},
          order: this.order,
          per_page: this.perPage,
          visible_columns: [...this.selectedColumnsModel],
          available_columns: this.availableColumns.map(x => x.attrs.field),
          column_settings: this.columnSettings
        }

        if (editingObject.is_system) {
          editingObject.is_default = false
        }

        let actionUrl = '/table_user_settings/', actionMethod = 'post'
        if (action == 'update') {
          actionUrl += editingObject.id
          actionMethod = 'put'
        }
        const [err, response] = await to(this.$http[actionMethod](actionUrl, editingObject))
        if (!err) {
          this.editModal = false
          this.editingObject = {}
          await this.fetchTableSettings()
          this.selectSetting(response.data)
        }
      }
    },
    async fetchTableSettings() {
      const [err, response] = await to(this.$http.get('/table_user_settings'))
      if (!err) {
        this.storeTableSettings(response.data)
        this.setTableSettingsFromStore()
      }
    },
    saveFilters() {
      if (this.selectedSetting && !this.selectedSetting.is_ld_default) {
        this.saveEditedSetting(this.selectedSetting, 'update')
      }
    },
    saveAsFilter() {
      this.openModal({table_id: this.tableId, is_default: false}, 'create')
    },
    /**
     * @param action {('create', 'update')}
     */
    openModal(editingObject, action = 'update') {
      this.editModalAction = action
      this.editModal = true
      this.editingObject = editingObject
    },
    getDefaultSetting() {
      if (this.hasSettings) {
        return this.tableSettings.find(view => view.is_default)
      }
      return null
    },
    selectSetting(view, isAutocomplete = false) {
      if (!view && isAutocomplete) {
        return;
      }
      let v = null
      if (view) {
        this.selectedSettingModel = view.name
        this.selectedSetting = view
        v = view.is_ld_default ? null : view
      }
      this.$emit('setting-changed', v)
    },
    setTableSettingsFromStore() {
      this.tableSettings = this.getSettingsFor(this.tableId)
    },
    setFilterFromUrlFilterId(filterId) {
      if (filterId) {
        const savedFilter = this.tableSettings.find(x => x.id == filterId)
        if (savedFilter) {
          this.selectedSetting = savedFilter
          this.selectedSettingModel = savedFilter.is_system && this.$store.state.currentUser.role.kind != 'lookdeep' ? 'Custom' : savedFilter.name
        }
      }
    },
    emitSelectedColumns() {
      this.$emit('update:selectedColumns', this.selectedColumnsModel)
    },
    emitColumnSettings() {
      this.$emit('column-settings', this.columnSettings)
    },
    columnsOrderChanged() {
      const order = this.availableColumns.map(x => x.attrs.field)
      this.selectedColumnsModel.sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b)
      })
      this.emitSelectedColumns()
    },
    createColumnSettingsObject(column) {
      if (!Object.hasOwn(this.columnSettings, column.attrs.field)) {
        this.columnSettings[column.attrs.field] = {}
      }
    },
    openColumnSettings(column) {
      this.createColumnSettingsObject(column)
      this.selectedColumnForSettings = column
    }
  },
  watch: {
    currentFilters(newVal) {
      if (Object.keys(newVal).length == 0 && !this.$route.query.filter_id) {
        this.selectedSetting = null
        this.selectedSettingModel = ''
      }
    },
    '$route': {
      handler(to) {
        if (this.selectedSetting && this.selectedSetting.is_ld_default) {
          return;
        }
        this.setFilterFromUrlFilterId(to.query.filter_id || this.defaultFilterModel?.id)
      }
    },
    selectedSetting: {
      handler(newVal) {
        if (this.hideColumnSelector) {
          return;
        }
        let newSelectedColumns = this.defaultColumns.filter(x => x.attrs.isChecked).map(x => x.attrs.field)
        let newAvailableColumns = [...this.defaultColumns]
        let newColumnSettings = {}
        if (newVal && !newVal.is_ld_default) {
          const savedColumns = newVal?.metadata?.visible_columns
          if (savedColumns) {
            newSelectedColumns = savedColumns
          }
          const availableColumnsOrder = newVal?.metadata?.available_columns
          if (availableColumnsOrder) {
            const newColumns = this.defaultColumns.filter(x => {
              return !availableColumnsOrder.includes(x.attrs.field)
            })

            if (newColumns.length) {
              const newColumnsField = newColumns.map(x => x.attrs.field)
              const visibleNewColumnsField = newColumns.filter(x => x.attrs.isChecked)
              newSelectedColumns.push(...visibleNewColumnsField)
              availableColumnsOrder.push(...newColumnsField)
            }

            newAvailableColumns.sort((a, b) => {
              return availableColumnsOrder.indexOf(a.attrs.field) - availableColumnsOrder.indexOf(b.attrs.field)
            })
          }
          const savedColumnSettings = newVal?.metadata?.column_settings
          if (savedColumnSettings) {
            newColumnSettings = savedColumnSettings
          }
        }

        this.availableColumns = newAvailableColumns
        this.selectedColumnsModel = newSelectedColumns
        this.columnSettings = {...newColumnSettings}
        this.emitSelectedColumns()
        this.emitColumnSettings()
      },
      immediate: true
    }
  },
  mounted() {
    if (this.tableId) {
      this.setTableSettingsFromStore()
    }
    this.defaultFilterModel = this.getDefaultSetting()
    this.setFilterFromUrlFilterId(this.$route.query.filter_id || this.defaultFilterModel?.id)
    if (this.selectedSetting.is_system) {
      this.defaultFilterModel = this.selectedSetting
    }
    this.$emit('update:defaultFilter', this.defaultFilterModel || null)
    if (!this.shouldLoadDefault) {
      this.selectedSettingModel = 'Custom'
    }
    if (!this.hasDefaultFilters) {
      this.selectedSettingModel = ''
    }
  },
  data() {
    return {
      selectedSettingModel: 'System Default',
      selectedSetting: SYSTEM_DEFAULT_FILTER,
      tableSettings: [],
      editModal: false,
      editingObject: {},
      editModalAction: null,
      // default + dynamic columns
      availableColumns: [],
      columnSettings: {},
      selectedColumnsModel: [],
      selectedColumnForSettings: null,
      defaultFilterModel: null
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.view-autocomplete) {
  max-width: 300px;
  width: 100%;
  input {
    background: #FFFFFF;
    border: 1px solid #d5d7dc;
  }
  .dropdown-item {
    padding: 8px 15px;
  }
  .autocomplete__row {
    display: flex;
    align-items: center;
  }
  .autocomplete__edit {
    width: 22px;
    height: 22px;
    padding: 2px 0 0 0;
    margin-left: 10px;
    font-size: 15px;
    border-radius: 50% !important;
  }
  .setting-name {
    flex: 1 1;
    white-space: pre-wrap;
  }
}
.saved-filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > .field-wrapper {
    margin-bottom: 0;
  }
}
.save-buttons {
  display: flex;
  align-items: center;
  .field-wrapper {
    margin-bottom: 0;
    margin-left: 10px;
  }
}
.column-dropdown-item {
  padding: 3px 9px;
  display: flex;
  align-items: center;
  background: #fff;
  &:hover {
    background: #d7e0fb;
  }
  &.sortable-ghost {
    opacity: 0.7;
    border: 1px solid #90baff;
    margin: 5px 0;
  }
  i {
    cursor: pointer;
  }
  label {
    width: 100%;
    font-size: 12px;
    :deep(.control-label) {
      font-size: 14px !important;
    }
  }
}
.column-dropdown-button {
  :deep(i) {
    font-size: 17px;
    padding-top: 1px;
  }
}
.column-settings-icons {
  display: flex;
  i {
    color: $primary;
    font-size: 14px;
    margin-left: 10px;
  }
}
.settings-dropdown :deep {
  .dropdown-content {
    width: 220px;
  }
}
</style>
