<template>
  <div class="app" :class="[pageClassName, {'is-fullscreen-mode': isFullScreen}]">
    <b-loading :model-value="isLoading || authLoading"></b-loading>
    <network>
      <template #offline>
        <offline-network-overlay></offline-network-overlay>
      </template>
    </network>
    <template v-if="!authLoading || $route.meta.unsecured">
      <component :is="layout" v-if="layout">
        <router-view :key="routeKey"/>
      </component>
      <router-view v-else :key="routeKey"/>
    </template>
    <patient-overlay-video v-if="canAccessLiveVideo"></patient-overlay-video>
  </div>
</template>

<script>
  import Network from "@/components/Network";
  import BlankLayout from "@/layouts/BlankLayout";
  import { mapState } from "vuex";
  import PatientOverlayVideo from "@/components/PatientOverlayVideo";
  import OfflineNetworkOverlay from "@/components/OfflineNetworkOverlay";
  import to from "@/lib/to";
  import {LOG_TYPES} from "@/lib/Log";
  import {CHANNEL, CLIENT_EVENT, SERVER_EVENT} from "@/lib/Pusher";
  import {applyPermissions} from "@/lib/Permissions";

  // TODO: to much stuff here, refactor
  export default {
    components: {OfflineNetworkOverlay, Network, PatientOverlayVideo, BlankLayout},
    mounted() {
      this.icuRequestAudio.preload = 'auto'
      this.icuRequestAudio.src = require('@/assets/audio/icu-request.mp3')
      this.icuRequestAudio.load()

      const debouncedTimerReset = this.$helpers.debounce(() => {
        this.triggerUser(CLIENT_EVENT.TIMER_RESET, '')
        this.startInactivityTimer()
      }, 500)

      const unwatch = this.$watch(() => {
        return [this.$store.state.token, this.$store.state.currentUser]
      }, (newVal) => {
        if (newVal.every(x => !!x)) {
          this.userChannel = this.$pusher.subscribe(CHANNEL.FOR_USER({
            env: process.env.VUE_APP_ENVIRONMENT_NAME,
            user: this.$store.state.currentUser
          }));
          this.hospitalChannel = this.$pusher.subscribe(CHANNEL.FOR_HOSPITAL({
            env: process.env.VUE_APP_ENVIRONMENT_NAME,
            hospital: this.$store.state.currentHospital
          }))

          this.hospitalChannel.bind(CLIENT_EVENT.ROOM_NOTIFICATION_ACCEPTED, async params => {
            if (this.$route.name == 'room-notification') {
              return;
            }
            this.clearAudio()
            const { patientId, userId, userName, level, patientName, roomName, patientLink, typeMessage, type } = params
            if (this.roomNotifications[patientId]) {
              this.roomNotifications[patientId].forEach(snack => snack.close())
              this.roomNotifications[patientId] = []
            }
            if (this.$store.state.currentUser.id == userId) {
              return;
            }
            if (!this.$store.state.currentUser.role.topics.includes(type)) {
              return;
            }
            this.$buefy.snackbar.open({
              type: `${level} room-notification`,
              position: 'is-top',
              cancelText: 'Dismiss',
              indefinite: true,
              actionText: null,
              message: `${userName} Accepted - ${typeMessage} Request: Room ${this.$helpers.mask('room_name', roomName)}
                          (<a href="${patientLink}">${this.$helpers.mask('patient_name', patientName)}</a>)`
            })
          });

          this.hospitalChannel.bind(CLIENT_EVENT.ROOM_NOTIFICATION, async ({ virtualRequestId, patientId, type }) => {
            let requestAccepted = false

            if (this.$route.name == 'room-notification') {
              return;
            }

            if (!this.$store.state.currentUser.role.topics.includes(type)) {
              return;
            }

            const [error, resp] = await to(this.$http.post(`/virtual_requests/${virtualRequestId}/receive`, {}, { skipLoading: true }))
            if (!error && resp.data?.status == 'denied') {
              return;
            }

            if (!this.roomNotifications[patientId]) {
              this.roomNotifications[patientId] = []
            }

            if (type == 'eicu') {
              this.icuRequestAudio.play()
              this.audioLoopInterval = setInterval(() => {
                this.icuRequestAudio.play()
              }, 30000)
            }
            const typeMessages = {
              virtual_nursing: 'Virtual Nursing',
              virtual_scribe: 'Virtual Scribe',
              eicu: 'eICU',
            }
            const levels = {
              "low": "is-info",
              "medium": "is-warning",
              "high": "is-danger"
            }

            const [err, response] = await to(this.$http.get(`/virtual_requests/${virtualRequestId}`))
            if (!err) {
              const { data: request } = response
              const level = levels[request.priority]
              const patientLink = this.$router.resolve({
                name: 'patients.overview.edit',
                params: { id: request.patient.id },
                query: { division_id: request.division.id }
              }).fullPath;
              const close = this.$buefy.snackbar.open({
                type: `${level} room-notification`,
                position: 'is-top',
                cancelText: 'Dismiss',
                actionText: 'Video Visit',
                indefinite: true,
                onClose: () => {
                  if (requestAccepted) {
                    requestAccepted = false
                  } else {
                    to(this.$http.post(`/virtual_requests/${virtualRequestId}/dismiss`))
                  }
                  this.clearAudio()
                },
                message: `${typeMessages[type]} Request: Room ${this.$helpers.mask('room_name', request.room.name)} - ${request.division.name}
                          (<a href="${patientLink}">${this.$helpers.mask('patient_name', request.patient.name)}</a>)`,

                onAction: () => {
                  requestAccepted = true
                  this.clearAudio()
                  to(this.$http.post(`/virtual_requests/${virtualRequestId}/accept`))

                  to(this.$http.post('/pusher/trigger_hospital', {
                    event: CLIENT_EVENT.ROOM_NOTIFICATION_ACCEPTED,
                    data: {
                      type,
                      level,
                      patientId,
                      userName: this.$store.state.currentUser.name,
                      userId: this.$store.state.currentUser.id,
                      patientName: request.patient.name,
                      roomName: request.room.name,
                      patientLink: patientLink,
                      typeMessage: typeMessages[type]
                    }
                  }, {
                    skipLoading: true,
                    skipDivisions: true
                  }));
                  this.$router.push({
                    name: 'patients.overview.video-visit',
                    params: { id: request.patient.id },
                    query: { 'start-video': 1, patient_id: [request.patient.id], division_id: request.division.id }
                  })
                }
              })
              this.roomNotifications[patientId].push(close)
            }
          })

          this.userChannel.bind(CLIENT_EVENT.TIMER_RESET, () => {
            this.startInactivityTimer()
          });
          this.userChannel.bind(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, () => {
            this.logOutUser()
          });
          this.userChannel.bind(CLIENT_EVENT.SESSION_EXPIRED, () => {
            this.showSessionExpiredPopup()
          });
          this.userChannel.bind(SERVER_EVENT.USER_UPDATED, (payload) => {
            this.userUpdated(payload)
          });
          this.userChannel.bind(CLIENT_EVENT.USER_AUTHENTICATED, payload => {
            if (payload.uniqueId != this.$store.state.uniqueSessionId) {
              this.$buefy.snackbar.open({
                type: 'is-danger',
                position: 'is-top',
                cancelText: null,
                indefinite: true,
                actionText: 'Dismiss',
                message: `Your account has been logged into from another device.<br/>If this was not you, please contact support immediately.`,
              })
            }
          });
          this.hospitalChannel.bind(SERVER_EVENT.REQUEST_BROWSER_REFRESH, ({ message }) => {
            this.$buefy.snackbar.open({
              type: 'is-success',
              position: 'is-top',
              cancelText: null,
              indefinite: true,
              actionText: 'Refresh',
              message: message || 'Please refresh your browser',
              onAction: () => {
                this.$store.commit('toggleLoading', { loadingState: true })
                window.location.reload()
              }
            })
          })

          this.$http.interceptors.response.use(response => {
            if (response?.config?.isPusherMessage || response?.config?.skipInactivityCheck) {
              return response
            }
            if (!this.inactivityDialog) {
              debouncedTimerReset()
            }
            return response
          });

          this.$helpScout.inject({
            onError: () => {
              const snack = this.$buefy.snackbar.open({
                position: 'is-top',
                actionText: 'Dismiss',
                indefinite: true,
                // eslint-disable-next-line no-undef
                message: `We would like to provide the LookDeep Support Center to you, however it appears that you have an ad blocker preventing access to the site. <br>  Please disable your ad blocker for  ${process.env.VUE_APP_BASE_URL}. <br> Note that we will never run any ads on this site.`,
                onAction() {
                  snack.close()
                }
              })
            }
          })

          unwatch()
        }
      }, { immediate: true })
    },
    methods: {
      clearAudio() {
        this.icuRequestAudio.pause()
        this.icuRequestAudio.load()
        clearInterval(this.audioLoopInterval)
      },
      logOutUser() {
        this.$logger.logUser(LOG_TYPES.USER.USER_SESSION_EXPIRED)
        this.$auth.logOut()
      },
      showSessionExpiredPopup() {
        this.$buefy.dialog.alert({
          message: 'Your session expired',
          confirmText: 'Sign-In Again',
          confirmCallback: () => {
            this.$store.commit('toggleLoading', { loadingState: true })
            this.logOutUser()
            // this.userChannel.trigger(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, '')
            this.triggerUser(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, '')
          }
        })
      },
      startInactivityTimer() {
        clearTimeout(this.inactivityTimer);
        clearTimeout(this.stillThereModalTimer)

        let INACTIVITY_TIMEOUT = 55 * 60 * 1000;
        let INACTIVITY_MESSAGE_TIMEOUT = 5 * 60 * 1000;
        const serverInactivityTimeout = this.$store.state.currentUser.inactivity_timeout_sec
        if (serverInactivityTimeout) {
          /**
           * subtract 5 minute from timeout and show inactivity popup so user can do something
           * before session actually expires.
           * 'Still There ?' popup runs for 5 minutes after which there are still 2 minutes left
           * before session expires. We need that to send pusher message to user's other active sessions
           * and initiate logout
           */
          const twoMinutes = 3 * 60 * 1000
          INACTIVITY_TIMEOUT = (serverInactivityTimeout * 1000) - INACTIVITY_MESSAGE_TIMEOUT - twoMinutes
        }

        if (this.inactivityDialog) {
          this.inactivityDialog.close()
        }
        this.inactivityTimer = setTimeout(() => {
          this.inactivityDialog = this.$buefy.dialog.alert({
            title: 'Still There ?',
            message: 'Please click the button below to stay logged in.',
            confirmText: 'Stay Logged In',
            closeOnConfirm: false,
            confirmCallback: async (value, { close }) => {
              const [err,] = await to(this.$http.get('/users/current'))
              if (err) {
                this.logOutUser()
                // this.userChannel.trigger(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, '')
                this.triggerUser(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, '')
              } else {
                // this.userChannel.trigger(CLIENT_EVENT.TIMER_RESET, '')
                this.triggerUser(CLIENT_EVENT.TIMER_RESET, '')
                close()
                this.inactivityDialog = null
              }
            }
          });
          this.stillThereModalTimer = setTimeout(() => {
            this.triggerUser(CLIENT_EVENT.LOGOUT_WITH_EXPIRATION, '')
          }, INACTIVITY_MESSAGE_TIMEOUT);
        }, INACTIVITY_TIMEOUT);
      },
      async userUpdated() {
        const [err, response] = await to(this.fetchCurrentUser())
        if (!err) {
          const { data } = response
          this.$store.commit('setCurrentUser', data)
          applyPermissions(data.permissions)
        }
      },
      async triggerUser(event, data) {
        return to(this.$http.post('/pusher/trigger_user', { event, data }, { skipDivisions: true, skipLoading: true, isPusherMessage: true }))
      },
      async fetchCurrentUser() {
        return this.$http.get('/users/current', { skipDivisions: true })
      },
      helpScoutOnReady() {
        this.$helpScout.Beacon('identify', {
          name: this.$store.state.currentUser.name,
          email: this.$store.state.currentUser.email,
        })

        this.$helpScout.Beacon('on', 'open', () => {
          this.$helpScout.Beacon('event', {
            type: 'page-viewed',
            url: document.location.href,
            title: document.title,
          })
          this.$helpScout.Beacon('suggest')
        })
      }
    },
    created() {
      document.addEventListener('fullscreenchange', () => {
        this.isFullScreen = document.fullscreenElement != null
      });

      this.$watch(() => {
        return this.$store.state.currentUser
      }, () => {
        if (this.$store.state.currentUser && this.$store.state.currentUser.name) {
          this.$helpScout.onReady(this.helpScoutOnReady)
        }
      }, { immediate: true })

      this.$store.subscribe(mutation => {
        if (mutation.type === 'setNetworkErrors') {
          const code = this.networkErrors[0]?.code
          const errorType = this.networkErrors[0]?.response?.data?.type
          if (this.networkErrors.length === 1 && (code === 'ECONNABORTED' || errorType === 'Authenticator::InactivityTimeout')) {
            this.dialogs.forEach(d => d.close())
            if (code === 'ECONNABORTED') {
              console.warn('InactivityTimeout', this.networkErrors)
              // let message = 'An Error Occurred - Please refresh your browser'
              // this.dialogs.push(this.$buefy.dialog.alert({
              //   title: 'Timeout', message
              // }))
            } else if (errorType === 'Authenticator::InactivityTimeout') {
              // this.userChannel.trigger(CLIENT_EVENT.SESSION_EXPIRED, '')
              this.triggerUser(CLIENT_EVENT.SESSION_EXPIRED, '')
              this.showSessionExpiredPopup()
            }
          } else {
            if (this.networkErrors.length > 0) {
              const dialog = this.$buefy.dialog.alert({
                title: 'Something Went Wrong',
                message: this.networkErrors.reduce((acc, curr) => {
                  return `${curr.response?.data?.error || 'Unknown network error. Please reload the page.'}`
                }, ''),
                confirmCallback: () => {
                  this.dialogs.pop()
                }
              });
              this.dialogs.push(dialog)
            }
          }
        }
      });
    },
    computed: {
      ...mapState(['divisionId']),
      layout() {
        return this.$route.meta.layout ? this.$route.meta.layout + "-layout" : null;
      },
      ...mapState(['isLoading', 'networkErrors', 'token', 'authLoading']),
      canAccessLiveVideo() {
        return this.$can('AccessLiveVideo', 'PatientMonitor')
      },
      pageClassName() {
        return this.$route.name ? 'page-' + this.$route.name.replaceAll('.', '-') : ''
      },
      routeKey() {
        const { path, query: { menu_id, item_id} } = this.$route
        return '' + path + menu_id + item_id
      }
    },
    data() {
      return {
        inactivityTimer: null,
        stillThereModalTimer: null,
        isFullScreen: false,
        dialogs: [],
        userChannel: null,
        hospitalChannel: null,
        inactivityDialog: null,
        roomNotifications: {},
        icuRequestAudio: new Audio('/audio/icu_request.mp3'),
        audioLoopInterval: null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loading-overlay {
    z-index: 999999999;
  }
</style>
<style lang="scss">
.snackbar.room-notification {
  &.is-danger {
    background: $danger !important;
  }
  &.is-warning {
    background: $warning !important;
    color: #363636 !important;
  }
  &.is-success {
    background: $success !important;
  }
  .action button {
    font-weight: 500 !important;
    font-size: 13px;
  }
  .action.is-cancel button {
    margin-right: 10px;
  }
}
</style>