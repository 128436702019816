<template>
  <template v-if="menu.isGroup">
    <li v-show="menu.enabled && menu.children.some(m => m.hasPermissions)" class="menu-set__group" :class="{'has-children': menu.children && menu.children.length}">
      <template v-if="menu.enabled">
        <VDropdown container=".sidebar-content" popper-class="ld-popper menu-set__popper" placement="right" :triggers="['hover']" :popper-triggers="['hover']" >
          <a href="#" class="menu-set__link">
            <i class="mdi" :class="menu.icon"></i>
            <span class="menu-set__title">{{ menu.title }}</span>
          </a>
          <template #popper>
            <div class="menu-set__popper-content">{{ menu.title }}</div>
          </template>
        </VDropdown>
        <template v-if="menu.children && menu.children.length">
          <ul class="menu-set__group-children">
            <menu-item v-for="(child, index) in menu.children" :key="index" :has-padding="true" :menu="child"></menu-item>
          </ul>
        </template>
      </template>
    </li>
  </template>
  <template v-else>
    <li v-show="menu.enabled && menu.hasPermissions">
      <VDropdown v-if="menu.hasPermissions" container=".sidebar-content" popper-class="ld-popper menu-set__popper" placement="right" :triggers="['hover']" :popper-triggers="['hover']" >
        <router-link :data-id="menu.id" :id="menu.id && `menu-item-${menu.id}`" class="menu-set__link menu-set__router-link" v-bind="getDynamicAttrsForId(menu.id)" :class="{'has-padding': hasPadding}" :to="clone(menu.route)">
          <i class="mdi" :class="menu.icon"></i>
          <span class="menu-set__title">{{ menu.title }}</span>
        </router-link>
        <template #popper>
          <div class="menu-set__popper-content">{{ menu.title }}</div>
        </template>
      </VDropdown>
    </li>
  </template>
</template>

<script>

import {PAGE_PERMISSION} from "@/lib/menu/MenuConfig";

export default {
  name: "MenuItem",
  props: ['menu', 'hasPadding'],
  methods: {
    clone(obj) {
      return structuredClone(obj)
    },
    getDynamicAttrsForId(id) {
      if (id) {
        return {'active-class': '', 'exact-active-class': ''}
      }
      return {}
    }
  },
  data() {
    return {
      PAGE_PERMISSION
    }

  }
}
</script>

<style scoped lang="scss">
  .menu-set__link {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 12px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: none;
    background: none;
    cursor: pointer;
    line-height: 19px;

    &.router-link-active, &:hover {
      background: #2e42a3;
      color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 24px -14px rgba(0, 0, 0, .49);
    }
    i {
      font-size: 20px;
      padding-left: 5px;
      color: #fff;
    }
    .menu-set__title {
      padding-left: 10px;
      line-height: 14px;
    }
  }
  .menu-set__router-link {
    font-weight: 400;
    font-size: 13px;
  }
  .has-padding {
    padding-left: 36px !important;
  }
  .menu-set__group-children li, .menu-set__children li {
    margin: 5px 0;
  }
  .menu-set__direct-link .menu-set__router-link {
    font-size: 14px;
    font-weight: 500;
  }
  .menu-set__popper-content {
    white-space: nowrap;
  }
</style>