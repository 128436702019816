import MenuItem from "@/lib/menu/MenuItem";
import MenuGroup from "@/lib/menu/MenuGroup";
import MenuSet from "@/lib/menu/MenuSet";

export const PAGE = Object.freeze({
  PATIENT: 'patient',
  PATIENT_MONITORS: 'patientMonitors',
  ASSIGNMENTS: 'assignments',
  CENTRAL_MONITORING: 'centralMonitoring',
  INFINITY_WATCH: 'infinityWatch',
  TEAM_MODE: 'teamMode',
  NURSING_STATION: 'nursingStation',
  SHIFT_REPORT: 'shiftReport',
})

export const PAGE_LINK = Object.freeze({
  [PAGE.PATIENT]: { name: 'patients.index' },
  [PAGE.PATIENT_MONITORS]: { name: 'patient-monitors.index' },
  [PAGE.CENTRAL_MONITORING]: { name: 'patient-central' },
  [PAGE.ASSIGNMENTS]: { name: 'manager-view' },
  [PAGE.INFINITY_WATCH]: { name: 'infinity-watch' },
  [PAGE.TEAM_MODE]: { name: 'patient-central.team-mode' },
  [PAGE.NURSING_STATION]: { name: 'patient-central.nursing-station' },
  [PAGE.SHIFT_REPORT]: { name: 'reporting.shift-report' },
})

export const PAGE_TYPE = Object.freeze({
  TABLE: 'table',
  CENTRAL_MONITORING: 'centralMonitoring',
  ASSIGNMENTS: 'assignments',
  GROUP: 'group',
  INFINITY_WATCH: 'infinityWatch',
  TEAM_MODE: 'teamMode',
  NURSING_STATION: 'nursingStation',
  SHIFT_REPORT: 'shiftReport',
})

export const PAGE_CONFIG = Object.freeze({
  [PAGE.SHIFT_REPORT]: {
    page: PAGE.SHIFT_REPORT,
    pageType: PAGE_TYPE.SHIFT_REPORT,
  },
  [PAGE.PATIENT]: {
    page: PAGE.PATIENT,
    pageType: PAGE_TYPE.TABLE,
    tableId: 'patients-list',
    customFieldsModel: 'Patient'
  },
  [PAGE.PATIENT_MONITORS]: {
    page: PAGE.PATIENT_MONITORS,
    pageType: PAGE_TYPE.TABLE,
    tableId: 'patient-monitors-list',
    customFieldsModel: 'PatientMonitor'
  },
  [PAGE.CENTRAL_MONITORING]: {
    page: PAGE.CENTRAL_MONITORING,
    pageType: PAGE_TYPE.CENTRAL_MONITORING,
  },
  [PAGE.ASSIGNMENTS]: {
    page: PAGE.ASSIGNMENTS,
    pageType: PAGE_TYPE.ASSIGNMENTS,
  },
  [PAGE.GROUP]: {
    pageType: PAGE_TYPE.GROUP,
  },
  [PAGE.INFINITY_WATCH]: {
    page: PAGE.INFINITY_WATCH,
    pageType: PAGE_TYPE.INFINITY_WATCH,
  },
  [PAGE.TEAM_MODE]: {
    page: PAGE.TEAM_MODE,
    pageType: PAGE_TYPE.TEAM_MODE,
  },
  [PAGE.NURSING_STATION]: {
    page: PAGE.NURSING_STATION,
    pageType: PAGE_TYPE.NURSING_STATION,
  }
})

export const AVAILABLE_ITEMS = [
  {
    type: 'menu',
    title: 'Group',
    icon: 'mdi-group',
    config: PAGE_CONFIG[PAGE.GROUP]
  },
  {
    type: 'menu',
    title: 'Patients List',
    config: PAGE_CONFIG[PAGE.PATIENT]
  },
  {
    type: 'menu',
    title: 'Patients Monitors',
    config: PAGE_CONFIG[PAGE.PATIENT_MONITORS]
  },
  {
    type: 'menu',
    title: 'Central Monitoring',
    config: PAGE_CONFIG[PAGE.CENTRAL_MONITORING]
  },
  {
    type: 'menu',
    title: 'Assignments',
    config: PAGE_CONFIG[PAGE.ASSIGNMENTS]
  },
  {
    type: 'menu',
    title: 'Infinity Watch',
    config: PAGE_CONFIG[PAGE.INFINITY_WATCH]
  },
  {
    type: 'menu',
    title: 'Team Mode',
    config: PAGE_CONFIG[PAGE.TEAM_MODE]
  },
  {
    type: 'menu',
    title: 'Nursing Station',
    config: PAGE_CONFIG[PAGE.NURSING_STATION]
  },
  {
    type: 'menu',
    title: 'Shift Report',
    config: PAGE_CONFIG[PAGE.SHIFT_REPORT]
  }
];

const permissions = {
  canViewManagePatients() { return this.$can('View', 'Patient') },
  canViewMonitoring() { return this.$can('View', 'PatientMonitor') },
  canViewPatientCentral() { return this.$can('View', 'PatientCentral') },
  canViewReporting() { return this.$can('View', 'Reporting') },
  canViewHospital() { return this.$can('View', 'Hospital') },
  canViewRooms() { return this.$can('View', 'Room') },
  canViewUnits() { return this.$can('View', 'Unit') },
  canViewVideoDevices() { return this.$can('View', 'VideoDevice') },
  canViewUsers() { return this.$can('View', 'User') },
  canViewRoles() { return this.$can('Edit', 'Role') },
  canViewGroup() { return this.$can('View', 'Group') },
  canViewLogs() { return this.$can('View', 'AuditLog') },
  canViewFormBuilder() { return this.$can('Edit', 'FormBuilder') },
  canViewFamilyConnect() { return this.$can('View', 'FamilyConnect') },
  canViewAccessBrowser() { return this.$can('View', 'User') && this.$can('View', 'Group') && this.$can('View', 'Room') && this.$can('View', 'Unit') },
  canManageSharedViews() { return this.$can('ManageSharedViews', 'PatientCentral') },
  canViewDivisions() { return this.$can('View', 'Division') },
  canEditDivision() { return this.$can('Edit', 'Division') },
  canViewSystemMonitoring() { return this.$can('View', 'SystemMonitoring') },
  canManageLabelers() { return this.$can('Manage', 'Labeler')},
  canEditLabeling() { return this.$can('Edit', 'LabeledImage')},
  canEditHomepage() { return this.$can('Edit', 'Homepage')},
  canManageSystem() { return this.$can('Manage', 'System') },
  canManageAssignments() { return this.$can('Manage', 'PatientMonitorAssignment') },
  canManageOrViewAssignments() { return this.$can('Manage', 'PatientMonitorAssignment') || this.$can('View', 'PatientMonitorAssignment') },
  canReceiveAssignments() { return this.$can('Receive', 'PatientMonitorAssignment') },
  canViewInfinityWatch() { return this.$can('View', 'InfinityWatch') }
}

function evaluatePermission(permFunc) {
  return permFunc.call(this)
}

export const PAGE_PERMISSION = Object.freeze({
  [PAGE.PATIENT]: permissions.canViewManagePatients,
  [PAGE.PATIENT_MONITORS]: permissions.canViewMonitoring,
  [PAGE.CENTRAL_MONITORING]: permissions.canViewPatientCentral,
  [PAGE.ASSIGNMENTS]: permissions.canManageOrViewAssignments,
  [PAGE.INFINITY_WATCH]: permissions.canViewInfinityWatch,
  [PAGE.TEAM_MODE]: permissions.canReceiveAssignments,
  [PAGE.NURSING_STATION]: permissions.canViewPatientCentral,
  [PAGE.SHIFT_REPORT]: permissions.canViewMonitoring,
})

export const homeMenuSet = [
  new MenuItem(null, 'Home', { name: 'home' }, true, 'mdi-home-outline'),
]
export function buildStaticMenuSets(appInstance) {
  const permEval = evaluatePermission.bind(appInstance)
  const healthSystem = new MenuGroup('Health System', 'mdi-layers-outline')
  healthSystem.enabled = permEval(permissions.canViewHospital) || permEval(permissions.canManageSharedViews) || permEval(permissions.canViewDivisions)
  healthSystem.children = [
    new MenuItem(null, 'Custom Fields', { name: 'hsl.custom-fields.index' }, true, 'mdi-form-textbox', permEval(permissions.canViewHospital)),
    new MenuItem(null, 'Attributes', { name: 'hsl.tags' }, true, 'mdi-tag-multiple-outline', permEval(permissions.canViewHospital)),
    new MenuItem(null, 'Views', { name: 'hsl.manage-views' }, true, 'mdi-view-list-outline', permEval(permissions.canManageSharedViews)),
    new MenuItem(null, 'Hospitals', { name: 'hsl.hospitals.index' }, true, 'mdi-hospital-building', permEval(permissions.canViewDivisions)),
    new MenuItem(null, 'Preferences', { name: 'hsl.preferences' }, true, 'mdi-dns-outline', permEval(permissions.canViewHospital)),
  ]

  const hospital = new MenuGroup('Hospital', 'mdi-hospital-building')
  hospital.enabled = permEval(permissions.canViewRooms) || permEval(permissions.canViewUnits) || permEval(permissions.canViewVideoDevices) || permEval(permissions.canEditHomepage) || permEval(permissions.canEditDivision)
  hospital.children = [
    new MenuItem(null, 'Rooms', { name: 'hospital.rooms' }, true, 'mdi-puzzle-outline', permEval(permissions.canViewRooms)),
    new MenuItem(null, 'Units', { name: 'hospital.units' }, true, 'mdi-vector-intersection', permEval(permissions.canViewUnits)),
    new MenuItem(null, 'Video Devices', { name: 'hospital.video_devices' }, true, 'mdi-cctv', permEval(permissions.canViewVideoDevices)),
    new MenuItem(null, 'Homepage', { name: 'hospital.homepage' }, true, 'mdi-home-edit-outline', permEval(permissions.canEditHomepage)),
    new MenuItem(null, 'Preferences', { name: 'hospital.preferences' }, true, 'mdi-dns-outline', permEval(permissions.canEditDivision)),
  ]

  const userManagement = new MenuGroup('User Management', 'mdi-account-group-outline')
  userManagement.enabled = permEval(permissions.canViewUsers) || permEval(permissions.canViewRoles) || permEval(permissions.canViewGroup) || permEval(permissions.canViewAccessBrowser) || permEval(permissions.canViewFamilyConnect) || permEval(permissions.canManageLabelers)
  userManagement.children = [
    new MenuItem(null, 'Users', { name: 'user-management.users' }, true, 'mdi-account-group-outline', permEval(permissions.canViewUsers)),
    new MenuItem(null, 'Roles', { name: 'user-management.roles' }, true, 'mdi-account-key-outline', permEval(permissions.canViewRoles)),
    new MenuItem(null, 'Groups', { name: 'user-management.groups' }, true, 'mdi-group', permEval(permissions.canViewGroup)),
    new MenuItem(null, 'Access Browser', { name: 'user-management.access-browser' }, true, 'mdi-magnify-scan', permEval(permissions.canViewAccessBrowser)),
    new MenuItem(null, 'Family Connect', { name: 'user-management.family-connect' }, true, 'mdi-home-group', permEval(permissions.canViewFamilyConnect)),
    new MenuItem(null, 'Labelers', { name: 'user-management.labelersindex' }, true, 'mdi-tag-multiple-outline', permEval(permissions.canManageLabelers)),
  ]

  const reporting = new MenuGroup('Reporting', 'mdi-chart-bar')
  reporting.enabled = permEval(permissions.canViewReporting) || permEval(permissions.canViewMonitoring)
  reporting.children = [
    new MenuItem(null, 'Data Export', { name: 'reporting.data-export' }, true, 'mdi-database-export', permEval(permissions.canViewReporting)),
  ]

  const monitoring = new MenuGroup('Monitoring', 'mdi-monitor-dashboard')
  monitoring.enabled = permEval(permissions.canViewLogs) || permEval(permissions.canViewSystemMonitoring)
  monitoring.children = [
    new MenuItem(null, 'Inbound Requests', { name: 'monitoring.inbound-requests' }, true, 'mdi-import', permEval(permissions.canViewLogs)),
    new MenuItem(null, 'Logs', { name: 'monitoring.logs' }, true, 'mdi-format-columns', permEval(permissions.canViewLogs)),
    new MenuItem(null, 'System Overview', { name: 'monitoring.system-overview' }, true, 'mdi-view-dashboard-variant-outline', permEval(permissions.canViewSystemMonitoring)),
    new MenuItem(null, 'Device Uptime', { name: 'monitoring.devices-uptime' }, true, 'mdi-alarm-light-outline', permEval(permissions.canViewSystemMonitoring)),
    new MenuItem(null, 'ADT Messages', { name: 'monitoring.adt-messages' }, true, 'mdi-card-bulleted-outline', permEval(permissions.canViewLogs)),
    new MenuItem(null, 'Nurse Call', { name: 'monitoring.nurse-call' }, true, 'mdi-phone-outline', permEval(permissions.canViewLogs)),
  ]

  const system = new MenuGroup('System', 'mdi-cog-outline')
  system.enabled = permEval(permissions.canManageSystem) || permEval(permissions.canManageLabelers) || permEval(permissions.canEditLabeling)
  system.children = [
    new MenuItem(null, 'App Modules', { name: 'menu-management' }, true, 'mdi-menu', permEval(permissions.canManageSystem)),
    new MenuItem(null, 'Feature Flags', { name: 'system.feature-flags' }, true, 'mdi-flag-variant-outline', permEval(permissions.canManageSystem)),
    new MenuItem(null, 'Test ADT', { name: 'system.test-adt' }, true, 'mdi-test-tube', permEval(permissions.canManageSystem)),
    new MenuItem(null, 'Delete Labeled Image', { name: 'labeling.delete-image' }, true, 'mdi-image-remove', permEval(permissions.canManageLabelers)),
  ]

  const adminSet = new MenuSet(Math.random(), 'Admin', 'mdi-tune')
  adminSet.children = [
    new MenuItem(null, 'Form Builder', { name: 'form-builder' }, true, 'mdi-table-cog', permEval(permissions.canViewFormBuilder)),
    healthSystem,
    hospital,
    userManagement,
    reporting,
    monitoring,
    system,
    new MenuItem(null, 'Labeling', { name: 'labeling.index' }, true, 'mdi-draw', permEval(permissions.canEditLabeling)),
  ]

  return [adminSet]

}