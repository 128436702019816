export default class TableColumn {
  DEFAULT_ATTRS = {}
  /**
   * @typedef {Object} ColumnAttrs
   * @property {boolean} sortable - Whether column is sortable
   * @property {string} field - Field name used to for sorting on backend and to uniquely identify field
   * @property {string} label - Text used as a column header/label
   * @property {string} [slotName] - By default when `usesSlot` is true we generate per-column slot by field property.
   *                               If slotName is used we override it.
   * @property {boolean} [isChecked] - If field is visible or not by default
   * @property {boolean} [isCustomField] - The way to differentiate if this field is custom. default FALSE
   * @property {Object} [customFieldOptions] - Options for custom fields, see: utils.js->ELEMENT_SCHEMA. default {}
   * @property {string} [customFieldKind] - Type of custom field, see: utils.js->FORM_ELEMENTS
   * @property {string} [customFieldKey] - Type of custom field, see: utils.js->FORM_ELEMENTS
   * @property {boolean} [enabled] - ability to use columns
   */

  /**
   *
   * @param {ColumnAttrs} attrs All Attributes of <b-column> with addition of custom ones. See definition above
   * @param {function} [formatter] Function returning text that will be used as a column value
   * @param {boolean} [usesSlot] If column needs to use custom slot for rendering
   */
  constructor(attrs = this.DEFAULT_ATTRS, formatter = null, usesSlot = false) {
    this.attrs = { enabled: true, isChecked: true, isCustomField: false, customFieldOptions: {}, ...attrs }
    this.formatter = formatter
    this.usesSlot = usesSlot
  }
}
