/**
 * MenuItem class
 * @class MenuItem
 */
export default class {
  /**
   * Creates a new MenuItem instance
   * @param {string} title - The title of the menu item
   * @param {object} route - The route of the menu item
   * @param {boolean} [enabled=true] - The enabled status of the menu item
   * @param {string} icon - The icon of the menu item
   * @param {function|boolean} [hasPermissions=() => true] - Permissions for the menu item
   * @param {MenuItem[]} [children=[]] - The child menu items of the menu item
   */
  constructor(id, title, route, enabled = true, icon = '', hasPermissions = true, children = []) {
    this.id = id
    this.title = title
    this.route = route
    this.enabled = enabled
    this.icon = icon
    this.hasPermissions = hasPermissions
    this.children = children
  }
}