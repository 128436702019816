<template>
  <div class="filters-container">
    <div class="filters">
      <div class="filter-buttons" :class="{'filter-buttons__clearable': canClearFilters}" v-if="!isNewFilterButtonHidden || canClearFilters">
        <b-dropdown v-model="filterModel" @change="filterSelected" v-if="!isNewFilterButtonHidden">
          <template #trigger>
            <b-button icon-left="mdi mdi-filter-plus-outline" label="Add Filter" type="is-primary" />
          </template>
          <b-dropdown-item v-for="(item, index) in availableFilters" :value="item" :key="index">{{ item.displayName }}</b-dropdown-item>
        </b-dropdown>
        <b-button v-if="canClearFilters" label="Clear Filters" type="is-text" @click="selectedFilters = []; $emit('filters-cleared')" />
      </div>
      <b-dropdown v-for="(filter, index) in selectedFilters" :key="filter.id" :ref="`filter${index}`">
        <template #trigger>
          <div class="filter">
            <p>
              {{ filter.displayName }}
              <template v-if="selectedFilters[index].value">
                <span>{{ filterTypeOperator[filter.type] }}</span>
              </template>
              {{ getValueForDisplay(filter) }}
            </p>
            <button v-if="filterSettings.deletableFilters !== false" @click.stop="removeFilter(index, filter.id)">
              <i class="mdi mdi-close-circle"></i>
            </button>
          </div>
        </template>
        <b-dropdown-item custom>
          <div class="filter-content" v-on:keypress.enter="applyFilter(index, filter.id)">
            <b-select v-if="filter.type == FilterType.ENUM && !filter.isInline" expanded :placeholder="`Select ${filter.displayName}`" v-model="tempValue[filter.id]">
              <option v-for="(option, index) in enumValuesForFilter(filter)" :key="index" :value="option.key">{{ option.value }}</option>
            </b-select>
            <div v-else-if="filter.type == FilterType.DATETIMERANGE">
              <vue-date-picker range :partial-range="false" :month-change-on-scroll="false" v-model="tempValue[filter.id]"></vue-date-picker>
            </div>
            <component v-else :is="getFilterComponentName(filter)" :filter="filter" v-bind="filterTypeComponent[filter.type].attributes" v-model="tempValue[filter.id]"></component>
            <button class="button is-primary" @click="applyFilter(index, filter.id)">Apply</button>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>


<script>
import {FilterType, TableFilter} from "@/models/TableFilter";
import TableFilterRadioGroup from "@/components/TableFilterRadioGroup.vue";
import VueDatePicker from "@vuepic/vue-datepicker"
import '@vuepic/vue-datepicker/dist/main.css';
import {DateTime} from "luxon";
import AutocompleteTableFilter from "@/components/table_filters/AutocompleteTableFilter";

export default {
  name: "TableFilters",
  components: { TableFilterRadioGroup, VueDatePicker, AutocompleteTableFilter },
  props: {
    urlFilters: {
      type: Object,
      default: () => { return {} }
    },
    availableFilters: {
      type: Array,
      required: true,
      validator(value) {
        return Array.isArray(value) && value.every(x => x instanceof TableFilter)
      }
    },
    filterSettings: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    isNewFilterButtonHidden() {
      return this.filterSettings.hiddenNewFilterButton
    },
    canClearFilters() {
      return this.selectedFilters.length > 0 && !this.filterSettings.hiddenClearFiltersButton
    }
  },
  watch: {
    urlFilters: {
      handler() {
        this.parseUrlParams()
      },
      immediate: true
    },
    availableFilters: {
      handler() {
        this.reloadDynamicFilters()
        this.parseUrlParams()
      },
      deep: true
    }
  },
  methods: {
    enumValuesForFilter(filter) {
      return this.dynamicEnumValues[filter.name] || filter.enumValues || []
    },
    async reloadDynamicFilters() {
      const requests = []
      this.availableFilters.forEach(filter => {
        if (filter.type == FilterType.ENUM && filter?.data?.apiEndpoint) {
          requests.push(this.$http.get(filter.data.apiEndpoint).then(response => {
            this.dynamicEnumValues[filter.name] = response.data.map(x => { return { key: x[filter.data.key], value: x[filter.data.value] } })
            return { response, filter }
          }))
        }
      })
      const promiseData = await Promise.allSettled(requests)
      return promiseData.filter(x => x.status == 'fulfilled').map(x => x.value)
    },
    getValueForDisplay(filter) {
      let val = filter.value
      if (filter.type == FilterType.ENUM) {
        const selectedEnum = this.enumValuesForFilter(filter)?.filter(x => x.key == filter.value)[0]
        if (selectedEnum) {
          val = selectedEnum.value
        }
      } else if (filter.type == FilterType.DATERANGE) {
        if (Array.isArray(filter.value)) {
          const dates = filter.value.map(x => this.$helpers.formatDate(x, 'LL', true))
          val = `${dates[0]} and ${dates[1]}`
        }
      } else if (filter.type == FilterType.DATETIMERANGE) {
        if (Array.isArray(filter.value)) {
          const dates = filter.value.map(x => this.$helpers.formatDate(x, 'LLL'))
          val = `${dates[0]} and ${dates[1]}`
        }
      }  else if (filter.type == FilterType.DATE) {
        val = this.$helpers.formatDate(filter.value, 'LL', true)
      } else {
        val = filter.value
      }
      return val
    },
    parseUrlParams() {
      this.selectedFilters = []
      for (const [key, value] of Object.entries(this.urlFilters)) {
        if (key == 'custom_fields') {
          for (const [customFieldKey, customFieldValues] of Object.entries(value)) {
            const filter = this.availableFilters.filter(x => x.name == `custom_fields[${customFieldKey}]`)[0]
            if (filter) {
              if (Array.isArray(customFieldValues)) {
                customFieldValues.forEach(val => {
                  this.filterSelected(filter, val, false)
                })
              } else if (typeof customFieldValues == 'object') {
                Object.values(customFieldValues).forEach(val => {
                  this.filterSelected(filter, val, false)
                })
              }
            }
          }
        } else {
          const filter = this.availableFilters.filter(x => x.name == key)[0]
          if (filter) {
            if (Array.isArray(value)) {
              value.forEach(val => {
                this.filterSelected(filter, val, false)
              })
            } else if ([FilterType.DATERANGE, FilterType.DATETIMERANGE].includes(filter.type)) {
              Object.values(value).forEach(val => {
                this.filterSelected(filter, val, false)
              })
            } else {
              this.filterSelected(filter, value, false)
            }
          }
        }
      }
    },
    filterOpened(index, state) {
      if (state) {
        this.focusValueInput(index)
      }
    },
    filterSelected(filter, value = '', openFilter = true) {
      const randId = Math.random() + ''
      let val = value, dateStringVal = null
      if (filter.type == FilterType.DATERANGE && val) {
        val = val.map(x => this.$helpers.getDate({ date: x, withoutTimezone: true}).toDate())
        dateStringVal = val.map(x => this.$helpers.formatDate(x, 'YYYY-MM-DD', true))
      }
      if (filter.type == FilterType.DATETIMERANGE && val) {
        val = val.map(x => this.$helpers.getDate({ date: x, withoutTimezone: true}).toDate())
        dateStringVal = val
      }
      if (filter.type == FilterType.DATE && val) {
        val = this.$helpers.getDate({ date: val, withoutTimezone: true}).toDate()
        dateStringVal = this.$helpers.formatDate(val, 'YYYY-MM-DD', true)
      }
      this.selectedFilters.push({
        id: randId,
        ...filter,
        value: dateStringVal || val
      });
      if (val) {
        this.tempValue[randId] = val
      }
      this.assignColor(filter.name)
      if (openFilter) {
        this.$nextTick(() => {
          this.filterModel = null
          const comp = this.$refs[`filter${this.selectedFilters.length - 1}`][0]
          comp.toggle()
          this.focusValueInput(this.selectedFilters.length - 1)
        })
      }
    },
    assignColor(name) {
      if (!this.assignedColors[name]) {
        const randomIndex = Math.floor(Math.random() * this.colors.length)
        let color = this.colors.splice(randomIndex, 1)[0]
        this.assignedColors[name] = color
      }
    },
    focusValueInput(index) {
      const comp = this.$refs[`filter${index}`][0]
      if (comp) {
        setTimeout(() => {
          const el = comp.$el.getElementsByTagName('input')[0]
          if (el) {
            el.focus()
          }
        }, 100)
      }
    },
    removeFilter(index, id) {
      this.selectedFilters.splice(index, 1)
      delete this.tempValue[id]
      this.emitActiveFilters()
    },
    applyFilter(index, id) {
      this.$refs[`filter${index}`][0].toggle()
      let val = null
      if (this.selectedFilters[index].type == FilterType.DATERANGE) {
        val = this.tempValue[id].map(x => this.$helpers.formatDate(x, 'YYYY-MM-DD', true))
      } else if (this.selectedFilters[index].type == FilterType.DATETIMERANGE) {
        val = this.tempValue[id].map(x => {
          return DateTime.fromObject(DateTime.fromJSDate(x).toObject(), { zone: this.$store.state.currentUser.timezone }).toUTC().toString()
        })
      } else if (this.selectedFilters[index].type == FilterType.DATE) {
        val = this.$helpers.formatDate(this.tempValue[id], 'YYYY-MM-DD', true)
      } else {
        val = this.tempValue[id]
      }
      this.selectedFilters[index].value = val
      this.emitActiveFilters()
    },
    emitActiveFilters() {
      this.$emit('filters-changed', this.selectedFilters.filter(x => x.value).reduce((acc, curr) => {
        if (Array.isArray(acc[curr.name])) {
          acc[curr.name].push(curr.value)
        } else if (typeof acc[curr.name] == 'object') {
          acc[curr.name][Object.keys(acc[curr.name]).length + 'a'] = curr.value
        } else if (acc[curr.name]) {
          acc[curr.name] = [acc[curr.name], curr.value]
        } else {
          /**
           * To support selecting multiple date ranges, its array should've look liked this [[DATE_FROM, DATE_TO], [DATE_FROM, DATE_TO], ...]
           * but QS library can't build that from querystring. index with string char used
           * to force QS bulding hash instead of array. Then, on backend, keys are ignored and values used
           * so in the end we get [[DATE_FROM, DATE_TO],...]
           */
          if ([FilterType.DATERANGE, FilterType.DATETIMERANGE].includes(curr.type)) {
            acc[curr.name] = { '0a': curr.value }
          } else {
            acc[curr.name] = [curr.value]
          }
        }
        return acc
      }, {}))
    },
    getFilterComponentName(filter) {
      const name = this.filterTypeComponent[filter.type].name
      if (typeof name == 'function') {
        return name(filter)
      } else {
        return name
      }
    }
  },
  mounted() {
    this.reloadDynamicFilters()
  },
  data() {
    return {
      FilterType,
      filterModel: null,
      selectedFilters: [],
      tempValue: {},
      assignedColors: {},
      colors: ['#81c784', '#64b5f6', '#4dd0e1', '#ff8a65', '#ef9a9a', '#ce93d8', '#fbc02d'],
      filterTypeComponent: {
        [FilterType.TEXT]: { name: 'b-input', attributes: { placeholder: "Search Value" }},
        [FilterType.NUMBER]: { name: 'b-input', attributes: { placeholder: "Search Value", type: 'number' }},
        [FilterType.ENUM]: {
          name: filter => {
            return filter.isInline ? 'table-filter-radio-group' : 'b-select'
          }
        },
        [FilterType.DATERANGE]: { name: 'b-datepicker', attributes: { range: true, locale: 'en-US' }},
        [FilterType.DATETIMERANGE]: { name: 'b-datetimepicker', attributes: { range: true, locale: 'en-US' }},
        [FilterType.DATE]: { name: 'b-datepicker', attributes: { range: false, locale: 'en-US' }},
        [FilterType.AUTOCOMPLETE]: { name: 'AutocompleteTableFilter' }
      },
      filterTypeOperator: {
        [FilterType.TEXT]: 'contains',
        [FilterType.NUMBER]: 'is',
        [FilterType.ENUM]: 'is',
        [FilterType.DATERANGE]: 'between',
        [FilterType.DATETIMERANGE]: 'between',
        [FilterType.DATE]: 'is',
        [FilterType.AUTOCOMPLETE]: 'contains'
      },
      autocompleteData: {},
      dynamicEnumValues: {}
    }
  }
}
</script>

<style scoped lang="scss">
  .filters {
    //margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px 0;
    > .dropdown {
      margin: 0 15px 0 0;
    }
  }
  .filter {
    display: flex;
    align-items: center;
    background: #64b5f6;
    padding: 5px 8px;
    border-radius: 10px;
    cursor: pointer;
    height: 30px;
    p {
      margin: 0 !important;
      font-size: 14px;
    }
    span {
      font-size: 14px;
      line-height: 14px;
    }
    button {
      cursor: pointer;
      color: #5487a9;
      font-size: 16px;
      margin-left: 5px;
      padding: 0;
      border: none;
      background: none;
    }
  }
  .filter-content {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 10px;
      font-size: 13px;
      align-self: center;
    }
    :deep(input) {
      font-size: 15px;
    }
  }
  .filter-buttons {
    display: flex;
    align-items: center;
    margin-right: 14px;
  }
  .filter-buttons.filter-buttons__clearable {
    border-right: 1px solid #c7c9d0;
  }
  .filter-buttons > .button {
    margin-left: 5px;
  }
  .filters-container {
    margin-bottom: 30px;
  }
</style>
