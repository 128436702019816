<template>
  <div class="column-settings">
    <div class="column-settings__head">
      <div>
        <button class="button is-text" @click.stop.prevent="$emit('close')">
          <span class="icon">
            <i class="mdi mdi-arrow-left"></i>
          </span>
        </button>
      </div>
      <span class="column-settings__title">{{ column.attrs.label }}</span>
      <span></span>
    </div>
    <div>
      <div class="column-settings__content">
        <input-with-validation b-name="switch" size="is-small" v-model="localSettings.is_sticky" @change="emitSettings">Freeze</input-with-validation>
        <input-with-validation b-name="switch" size="is-small" v-model="localSettings.is_wrapped" @change="emitSettings">Wrap</input-with-validation>
      </div>
    </div>
  </div>
</template>

<script>
import InputWithValidation from "@/components/validation/InputWithValidation";
export default {
  name: "TableColumnSettings",
  components: {InputWithValidation},
  props: ['column', 'settings'],
  emits: ['update:settings', 'close'],
  methods: {
    emitSettings() {
      this.$emit('update:settings', {...this.localSettings})
    }
  },
  watch: {
    settings: {
      handler() {
        this.localSettings = {...this.settings}
      },
      immediate: true
    }
  },
  data() {
    return {
      localSettings: {}
    }
  }
}
</script>

<style scoped lang="scss">
  .column-settings__head {
    width: 100%;
    display: flex;
    align-items: center;
    > *:first-child,
    > *:last-child {
      flex-basis: 30px;
    }
  }
  .column-settings__title {
    flex: 1 1;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .column-settings__content {
    padding: 5px 12px;
    :deep {
      .switch.is-small {
        font-size: 0.85rem;
      }
    }
    .field-wrapper:not(:last-child) {
      margin-bottom: 10px;
    }
  }
</style>
