<template>
  <nav class="nav" v-show="headerVisible">
    <div class="hamburger" @click="toggleSidebar()">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <router-link to="/" class="logo" active-class="" exact-active-class="">
      <template v-if="currentHospital">
        <image-with-headers v-if="hasCustomLogo" :url="logoUrl"></image-with-headers>
        <img v-else :src="logoUrl" class="lookdeep-logo" />
      </template>
    </router-link>
    <div>
      <division-picker v-show="divisionsVisible" style="margin-left: 20px;"></division-picker>
    </div>
    <div class="search">
      <search-bar v-show="searchVisible"></search-bar>
    </div>
    <div class="nav__right">
      <b-dropdown v-show="userNotificationsVisible" ref="notificationDropdown" position="is-bottom-left" append-to-body-copy-parent class="notifications">
        <template #trigger>
          <div class="notifications__bell">
            <div class="notifications__bell-count notification-counter" v-if="notificationCount">{{ notificationCount }}</div>
            <i class="mdi mdi-bell-outline"></i>
          </div>
        </template>
        <b-dropdown-item custom paddingless>
          <ld-notification @count-changed="count => notificationCount = count" @close="$refs.notificationDropdown.toggle()"></ld-notification>
        </b-dropdown-item>
      </b-dropdown>
      <div v-if="currentUser && profileVisible">
        <b-dropdown position="is-bottom-left">
          <template #trigger>
            <div class="user">
              <div>
                <p class="user__name">{{ currentUser.name }}</p>
                <p class="user__email">{{ currentUser.email }}</p>
              </div>
            </div>
          </template>
          <b-dropdown-item @click="$router.push({ name: 'profile' })">Profile</b-dropdown-item>
          <b-dropdown-item class="user__logout-link" @click="logOut">Log Out</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import SearchBar from "@/components/layout/SearchBar";
  import LdNotification from "@/components/notifications/LdNotification";
  import {LOG_TYPES} from "@/lib/Log";
  import DivisionPicker from "@/components/DivisionPicker";
  import ImageWithHeaders from "@/components/ImageWithHeaders";

  export default {
    name: "HeaderBar",
    components: {ImageWithHeaders, DivisionPicker, LdNotification, SearchBar},
    computed: {
      ...mapState(['currentUser', 'currentHospital']),
      ...mapState('UIStore', ['divisionsVisible', 'searchVisible', 'userNotificationsVisible', 'profileVisible', 'headerVisible']),
      hasCustomLogo() {
        return this.currentHospital && this.currentHospital.logo_url
      },
      logoUrl() {
        return this.hasCustomLogo ? this.currentHospital.logo_url : require('@/assets/ld-logo.png')
      }
    },
    methods: {
      ...mapMutations(['toggleSidebar']),
      logOut() {
        Object.keys(localStorage).forEach(key => {
          if (key != 'MASK_PHI') {
            localStorage.removeItem(key)
          }
        })
        sessionStorage.clear()
        this.$logger.logUser(LOG_TYPES.USER.USER_LOGGED_OUT)
        this.$auth.logOut()
      }
    },
    data() {
      return {
        notificationCount: 0
      }
    }
  }
</script>

<style scoped lang="scss">
  .dropdown {
    cursor: pointer;
  }
  .user__logout-link {
    color: red;
  }
  .nav {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #e9ebf7;
    background: $background-level-1;
    display: flex;
    align-items: center;
  }
  .hamburger {
    @include hamburger(30px);
    display: none;
    position: absolute;
    left: 35px;
  }
  .logo {
    width: $sidebar-width;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 50px;
    }
    img.lookdeep-logo {
      width: 180px;
      max-height: none;
    }
    @include mobile {
      width: auto;
      padding-left: 15px;
    }
  }

  .layout__header {
    display: flex;
  }
  .user {
    display: flex;
    align-items: center;
    img {
      width: 35px;
      margin-right: 10px;
    }
  }
  .user__name {
    font-size: 14px;
    padding-bottom: 2px;
  }
  .user__email {
    font-size: 12px;
    opacity: 0.7;
  }
  .nav__right {
    margin-right: 43px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 20px;
    }
  }
  .search {
    max-width: 600px;
    margin: 0 auto;
    flex: 1 1;
  }
  @include until($desktop) {
    .search, .nav__right {
      display: none;
    }
    .logo {
      margin: 0 auto;
    }
    .hamburger {
      display: block;
      /*margin-left: 8px;*/
    }
  }
  @include mobile {
    .hamburger {
      left: 8px;
    }
  }
  .notifications {
    margin-top: 5px;
    :deep(.dropdown-content) {
      width: 360px;
      padding: 0;
      cursor: initial;
      .dropdown-item {
        outline: none !important;
      }
    }
  }
  .notifications__bell {
    position: relative;
    i {
      font-size: 20px;
    }
  }
  .notifications__bell-count {
    position: absolute;
    top: -12px;
    right: -9px;
  }
</style>
