export default {
  namespaced: true,
  state: {
    customFields: {}
  },
  getters: {
    getFieldsFor: state => model => {
      if (!Object.hasOwn(state.customFields, model)) {
        return []
      }
      return state.customFields[model]
    }
  },
  mutations: {
    setCustomFields(state, customFields) {
      state.customFields = customFields
    }
  },
  actions: {
    storeCustomFields(context, customFields) {
      if (!Array.isArray(customFields)) {
        return;
      }
      const fields = {}
      customFields.forEach(data => {
        if (!Object.hasOwn(fields, data.model)) {
          fields[data.model] = {}
        }
        fields[data.model][data.key] = data
      })
      context.commit('setCustomFields', fields)
    }
  }
}
