<template>
  <div>
    <slot :errors="[]" :valid="true" :failed-rules="[]"></slot>
  </div>
</template>

<script>
export default {
  name: "ValidationProvider"
}
</script>
