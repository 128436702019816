export default {
  namespaced: true,
  state: {
    showLiveVideoOverlay: false,
    isPatientCameraPlaying: false,
    isMinimized: false,
    meetingMetaData: {
      device: null,
      patient_monitor: null,
      patient: null,
      kiosk_mode: null,
      meeting_id: null,
      is_preview: false,
      is_ptz: false,
      division_meeting_preview_enabled: null
    },
    monitor: null,
    requestVideoStart: false,
    isPopupMode: true,
    isExternal: false
  },
  mutations: {
    setMeetingMetaData(state, metaData) {
      const is_preview = Object.hasOwn(metaData, 'is_preview') ? metaData.is_preview : false
      state.meetingMetaData = {...state.meetingMetaData, ...metaData, ...{ test_mode: false, is_preview }}
    },
    setTestMeetingMetaData(state, metaData) {
      state.meetingMetaData = {...state.meetingMetaData, ...metaData, ...{ test_mode: true }}
    },
    setMonitor(state, id) {
      state.monitor = id
    },
    setIsMinimized(state, isMinimized) {
      state.isMinimized = isMinimized
    },
    setShowLiveVideoOverlay(state, isDisplayed) {
      state.showLiveVideoOverlay = isDisplayed
    },
    setPatientCameraIsPlaying(state, isPlaying) {
      state.isPatientCameraPlaying = isPlaying
    },
    setRequestVideoStart(state, value) {
      state.requestVideoStart = value
    },
    setIsPopupMode(state, value) {
      state.isPopupMode = value
    },
    setIsExternal(state, value) {
      state.isExternal = value
    }
  }
}
