<template>
  <div class="network-offline">
    <svg width="200px" height="76px" viewBox="0 0 200 76">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="heartbeat" fill="#5E679F" fill-rule="nonzero">
          <path d="M56.4611514,0 C57.8774205,0.120411491 58.812913,0.921956636 58.989048,2.17998713 L64.1922196,39.6504255 L74.749153,39.6496204 C74.8236915,39.6429017 74.899135,39.639469 74.9753359,39.639469 L74.9753359,39.639469 L197.481988,39.639469 C198.86321,39.639469 200,40.7707979 200,42.1573272 C200,43.5447551 198.86321,44.6751854 197.481988,44.6751854 L197.481988,44.6751854 L77.6318978,44.6769157 C77.5603926,44.6830243 77.488027,44.6861419 77.4149254,44.6861419 L61.9968227,44.6861419 C60.7378169,44.6861419 59.6756151,43.7623881 59.5138584,42.5124449 L56.7882592,22.9509692 L51.2858379,73.2838709 C51.1537366,74.4691153 50.193082,75.4180298 48.9844005,75.5069905 C48.9134073,75.5114835 48.8540966,75.5186722 48.7786101,75.5186722 C47.6544016,75.5186722 46.6631929,74.7800286 46.3657404,73.6828463 L38.1646793,44.5145106 L30.0876317,59.8606855 C29.6032604,60.7844393 28.5895855,61.34606 27.559735,61.1897048 C26.518202,61.0648004 25.6716757,60.3126779 25.4137637,59.3017605 L21.482179,43.5853652 L2.51891017,43.5853652 C1.1340937,43.5862638 0,42.4540363 0,41.0684056 C0,39.6764847 1.1340937,38.5505474 2.51891017,38.5505474 L23.4439274,38.5505474 C24.5977914,38.5505474 25.6114663,39.3368165 25.8882498,40.4582608 L28.695626,51.6789943 L36.6423697,36.5925128 C37.1168558,35.673252 38.1080645,35.1206171 39.1262326,35.2554061 C40.1479953,35.3641359 41.0035082,36.0866048 41.2937715,37.0741588 L47.6894488,59.8256403 L53.9961601,2.23480132 C54.1300586,0.975872229 55.183274,0.0197690507 56.4611514,0 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
    <p>Network is offline. Click the refresh button below to try again</p>
    <button class="button is-primary" @click="refresh">Refresh</button>
  </div>
</template>

<script>
export default {
  name: "OfflineNetworkOverlay",
  methods: {
    refresh() {
      window.location.reload()
    }
  }
}
</script>

<style scoped>
  .network-offline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e8eaf6;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .network-offline svg {
    margin: -100px 0 100px;
  }
  .network-offline p {
    font-size: 17px;
  }
  .network-offline button {
    margin-top: 20px;
  }
</style>