<template>
  <div class="table-resizer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TableColumnResizer",
  props: ['columns'],
  methods: {
    /**
     *
     * @param {HTMLElement} handler
     */
    setupEventHandlers(handler) {
      handler.addEventListener('mousedown', this.mouseDownHandler(handler))
    },
    removeEventhandler(handler) {
      handler.removeEventListener('mousedown', this.mouseDownHandler(handler))
    },
    mouseDownHandler(handler) {
      return (event) => {
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        handler.parentElement.style.pointerEvents = 'none'
        this.activeHandler = handler
        this.startX = event.clientX
        this.currentThWidth = parseFloat(getComputedStyle(this.activeHandler.parentElement).width)
        this.activeHandler.classList.add('active')
        this.activeHandler.parentElement.classList.add('active')
        this.allThs.forEach(th => {
          if (th != this.activeHandler.parentElement) {
            th.classList.add('no-hover')
          }
        })
        document.addEventListener('mousemove', this.mouseMovehandler);
        document.addEventListener('mouseup', this.mouseUpHandler)
      }
    },
    mouseMovehandler(event) {
      const delta = event.clientX - this.startX
      this.activeHandler.parentElement.style.width = (this.currentThWidth + delta) + 'px'
      this.updateTableWidth(this.originalTableWidth + delta)
    },
    mouseUpHandler(event) {
      event.stopPropagation()
      event.preventDefault()
      event.stopImmediatePropagation()
      this.activeHandler.parentElement.classList.remove('active')
      this.activeHandler.classList.remove('active')
      this.activeHandler.parentElement.style.pointerEvents = 'auto'
      this.activeHandler = null
      this.startX = null
      this.moveX = null
      this.currentThWidth = null
      this.originalTableWidth = parseFloat(this.table.style.width)
      this.allThs.forEach(th => th.classList.remove('no-hover'))
      document.removeEventListener('mousemove', this.mouseMovehandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
    },
    calculateTableWidth() {
      return this.allThs.reduce((acc, th) => {
        return acc + parseFloat(getComputedStyle(th).width)
      }, 0)
    },
    init() {
      if (this.allThs.length && this.table) {
        this.table.querySelectorAll('.resize-handler').forEach(handler => {
          this.removeEventhandler(handler)
          handler.remove()
        })
        this.allThs = []
      }
      this.table = document.querySelector('.table-resizer table')
      if (!this.table) {
        return;
      }
      this.allThs = Array.from(this.table.querySelectorAll('thead th'))
      this.allThs.forEach(th => {
        const resizeHandler = document.createElement('div')
        resizeHandler.classList.add('resize-handler')
        this.setupEventHandlers(resizeHandler)
        th.append(resizeHandler)
      })
      this.originalTableWidth = this.calculateTableWidth()
      this.updateTableWidth(this.originalTableWidth)
    },
    updateTableWidth(width) {
      this.table.style.width = width + 'px'
    }
  },
  mounted() {
    //TODO: @gurik fix this
    setTimeout(() => {
        this.init()
    }, 1000)
  },
  data() {
    return {
      activeHandler: null,
      startX: null,
      moveX: null,
      currentThWidth: null,
      allThs: [],
      table: null,
      originalTableWidth: null
    }
  }
}
</script>

<style scoped lang="scss">
:deep {
  table thead th {
    //background: #f2f5fb;
    transition: background 0.1s linear;
    transition-property: background, color;
    &:not(.no-hover) {
      &:hover, &.active {
        background: #d2e3ff !important;
        color: #4473bf !important;
        > .resize-handler {
          opacity: 1;
        }
      }
    }
    &.is-sticky {
      position: sticky !important;
    }
  }
  table tbody td {
    &.is-sticky {
      //border-radius: 0 !important;
      //border-bottom: none !important;
      //border-top: none !important;
      //box-shadow: 0 3px 4px 1px #dce0e6 !important;
    }
  }
  .resize-handler {
    transition: all 0.2s linear;
    width: 4px;
    height: 100%;
    background: #2d7bff;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    cursor: ew-resize;
    &.active {
      opacity: 1;
    }
  }
}
</style>
