const MASKS = Object.freeze({
  mrn: value => {
    return value.slice(-3).padStart(value.length, '*')
  },
  dob: value => {
    return `**/**/${value.slice(-4)}`
  },
  patient_name: value => {
    return value.split(' ').map(x => x[0].padEnd(6, '*')).join(' ')
  },
  room_name: value => {
    return value.slice(-2).padStart(value.length, '*')
  }
});

export function mask(maskType, value) {
  if (!Object.hasOwn(MASKS, maskType)) {
    throw new Error(`Unknown mask type. Supported types: ${ Object.keys(MASKS).join() }`)
  }
  if (value) {
    return window.localStorage.getItem('MASK_PHI') === 'true' ? MASKS[maskType](String(value).trim()) : value
  } else {
    return ''
  }
}
