import Pusher from "pusher-js/with-encryption";
import store from '@/store'

export const CHANNEL = Object.freeze({
  FOR_USER: ({env, user}) => {
    return `private-encrypted-ld-${env}-user-${user.id}`
  },
  FOR_HOSPITAL: ({env, hospital}) => {
    return `private-encrypted-ld-${env}-hospital-${hospital.id}`
  }
});

export const CLIENT_EVENT = Object.freeze({
  TIMER_RESET: 'client-timer-reset',
  LOGOUT_WITH_EXPIRATION: 'client-logout-with-expiration',
  SESSION_EXPIRED: 'client-session-expired',
  CURTAIN_TOGGLE: 'client-virtual-curtain-toggled',
  MIC_TOGGLED: 'client-patient-monitor-microphone-toggled',
  LISTEN_TOGGLED: 'client-patient-monitor-listen-toggled',
  ROOM_NOTIFICATION: 'client-room-notification',
  USER_AUTHENTICATED: 'client-user-authenticated',
  ROOM_NOTIFICATION_ACCEPTED: 'client-room-notification-accepted',
  VIDEO_CONNECTION_SUCCESSFUL: 'client-video-connection-successful',
});
export const SERVER_EVENT = Object.freeze({
  USER_UPDATED: 'user-updated',
  CURTAIN_TOGGLED: 'patient-monitor-curtain-toggled',
  USER_ASSIGNMENTS_UPDATED: 'user-assignments-updated',
  DEVICE_STATUS_CHANGED: 'device-status-changed',
  WIFI_STATS_UPDATED: 'wifi-stats-updated',
  REQUEST_BROWSER_REFRESH: 'request-browser-refresh',
  PATIENT_MONITOR_UPDATED: 'patient-monitor-updated',
  PATIENT_MONITOR_TRANSFER_CREATED: 'patient-monitor-transfer-created',
  PATIENT_MONITOR_TRANSFER_ACCEPTED: 'patient-monitor-transfer-accepted',
  PATIENT_MONITOR_TRANSFER_SUCCESSFUL: 'patient-monitor-transfer-successful',
  PATIENT_MONITOR_TRANSFER_CANCELLED: 'patient-monitor-transfer-cancelled',
  ASSIGNMENTS_UPDATED_EXTERNAL: 'assignments-updated-external',
  PATIENT_EVENTS_UPDATED: 'patient-events-updated',
})

export default {
  install: function(app, opts) {
    let pusher = null
    const getAuthObject = (token) => {
      return {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }
    }
    if (store.state.token) {
      opts.options['auth'] = getAuthObject(store.state.token)
    } else {
      const unsubscribe = store.subscribe((mutation) => {
        if (mutation.type == 'setToken') {
          opts.options['auth'] = getAuthObject(mutation.payload.token)
          opts.options['authEndpoint'] += '?division_id=*'
          pusher = new Pusher(opts.appKey, opts.options)
          unsubscribe()
        }
      })
    }
    /**
     * @type Pusher
     */
    Object.defineProperty(app.config.globalProperties, '$pusher', {
      get () {
        if (!pusher) {
          throw new Error("$pusher called before auth token was set")
        }
        return pusher
      }
    })
  }
}
