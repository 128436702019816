<template>
  <div class="ld-modal">
    <div class="ld-modal__header" v-if="$slots.header || title">
      <slot v-if="$slots.header" name="header"></slot>
      <template v-else>
        <div class="ld-modal__header-middle">
          <p class="ld-modal__title">{{ title }}</p>
          <p class="ld-modal__subtitle" v-if="subtitle || $slots.subtitle">
            <slot name="subtitle" v-if="$slots.subtitle"></slot>
            <template v-else-if="subtitle">{{ subtitle }}</template>
          </p>
        </div>
      </template>
    </div>
    <div class="ld-modal__content">
      <slot></slot>
    </div>
    <div class="ld-modal__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LdModalContent",
  props: ['title', 'subtitle']
}
</script>

<style scoped lang="scss">
  .ld-modal {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    background: #fff;
    padding: 0 0 20px;
    border-radius: 8px;
    transition: all linear 0.1s;
    width: 700px;
  }
  .ld-modal__header {
    display: flex;
    align-items: center;
    background: white;
    margin: 0 0 20px 0;
    padding: 6px 10px 0;
    min-height: 50px;
    border-bottom: 1px solid $background-level-0;
    > *,
    ::v-slotted(> *) {
      flex: 1 1;
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .ld-modal__title,
  ::v-slotted(.ld-modal__title) {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 14px;
  }
  .ld-modal__subtitle,
  ::v-slotted(.ld-modal__subtitle) {
    font-size: 12px;
    padding-top: 4px;
    color: #687584;
    span {
      font-weight: 500;
    }
  }
  .ld-modal__header-middle,
  ::v-slotted(.ld-modal__header-middle) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ld-modal__value {
    white-space: pre-line;
    line-height: 20px;
    font-size: 15px;
  }
  .ld-modal__content {
    overflow-y: auto;
    padding: 0 20px;
  }
</style>
<style>
  .modal .modal-close {
    position: absolute;
    right: -40px;
    top: 11px;
  }
</style>
