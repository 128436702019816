// reusable function to calculate next available division ID for the user
export function getAvailableDivisionId({ userObject }) {
  const urlParams = new URLSearchParams(window.location.search)
  let selectedDivisionId = null
  if (urlParams.has('division_id')) {
    selectedDivisionId = urlParams.get('division_id')
  } else {
    const sessionDivisionId = window.sessionStorage.getItem('DIVISION_ID')
    selectedDivisionId = sessionDivisionId || userObject.preferred_division_id
  }

  // if we don't have selected division ID or division ID was in session storage while other user
  // removed it
  if (!selectedDivisionId || !userObject.divisions.find(x => x.id == selectedDivisionId)) {
    // happens when currently selected 'all divisions' and navigating to page where 'all divisions' is not available
    if (selectedDivisionId == '*' && userObject.preferred_division_id) {
      selectedDivisionId = userObject.preferred_division_id
    } else {
      // hospitals' main division is nullable. check if user has access to it
      if (userObject.hospital.main_division_id && userObject.divisions.find(x => x.id == userObject.hospital.main_division_id)) {
        selectedDivisionId = userObject.hospital.main_division_id
      } else {
        // no way to find which division to load. getting user's first one
        selectedDivisionId = userObject.divisions[0].id
      }
    }
  }
  return selectedDivisionId
}
