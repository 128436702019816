<template>
  <img :src="blobUrl" v-if="blobUrl" alt="">
</template>

<script>
import to from "@/lib/to";

export default {
  name: "ImageWithHeaders",
  props: ['url'],
  async created() {
    if (this.url) {
      const [err, response] = await to(this.$http.get(this.url, { responseType: 'blob', handleErrors: true, skipLoading: true }))
      if (!err) {
        this.blobUrl = URL.createObjectURL(response.data)
      }
    }
  },
  data() {
    return {
      blobUrl: null
    }
  }
}
</script>

<style scoped>

</style>
