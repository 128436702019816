import AppInstance from "@/lib/AppInstance";
import App from './App.vue'
import router from './router'
import store from './store'
import { Auth0 } from "@/auth/Auth0";
import '@/assets/styles/app.scss'
import Default from "@/layouts/Default";
import createAxiosInstance from '@/lib/axios';
import Helpers from '@/lib/helpers'
import HighchartsVue from 'highcharts-vue'
import HighchartsMore from 'highcharts/highcharts-more'
import { Promised } from 'vue-promised'
import { abilitiesPlugin } from '@casl/vue';
import { defineAbility } from "@casl/ability";
import './components/validation/init'
import Can from "@/components/permissions/Can";
import Highcharts from 'highcharts';
import Pusher from "./lib/Pusher"
import Buefy from 'ld-buefy'
import ValidationProvider from "@/components/validation/ValidationProvider";
import ValidationObserver from "@/components/validation/ValidationObserver";
import componentBus from '@/lib/ComponentEventBus'
import LdSkeleton from "@/components/skeletons/LdSkeleton";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import FloatingVue from 'floating-vue'
import {createLoggerInstance} from "@/lib/Log";
import HelpScout from "@/lib/HelpScout";
import {defineRule} from "vee-validate";


const app = AppInstance.create(App)
app.use(FloatingVue, {
  themes: {
    'badge-popper': {
      $extend: 'dropdown',
    },
  },
})
app.directive('tooltip', FloatingVue.vTooltip)
app.use(HelpScout, { key: process.env.VUE_APP_HELPSCOUT_KEY })
app.component('LdSkeleton', LdSkeleton)
app.component('ValidationProvider', ValidationProvider)
app.component('ValidationObserver', ValidationObserver)
app.use(router)
app.use(store)
const axiosInstance = createAxiosInstance(router)
app['$http'] = axiosInstance
app.config.globalProperties['$http'] = axiosInstance
const loggerInstance = createLoggerInstance(axiosInstance)
app.config.globalProperties['$logger'] = loggerInstance
app.mixin({
  data() {
    return {
      http: axiosInstance
    }
  }
})
for (const [key, value] of Object.entries(componentBus)) {
  app.config.globalProperties[key] = value
}
app.use(Pusher, {
  appKey: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/v1/pusher/auth`
  }
});

/**
 * Highcharts custom legend color
 * http://jsfiddle.net/fypopsnc/5/
 */
(function(H) {
  H.wrap(H.Legend.prototype, 'colorizeItem', function(proceed, item) {
    var color = item.color;
    if (item.options.legendColor) {
      item.color = item.options.legendColor;
    }
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    item.color = color;
  });
}(Highcharts));

HighchartsMore(Highcharts);
Highcharts.setOptions({
  colors: ["#7cb5ec", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1", "#434348"]
});

app.use(abilitiesPlugin, defineAbility(() => {}), {
  useGlobalProperties: true
})
app.component('Can', Can);
app.component('Promised', Promised)
app.use(HighchartsVue)
app.config.productionTip = false
app.use(Helpers, {
  store
})
app.component('default-layout', Default)

var strategies = app.config.optionMergeStrategies
strategies.widget = strategies.methods
app.use(Buefy)

app.use(Auth0, {
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  redirectUri: `${process.env.VUE_APP_BASE_URL}/`,
  router,
  axiosInstance,
  loggerInstance
});

if (process.env.NODE_ENV == 'production' && !navigator.userAgent?.includes("HeadlessChrome")) {
  const apiUrl = process.env.VUE_APP_API_BASE_URL.replace('https://', '')
  const sentryConfig = {
    app,
    dsn: 'https://d7566269227e45c6aff0163de398e0fc@o1089958.ingest.sentry.io/6107167',
    environment: process.env.VUE_APP_ENVIRONMENT_NAME,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [apiUrl, /^\//],
      })
    ],
    tracesSampleRate: 0.1,
    release: process.env.VUE_APP_SENTRY_RELEASE
  }

  if (process.env.VUE_APP_ENVIRONMENT_NAME == 'qa') {
    sentryConfig.integrations.push(
      new Sentry.Replay({
        networkDetailAllowUrls: [apiUrl],
        maskAllText: false,
        maskAllInputs: false,
      })
    )
    sentryConfig.replaysSessionSampleRate = 0
    sentryConfig.replaysOnErrorSampleRate = 1.0
  }

  Sentry.init(sentryConfig);
}

defineRule('positiveInteger', value => {
  return value == '' || /^[1-9][0-9]*$/.test(value)
})

defineRule('lengthLessThan', (value, [targetValue]) => {
  if (targetValue) {
    if (+value > +targetValue) {
      return 'Should be less or equal to maximum number of characters'
    }
  }
  return true
})
defineRule('valueLessThan', (value, [targetValue]) => {
  if (targetValue) {
    if (+value > +targetValue) {
      return 'Should be less or equal to maximum value'
    }
  }
  return true
})


app.mount('#app')
