export default {
  namespaced: true,
  state: {
    readOnlySafetyZoneEnabled: true,
    nurseStationEnabled: false,
  },
  mutations: {
    setReadOnlySafetyZoneEnabled(state, value) {
      state.readOnlySafetyZoneEnabled = value
    },
    setNurseStationEnabled(state, value) {
      state.nurseStationEnabled = value
    }
  }
}
