import createAuth0Client from "@auth0/auth0-spa-js";
import {reactive, watchEffect} from "vue";
import store from "@/store"
import * as Sentry from "@sentry/vue";
import {LOG_TYPES} from "@/lib/Log";
import to from "@/lib/to";
import { getAvailableDivisionId } from "@/lib/Divisions";
import {applyPermissions} from "@/lib/Permissions";
import {CLIENT_EVENT} from "@/lib/Pusher";
import { v4 as uuidv4 } from 'uuid';

let auth0Client, auth0Instance, appRedirectUri;
const AUTH0_ORG_ID = 'auth0_org_id';

const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  error: null
})


const logInWithRedirect = async (redirectUrl, orgId) => {
  // redirect to naked domain if www is used because of complicated Auth0 state issue if we don't redirect
  if (window.location.hostname.startsWith('www')) {
    console.log(`found www url. redirecting to ${appRedirectUri}`)
    window.location = appRedirectUri
    return
  }
  let auth0Params = {}, org = orgId || localStorage.getItem(AUTH0_ORG_ID)
  if (org) {
    auth0Params['organization'] = org
  }
  return auth0Client.loginWithRedirect({
    ...auth0Params,
    appState: { targetUrl: redirectUrl },
    prompt: 'login',  // needs to be set so the backend gets a new "iat" value on login - see authenticator.rb
  })
}
const logOut = async () => {
  auth0Client.logout()
}

const setTokenAndSessionId = (token, sessionId) => {
  store.commit('setToken', { token })
  store.commit('setUniqueSessionId', { id: sessionId })
}

const init = async (options, { $http, $buefy, logger }) => {
  Sentry.configureScope(scope => {
    scope.setUser(null);
  });

  state.error = false
  const orgId = localStorage.getItem(AUTH0_ORG_ID)
  appRedirectUri = options.redirectUri  // track the primary application root redirect uri - currently the naked domain
  let auth0Params = {}
  if (orgId) {
    auth0Params['organization'] = orgId
  }
  try {
    auth0Client = await createAuth0Client({
      domain: options.domain,
      client_id: options.clientId,
      audience: options.audience,
      redirect_uri: options.redirectUri,
      ...auth0Params
    })
  } catch(e) {
    if (e.message.startsWith("parameter organization is invalid")) {
      console.warn("Invalid auth0 orgId detected, removing from local storage. Close this browser and try again.")
      localStorage.removeItem(AUTH0_ORG_ID);
    }
    throw e;
  }

  if (window.location.pathname === '/login') {
    // NOTE: since this will be triggered from Auth0 or LD configured URLs, assume we don't need to redirect for www
    // configured as default auth0 login path. this url generally redirected to after actions like reset password
    const params = new URLSearchParams(window.location.search);
    if (params.has("organization")) {
      console.log(`logging in with redirect. org = ${params.get('organization')}`);
      auth0Client.loginWithRedirect({
        organization: params.get("organization"),
        appState: {
          targetUrl: "/"
        }
      });
      return;
    } else {
      options.router.push({ name: 'password-changed' })
    }
  }

  const uniqueId = uuidv4()

  try {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code') || params.has('state') || params.has('error')) {
      const data = await auth0Client.handleRedirectCallback();
      // handle the redirect and retrieve tokens
      if (params.has('state') && params.has('code')) {
        let isAuthed = await auth0Client.isAuthenticated();
        if (isAuthed) {
          setTokenAndSessionId(await auth0Client.getTokenSilently(), uniqueId)
          const event = CLIENT_EVENT.USER_AUTHENTICATED
          const data = { uniqueId }
          to($http.post('/pusher/trigger_user', { event, data }, { skipDivisions: true, skipLoading: true, isPusherMessage: true }))
        }
        const targetUrl = data.appState.targetUrl
        if (targetUrl) {
          options.router.push(targetUrl)
        }
      }
    }
  } catch (e) {

    state.error = e;
    console.warn(`auth error: ${e.error_description}`, e);
    let message = '';
    if (e.error_description == 'authorization request parameter organization must be an organization id') {
      message = 'Organization ID is not correct. Click button below to continue sign-in.'
    } else if (e.error_description == 'user is blocked') {
      message = `This login is disabled. If you have a corporate login, try clicking "Login with corporate SSO" on the login page.`
    } else {
      message = e.error_description
    }
    $buefy.dialog.alert({
      message,
      confirmText: 'Sign-In Again',
      confirmCallback: () => {
        logInWithRedirect()
      }
    })
  } finally {
    // Initialize our internal authentication state

    let isAuthed = await auth0Client.isAuthenticated();
    if (isAuthed) {
      setTokenAndSessionId(await auth0Client.getTokenSilently(), uniqueId)
      const requests = [$http.get('/users/current'), $http.get('/custom_fields/all', { handleErrors: true })]
      const [err, response] = await to(Promise.all(requests))
      if (!err) {
        const [{ data: user }, { data: customFields }] = response
        store.commit('setDivisionId', getAvailableDivisionId({ userObject: user }))
        store.commit('setCurrentUser', user)
        store.commit('setCurrentHospital', user.hospital)
        store.dispatch('TableFiltersStore/storeTableSettings', user.table_user_settings)
        store.dispatch('CustomFieldsStore/storeCustomFields', customFields)
        localStorage.setItem(AUTH0_ORG_ID, user.hospital.auth0_id)
        applyPermissions(user.permissions)
        state.isAuthenticated = isAuthed
        state.loading = false

        Sentry.configureScope(scope => {
          scope.setUser({ id: user.id, hospital_id: user.hospital_id});
        });
        logger.logUser(LOG_TYPES.USER.USER_AUTHENTICATED)
      }
      store.commit('setAuthLoading', false)
    } else {
      state.loading = false
      store.commit('setAuthLoading', false)
    }
  }
  return {
    logInWithRedirect,
    logOut,
    buildAuth0Client: async (additionalParams = {}) => {
      let auth0Params = {
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: options.redirectUri,
        ...additionalParams
      }
      return await createAuth0Client(auth0Params)
    }
  }
}
export const Auth0Guard = (to, from, next) => {
  const verify = async () => {
    if (state.isAuthenticated || to.meta.unsecured) {
      next()
    } else {
      if (!state.error) {
        await logInWithRedirect(to.fullPath)
      }
    }
  }
  watchEffect(() => {
    if (!state.loading) {
      verify()
    }
  })
}

export const Auth0 = {
  install: async (app, options) => {
    auth0Instance = await init(options, {
      $http: options.axiosInstance,
      $buefy: app.config.globalProperties.$buefy,
      logger: options.loggerInstance,
    })
    app.provide('$auth', auth0Instance);
    app.config.globalProperties.$auth = auth0Instance
  },
}
export const getInstance = () => {
  return auth0Instance
}
