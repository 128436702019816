<template>
  <div>
    <b-slider :model-value="modelValue" :min="60" :max="100" @change="$emit('volume-change', $event)" v-bind="$attrs"></b-slider>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "LvuVolumeControl",
  props: ['modelValue'],
  emits: ['volume-change']
}
</script>