import AppInstance from "@/lib/AppInstance";
export default {
  props: ['i', 'a', 'custom'],
  setup(props, { slots }) {
    return () => {
      const v = () => { return null }
      const {i: action, a: subject, custom} = props
      const actionAllowed = typeof custom === 'undefined' ? AppInstance.shared.config.globalProperties.$can(action, subject) : custom
      const {yes: yesSlot, no: noSlot = v, default: defaultSlot} = slots
      if (actionAllowed) {
        return (yesSlot || defaultSlot)()
      } else {
        return noSlot()
      }
    }
  }
}
