import {createRouter, createWebHistory, RouterView} from 'vue-router'
import { h } from 'vue'
import { Auth0Guard } from "@/auth/Auth0";
import * as qs from "qs";
import store from "@/store";
import {getAvailableDivisionId} from "@/lib/Divisions";


const proxyComponent = { render: () => h(RouterView)}
const routes = [
  {
    path: '/h/:orgId',
    component: () => import(/* webpackChunkName: "fhir-launch" */ '@/views/HospitalRedirect'),
    name: 'hospital-redirect',
    meta: {
      unsecured: true,
      layout: 'blank'
    }
  },
  {
    path: '/helpscout-auth',
    name: 'helpscout-auth',
    component: () => import(/* webpackChunkName: "helpscout-auth" */ '@/views/HelpScoutAuth'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/password-changed',
    component: () => import(/* webpackChunkName: "password-changed" */ '@/views/PasswordChanged'),
    name: 'password-changed',
    meta: {
      unsecured: true,
      layout: 'blank'
    }
  },
  {
    path: '/fhir-launch',
    name: 'fhir-launch',
    component: () => import(/* webpackChunkName: "fhir-launch" */ '@/views/FhirLaunch'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/fhir',
    name: 'fhir',
    component: () => import(/* webpackChunkName: "fhir" */ '@/views/Fhir'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
    meta: {
      allDivisionsEnabled: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/patients',
    component: proxyComponent,
    children: [
      {
        path: '',
        name: 'patients.index',
        component: () => import(/* webpackChunkName: "patients.index" */ '@/views/patient/PatientList'),
      },
      {
        path: 'add',
        name: 'patients.add',
        component: () => import(/* webpackChunkName: "patients.add" */ '@/views/patient/AddPatient'),
      },
      {
        path: ':id/edit',
        name: 'patients.edit',
        component: () => import(/* webpackChunkName: "patients.add" */ '@/views/patient/AddPatient'),
      },
      {
        path: ':id/view',
        name: 'patients.view',
        component: () => import(/* webpackChunkName: "patients.view" */ '@/views/patient/ViewPatient')
      },
      {
        path: ':id/overview',
        component: () => import(/* webpackChunkName: "patients.view" */ '@/views/patient/overview/OverviewLayout'),
        children: [
          {
            path: 'edit',
            name: 'patients.overview.edit',
            component: () => import(/* webpackChunkName: "patients.overview.edit" */ '@/views/patient/overview/OverviewEdit'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'monitors',
            name: 'patients.overview.monitors',
            component: () => import(/* webpackChunkName: "patients.overview.monitors" */ '@/views/patient/overview/OverviewMonitors'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'forms',
            name: 'patients.overview.forms',
            component: () => import(/* webpackChunkName: "patients.overview.forms" */ '@/views/patient/overview/OverviewForms'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'video-visit',
            name: 'patients.overview.video-visit',
            component: () => import(/* webpackChunkName: "patients.overview.video-visit" */ '@/views/patient/overview/OverviewVideoVisits'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'ai-insights',
            name: 'patients.overview.ai-insights',
            component: () => import(/* webpackChunkName: "patients.overview.ai-insights" */ '@/views/patient/overview/OverviewAiInsights.vue'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/patient-monitors',
    component: proxyComponent,
    children: [
      {
        path: '',
        name: 'patient-monitors.index',
        component: () => import(/* webpackChunkName: "patient-monitors.index" */ '@/views/patient_monitors/PatientMonitorsList')
      },
      {
        path: 'multipause',
        name: 'patient-monitors.multipause',
        component: () => import(/* webpackChunkName: "patient-monitors.multipause" */ '@/views/patient_monitors/MultiPause'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'multiresume',
        name: 'patient-monitors.multiresume',
        component: () => import(/* webpackChunkName: "patient-monitors.multiresume" */ '@/views/patient_monitors/MultiResume'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: '/patient-monitors/add',
        name: 'patient-monitors.add',
        component: () => import(/* webpackChunkName: "patient-monitors.add" */ '@/views/patient_monitors/AddMonitor'),
        meta: {
          allDivisionsEnabled: false
        }
      },
      {
        path: '/patient-monitors/:id/edit',
        name: 'patient-monitors.edit',
        component: () => import(/* webpackChunkName: "patient-monitors.edit" */ '@/views/patient_monitors/AddMonitor'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: '/patient-monitors/:id/monitoring',
        name: 'patient-monitors.monitoring',
        component: () => import(/* webpackChunkName: "patients.monitoring" */ '@/views/patient_monitors/Monitoring'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: '/patient-monitors/:id/view',
        name: 'patient-monitors.view',
        component: () => import(/* webpackChunkName: "patients.overview" */ '@/views/patient_monitors/Overview'),
        meta: {
          withoutDivision: true
        }
      }
    ]
  },
  {
    path: '/health-system',
    component: proxyComponent,
    children: [
      {
        path: 'custom-fields',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hsl.custom-fields.index',
            component: () => import(/* webpackChunkName: "hsl.custom-fields.index" */ '@/views/hsl/custom_fields/CustomFields'),
            meta: {
              withoutDivision: true
            },
          },
          {
            path: 'add',
            name: 'hsl.custom-fields.add',
            component: () => import(/* webpackChunkName: "hsl.custom-fields.add" */ '@/views/hsl/custom_fields/EditCustomField'),
            meta: {
              withoutDivision: true
            },
          },
          {
            path: ':id/edit',
            name: 'hsl.custom-fields.edit',
            component: () => import(/* webpackChunkName: "hsl.custom-fields.edit" */ '@/views/hsl/custom_fields/EditCustomField'),
            meta: {
              withoutDivision: true
            },
          }
        ]
      },
      {
        path: 'tags',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hsl.tags',
            component: () => import(/* webpackChunkName: "hsl.tags.index" */ '@/views/hsl/tags/TagsIndex'),
            meta: {
              withoutDivision: true
            },
          },
          {
            path: 'add',
            name: 'hsl.tags.add',
            component: () => import(/* webpackChunkName: "hsl.tags.add" */ '@/views/hsl/tags/AddTags'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'hsl.tags.edit',
            component: () => import(/* webpackChunkName: "hsl.tags.edit" */ '@/views/hsl/tags/AddTags'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      },
      {
        path: 'manage-views',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hsl.manage-views',
            component: () => import(/* webpackChunkName: "manage-views" */ '@/views/hsl/manage_views/ManageViews'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'hsl.manage-views.edit',
            component: () => import(/* webpackChunkName: "manage-views.edit" */ '@/views/hsl/manage_views/EditSharedView'),
            meta: {
              withoutDivision: true
            }
          },
        ]
      },
      {
        path: 'hospitals',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hsl.hospitals.index',
            component: () => import(/* webpackChunkName: "hsl.hospitals.index" */ '@/views/hsl/hospitals/HospitalsIndex'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'add',
            name: 'hsl.hospitals.add',
            component: () => import(/* webpackChunkName: "hsl.hospitals.add" */ '@/views/hsl/hospitals/ManageHospital'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'hsl.hospitals.edit',
            component: () => import(/* webpackChunkName: "hsl.hospitals.edit" */ '@/views/hsl/hospitals/ManageHospital'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      },
      {
        path: 'preferences',
        name: 'hsl.preferences',
        component: () => import(/* webpackChunkName: "hsl.preferences" */ '@/views/hsl/preferences'),
        meta: {
          withoutDivision: true
        }
      },
    ]
  },
  {
    path: '/hospital',
    component: proxyComponent,
    children: [
      {
        path: 'preferences',
        name: 'hospital.preferences',
        component: () => import(/* webpackChunkName: "hospital.preferences" */ '@/views/hospital/DivisionPreferences'),
        meta: {
          allDivisionsEnabled: false
        }
      },
      {
        path: 'rooms',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hospital.rooms',
            component: () => import(/* webpackChunkName: "hospital.rooms" */ '@/views/hospital/rooms/Rooms'),
          },
          {
            path: 'add',
            name: 'hospital.rooms.add',
            component: () => import(/* webpackChunkName: "hospital.rooms.add" */ '@/views/hospital/rooms/AddRoom'),
            meta: {
              allDivisionsEnabled: false
            }
          },
          {
            path: ':id/edit',
            name: 'hospital.rooms.edit',
            component: () => import(/* webpackChunkName: "hospital.rooms.edit" */ '@/views/hospital/rooms/AddRoom'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      },
      {
        path: 'units',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hospital.units',
            component: () => import(/* webpackChunkName: "hospital.units" */ '@/views/hospital/units/Units'),
          },
          {
            path: 'add',
            name: 'hospital.units.add',
            component: () => import(/* webpackChunkName: "hospital.units.add" */ '@/views/hospital/units/AddUnit'),
          },
          {
            path: ':id/edit',
            name: 'hospital.units.edit',
            component: () => import(/* webpackChunkName: "hospital.units.edit" */ '@/views/hospital/units/AddUnit'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/view',
            name: 'hospital.units.view',
            component: () => import(/* webpackChunkName: "hospital.units.view" */ '@/views/hospital/units/ViewUnit'),
          },
        ]
      },
      {
        path: 'video_devices',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'hospital.video_devices',
            component: () => import(/* webpackChunkName: "hsl.videoDevices" */ '@/views/hospital/video_devices/VideoDevices'),
          },
          {
            path: 'add',
            name: 'hospital.video_devices.add',
            component: () => import(/* webpackChunkName: "hsl.videoDevices.add" */ '@/views/hospital/video_devices/AddVideoDevice'),
          },
          {
            path: ':id/edit',
            name: 'hospital.video_devices.edit',
            component: () => import(/* webpackChunkName: "hsl.videoDevices.edit" */ '@/views/hospital/video_devices/AddVideoDevice'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/view',
            name: 'hospital.video_devices.view',
            component: () => import(/* webpackChunkName: "hsl.videoDevices.view" */ '@/views/hospital/video_devices/ViewVideoDevice'),
          }
        ]
      },
      {
        path: 'homepage',
        name: 'hospital.homepage',
        component: () => import(/* webpackChunkName: "hospital.homepage" */ '@/views/hospital/HomepageSettings'),
        meta: {
          allDivisionsEnabled: false
        }
      },
    ]
  },
  {
    path: '/user-management',
    component: proxyComponent,
    children: [
      {
        path: 'users',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'user-management.users',
            component: () => import(/* webpackChunkName: "user-management.users" */ '@/views/user_management/users/Users'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'user-management.users.edit',
            component: () => import(/* webpackChunkName: "user-management.users.edit" */ '@/views/user_management/users/AddEditUser'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'add',
            name: 'user-management.users.add',
            component: () => import(/* webpackChunkName: "user-management.users.add" */ '@/views/user_management/users/AddEditUser'),
            meta: {
              withoutDivision: true
            }
          },
        ]
      },
      {
        path: 'roles',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'user-management.roles',
            component: () => import(/* webpackChunkName: "user-management.roles" */ '@/views/user_management/roles/Roles'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'add',
            name: 'user-management.roles.add',
            component: () => import(/* webpackChunkName: "user-management.roles.add" */ '@/views/user_management/roles/AddRole'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'user-management.roles.edit',
            component: () => import(/* webpackChunkName: "user-management.roles.edit" */ '@/views/user_management/roles/AddRole'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/view',
            name: 'user-management.roles.view',
            component: () => import(/* webpackChunkName: "user-management.roles.view" */ '@/views/user_management/roles/ViewRole'),
            meta: {
              withoutDivision: true
            }
          },
        ]
      },
      {
        path: 'groups',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'user-management.groups',
            component: () => import(/* webpackChunkName: "user-management.groups" */ '@/views/user_management/groups/Groups'),
            meta: {
              withoutDivision: true
            },
          },
          {
            path: 'add',
            name: 'user-management.groups.add',
            component: () => import(/* webpackChunkName: "user-management.groups.add" */ '@/views/user_management/groups/AddGroup'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'user-management.groups.edit',
            component: () => import(/* webpackChunkName: "user-management.groups.edit" */ '@/views/user_management/groups/AddGroup'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/view',
            name: 'user-management.groups.view',
            component: () => import(/* webpackChunkName: "user-management.groups.view" */ '@/views/user_management/groups/ViewGroup'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      },
      {
        path: 'access-browser',
        name: 'user-management.access-browser',
        component: () => import(/* webpackChunkName: "user-management.access-browser" */ '@/views/user_management/AccessBrowser'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'family-connect',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'user-management.family-connect',
            component: () => import(/* webpackChunkName: "user-management.family-connect" */ '@/views/user_management/family_connect/FamilyConnectList'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'add',
            name: 'user-management.family-connect.add',
            component: () => import(/* webpackChunkName: "user-management.family-connect.add" */ '@/views/user_management/family_connect/FamilyConnectAdd'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'user-management.family-connect.edit',
            component: () => import(/* webpackChunkName: "user-management.family-connect.edit" */ '@/views/user_management/family_connect/FamilyConnectAdd'),
            meta: {
              withoutDivision: true
            }
          },
        ]
      },
      {
        path: 'labelers',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'user-management.labelersindex',
            component: () => import(/* webpackChunkName: "user-management.labelersindex" */ '@/views/user_management/labelers/Labelers'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: 'add',
            name: 'user-management.labelersadd',
            component: () => import(/* webpackChunkName: "user-management.labelersindex" */ '@/views/user_management/labelers/AddLabeler'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'user-management.labelersedit',
            component: () => import(/* webpackChunkName: "user-management.labelersedit" */ '@/views/user_management/labelers/AddLabeler'),
            meta: {
              withoutDivision: true
            }
          }
        ]
      },
    ]
  },
  {
    path: '/monitoring',
    component: proxyComponent,
    children: [
      {
        path: 'inbound-requests',
        name: 'monitoring.inbound-requests',
        component: () => import(/* webpackChunkName: "monitoring.inbound-requests" */ '@/views/monitoring/InboundRequests'),
      },
      {
        path: 'logs',
        name: 'monitoring.logs',
        component: () => import(/* webpackChunkName: "monitoring.logs" */ '@/views/monitoring/Logs'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'system-overview',
        name: 'monitoring.system-overview',
        component: () => import(/* webpackChunkName: "monitoring.system-overview" */ '@/views/monitoring/SystemOverview'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'devices-uptime',
        name: 'monitoring.devices-uptime',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/monitoring/DeviceUptime'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'adt-messages',
        name: 'monitoring.adt-messages',
        component: () => import(/* webpackChunkName: "adt-messages" */ '@/views/monitoring/AdtMessages'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: 'nurse-call',
        name: 'monitoring.nurse-call',
        component: () => import(/* webpackChunkName: "adt-messages" */ '@/views/monitoring/NurseCall'),
        meta: {
          withoutDivision: true
        }
      },
    ]
  },
  {
    path: '/system',
    component: proxyComponent,
    children: [
      {
        path: 'feature-flags',
        component: proxyComponent,
        children: [
          {
            path: '',
            name: 'system.feature-flags',
            component: () => import(/* webpackChunkName: "system.feature-flags" */ '@/views/user_management/feature_flags/FeatureFlags'),
            meta: {
              withoutDivision: true
            }
          },
          {
            path: ':id/edit',
            name: 'system.feature-flags.edit',
            component: () => import(/* webpackChunkName: "system.feature-flags.edit" */ '@/views/user_management/feature_flags/ManageFeatureFlags'),
            meta: {
              withoutDivision: true
            }
          },
        ]
      },
      {
        path: 'test-adt',
        name: 'system.test-adt',
        component: () => import(/* webpackChunkName: "system.test-adt" */ '@/views/system/TestAdt'),
      }
    ]
  },
  {
    path: '/patient-central/team-mode',
    name: 'patient-central.team-mode',
    component: () => import(/* webpackChunkName: "patient.central.team-mode" */ '@/views/CentralMonitoringTeam'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/patient-central/nursing-station/:viewId?',
    name: 'patient-central.nursing-station',
    component: () => import(/* webpackChunkName: "patient.central.nursing-station" */ '@/views/patient/SafetyCenter'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/patient-central/:viewId?',
    name: 'patient-central',
    component: () => import(/* webpackChunkName: "patient.central" */ '@/views/patient/SafetyCenter'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/form-builder',
    component: proxyComponent,
    children: [
      {
        path: '',
        name: 'form-builder',
        component: () => import(/* webpackChunkName: "form-builder" */ '@/views/form_builder/Forms'),
        meta: {
          withoutDivision: true
        },
      },
      {
        path: 'add',
        name: 'form-builder.add',
        component: () => import(/* webpackChunkName: "form-builder.add" */ '@/views/form_builder/AddForm.vue'),
        meta: {
          withoutDivision: true
        }
      },
      {
        path: ':id/edit',
        name: 'form-builder.edit',
        component: () => import(/* webpackChunkName: "form-builder.edit" */ '@/views/form_builder/AddForm.vue'),
        meta: {
          withoutDivision: true
        }
      },
    ]
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
  },
  {
    path: '/labeling',
    name: 'labeling.index',
    component: () => import(/* webpackChunkName: "labeling.index" */ '@/views/Labeling'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/delete-labeled-image',
    name: 'labeling.delete-image',
    component: () => import(/* webpackChunkName: "labeling.delete-image" */ '@/views/DeleteLabeledImage.vue'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/reporting',
    component: proxyComponent,
    children: [
      {
        path: 'data-export',
        name: 'reporting.data-export',
        component: () => import(/* webpackChunkName: "reporting.data-export" */ '@/views/DataExport'),
        meta: {
          withoutDivision: true
        },
      },
      {
        path: 'shift-report',
        name: 'reporting.shift-report',
        component: () => import(/* webpackChunkName: "reporting.shift-report" */ '@/views/ShiftReport'),
        meta: {
          withoutDivision: true
        }
      },
    ]
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/AllNotifications'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/live-video',
    name: 'live-video.external',
    component: () => import(/* webpackChunkName: "live-video.external" */ '@/views/LiveVideoExternal.vue'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/r/:id',
    component: () => import(/* webpackChunkName: "room-notification" */ '@/views/RoomNotification.vue'),
    name: 'room-notification',
    meta: {
      unsecured: true,
      layout: 'blank'
    }
  },
  {
    path: '/infinity-watch',
    name: 'infinity-watch',
    component: () => import(/* webpackChunkName: "infinity-watch" */ '@/views/InfinityWatch'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/manager-view',
    name: 'manager-view',
    component: () => import(/* webpackChunkName: "manager-view" */ '@/views/manager_view/ManagerView.vue'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/menu-management',
    name: 'menu-management',
    component: () => import(/* webpackChunkName: "menu-management" */ '@/views/MenuManagement'),
    meta: {
      withoutDivision: true
    }
  },
  {
    path: '/live-dashboard',
    name: 'live-dashboard',
    component: () => import(/* webpackChunkName: "live-dashboard" */ '@/views/LiveDashboard'),
    meta: {
      withoutDivision: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  stringifyQuery(obj) {
    const str = qs.stringify(obj)
    return str ? `${str}` : ''
  },
  parseQuery(str) {
    return qs.parse(str)
  }
})
router.beforeEach((to, from, next) => {
  to.meta.layout = to.meta.layout || 'default'
  next()
})
router.beforeEach(Auth0Guard)
router.beforeEach((to, from, next) => {
  if (from.path == '/' && from.name === undefined && to.path) {
    store.commit('setUrlAccessedDirectly', true)
  } else if (from.name && to.name) {
    store.commit('setUrlAccessedDirectly', false)
  }
  if (to.meta.unsecured) {
    next()
  } else {
    // two paths must be different to avoid reseting division picker state
    // when URL changes because of table filters, pager, etc
    if (from.path != to.path) {
      store.commit('setDivisionPickerState', to.meta.withoutDivision ? 'hidden' : 'visible')
    }
    const storeDivisionId = store.state.divisionId
    const isHomepageAfterAuth = to.query.code && to.query.state && to.path == '/'
    if (to.meta.withoutDivision || isHomepageAfterAuth) {
      next()
    } else {
      if (to.query.division_id) {
        next()
      } else {
        const route = {...to}
        let divisionId = storeDivisionId
        if (divisionId == '*' && to.meta.allDivisionsEnabled === false) {
          divisionId = getAvailableDivisionId({ userObject: store.state.currentUser })
        }
        route.query.division_id = divisionId
        route.replace = false
        route.force = true
        next(route)
      }
    }
  }
})

export default router
