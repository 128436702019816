/**
 * Filter Types
 */
const FilterType = Object.freeze({
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  ENUM: 'ENUM',
  DATERANGE: 'DATERANGE',
  DATETIMERANGE: 'DATETIMERANGE',
  DATE: 'DATE',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
})

/**
 * @typedef {Object} FilterData
 * @property {string} apiEndpoint - API endpoint to fetch data
 * @property {string} key - property to use as a key from apiEndpoint response
 * @property {string} value - property to use as a value from apiEndpoint response
 */



class TableFilter {
  /**
   * @property {string} name - name used as a key in objects and in URL
   * @property {string} displayName - name displayed in UI
   * @property {FilterType} type - filter type
   * @property {array} enumValues - Values for filer with type of ENUM
   * @property {boolean} isInline - inline or not
   * @property {string} apiEndpoint - API endpoint for autocomplete
   * @property {FilterData} data - API endpoint for autocomplete
   */
  constructor({ name, displayName, type, enumValues, isInline, apiEndpoint, data }) {
    this.name = name
    this.displayName = displayName
    this.isInline = isInline || false
    if (type) {
      if (Object.prototype.hasOwnProperty.call(FilterType, type)) {
        this.type = FilterType[type]
      } else {
        throw new Error('Wrong filter type')
      }
    }
    if (type === FilterType.ENUM) {
      this.enumValues = enumValues
    }
    if (type === FilterType.AUTOCOMPLETE) {
      this.apiEndpoint = apiEndpoint
    }
    this.data = data
  }
}


export {TableFilter, FilterType }
