<template>
  <div class="webrtc-view">
    <div class="webrtc-view__tracks">
      <div class="webrtc-view__not-connected" v-if="!isPatientCameraPlaying">
        <p class="webrtc-view__connecting-text" v-if="connecting">Connecting ...</p>
        <button v-if="cancelable || !connecting" @click="cancelable ? stopVideo() : startVideo()" :class="{'is-danger': cancelable}" class="button is-primary webrtc-view__connect-btn">{{ connectButtonText }}</button>
      </div>
      <!-- local audio must be muted or we get echo -->
      <video id="webrtc-view__local-view" autoplay playsinline muted="true"></video>
      <div class="webrtc-view__video-container">
        <div class="open-mic-warning" v-show="!muted">
          MIC IS OPEN
        </div>
        <video v-if="isTrainingVideo && isPatientCameraPlaying" ref="video" loop class="training-video" autoplay="autoplay" muted="muted" tabindex="-1" preload="auto" :src="meetingMetaData?.device.video_url"></video>
        <template v-else>
          <video id="webrtc-view__patient-view" autoplay playsinline v-show="isPatientCameraPlaying" ref="webrtcView"></video>
          <digital-ptz v-show="isPatientCameraPlaying && isExpanded && !meetingMetaData.is_ptz" :popup-mode="isExpanded" class="video-ptz" :element="$refs.webrtcView"></digital-ptz>
          <ptz v-show="isPatientCameraPlaying && isExpanded && meetingMetaData.is_ptz" :videoRoomManager="videoRoom" :popup-mode="isExpanded" class="video-ptz" :element="$refs.webrtcView"></ptz>
        </template>
        <div class="webrtc-view__video-controls" :class="{'webrtc-view__video-controls--expanded' : isExpanded}" v-show="isPatientCameraPlaying">
          <button @click="stopVideo" class="button webrtc-view__video-control">
            <i class="mdi mdi-phone-hangup" aria-hidden="true"></i>
          </button>
          <button v-if="!meetingMetaData.is_preview" @click="toggleMute" class="button webrtc-view__video-control">
            <i :class="['mdi', muted ? 'mdi-microphone-off' : 'mdi-microphone']" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div id="webrtc-view__other-participants" v-show="otherParticipants.length > 0">
        <video autoplay playsinline v-for="p in otherParticipants" :key="p.participantId" :id="p.participantId">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import to from "@/lib/to";
import { mapState, mapMutations } from "vuex";
import { markRaw } from "vue";
import DigitalPtz from "@/components/DigitalPtz";
import Ptz from "@/components/Ptz";
import VideoRoomManager from "@/lib/VideoRoomManager"
import {LOG_TYPES} from "@/lib/Log";

export default {
  name: "WebrtcView",
  components: {DigitalPtz, Ptz},
  props: {
    startAutomatically: {
      type: Boolean,
      required: false,
      default: false
    },
    isExpanded: {
      type: Boolean,
      required: false,
      default: false
    },
    videoSettings: {
      type: Object,
      required: false,
      default: () => {
        return {
          doorKnock: true,
          visitorName: '',
          activateVideo: false
        }
      }
    },
    meetingMetaData: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },
  methods: {
    connectParticipantMedia(participant) {
      // NOTE: this may be called multiple times for the same participant
      const isPatient = participant.isPatient;
      console.log('participant connected', participant);
      if (!isPatient) {
        const pIndex = this.otherParticipants.findIndex((p) => p.participantId === participant.participantId);
        if (pIndex === -1) {
            // need to add this to array first so that vue will allocate the div element
            this.otherParticipants.push(participant);
        } else {
            console.debug(`already added participant ${participant.participantId}`);
            // still need to continue processing in case the stream changed
        }
      }

      const container = isPatient ? document.getElementById('webrtc-view__patient-view') : document.getElementById('webrtc-view__other-participants');
      if (isPatient) {
        if (container.srcObject !== participant.mediaStream) {
            console.log(`updated patient stream ${participant.mediaStream.id}`);
            container.srcObject = participant.mediaStream;
        }
        this.connecting = false;
        this.setPatientCameraIsPlaying(true);
        if (this.meetingMetaData.test_mode && !this.meetingMetaData.is_preview) {
          this.testingTimer = setTimeout(() => {
            this.stopVideo()
          }, this.testVideoDuration)
        }
      } else {
        const pDiv = document.getElementById(participant.participantId);
        if (pDiv && pDiv.srcObject !== participant.mediaStream) {
          pDiv.srcObject = participant.mediaStream;
          console.log(`added other participant (${participant.participantId}) stream ${participant.mediaStream.id}`);
        }
      }
    },
    disconnectParticipant(participant) {
      console.log(`participant ${participant.participantId} tracks disconnected`, participant);
      if (!participant.isPatient) {
        // look for the remote participant
        const pIndex = this.otherParticipants.findIndex((p) => p.participantId === participant.participantId);
        console.log(`p index = ${pIndex}`);
        if (pIndex !== -1) {
          // remove from the array - vue will remove the control automatically. the tracks will be handled by the unsubscribe handler
          this.otherParticipants.splice(pIndex, 1);
          const pVid = document.getElementById(participant.participantId);
          if (pVid) {
            pVid.srcObject = null;
            console.debug("participant video element cleared");
          }
        } else {
          console.warn('unable to find participant');
        }
      } else {
        // lost the patient connection. disconnect from the room without closing it on the server
        // we leave open on the server so that there's an indication that there's a problem;
        // otherwise it would appear as if the user closed the room
        console.warn("Lost patient connection");
        this.stopVideo();
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Video connection lost. Please try to reconnect.'
        });
      }
    },
    async startVideo() {
      this.log(LOG_TYPES.LIVE_VIDEO.CONNECT_STARTED)
      if (this.isTrainingVideo) {
        this.log(LOG_TYPES.LIVE_VIDEO.CONNECT_SUCCEEDED)
        this.setPatientCameraIsPlaying(true)
        this.connecting = false
      } else {
        this.connecting = true
        this.connectionTimeout = setTimeout(() => {
          if (!this.isPatientCameraPlaying) {
            this.stopVideo()
            this.$buefy.dialog.alert("Can't connect to patient's camera")
            this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_CONNECT_FAILED)
          }
        }, 60000)


        let postData = {};
        let joinUrl = '/videos/join_meeting'

        if (this.meetingMetaData.test_mode) {
          postData = { device_id: this.meetingMetaData.device.id }
          joinUrl = '/videos/join_testing'
        } else {
          postData = {
            device_id: this.meetingMetaData.device.id,
            patient_id: this.meetingMetaData.patient.id,
            play_greeting: this.meetingMetaData.is_preview ? false : this.videoSettings.doorKnock,
            ptz: this.meetingMetaData.is_ptz,
            preview: this.meetingMetaData.is_preview
          }
        }

        const [err, response] = await to(this.http.post(joinUrl, postData, {
          handleErrors: true
        }))

        if (!err) {
          const { data: joinData } = response
          this.meetingId = joinData.meeting_id

          const room = new VideoRoomManager();
          this.videoRoom = markRaw(room);

          room.on('localMediaStreamConnected', (stream) => {
            const localElem = document.getElementById("webrtc-view__local-view");
            if (localElem.srcObject !== stream) {
              localElem.srcObject = stream;
              console.debug("Connected local media stream");
              this.videoRoom.muteLocalAudio();
              this.muted = true;
            }
          });

          room.on('signalRequired', this.sendSignal);

          room.on('participantTrackAdded', this.connectParticipantMedia);

          // listen for disconnections in order to cleanup
          room.on('participantDisconnected', this.disconnectParticipant);

          // listen for disconnected from room event
          room.on('disconnected', (error) => {
            // error is null/undefined when disconnected by us so ignore that case
            if (error != null) {
              console.warn(`room disconnected: ${error}`);

              let err_message = "You were disconnected from the video room. Please try again.";

              // clean up
              this.stopVideo(); // no wait needed

              this.$buefy.dialog.alert({
                title: 'Error',
                message: err_message,
              });


              this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_DISCONNECT_UNEXPECTED)
            } else {
              console.debug('ignoring disconnected event');
              this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_DISCONNECT_EXPECTED)
            }
          });

          room.on('webrtcNetworkScoreUpdated', (metrics) => {
            this.saveVideoMetrics('stats', metrics);
          });
          room.on('webrtcIssue', (issue) => {
            this.saveVideoMetrics('warning', issue);
          });
          room.on('connectSucceeded', () => {
            this.log(LOG_TYPES.LIVE_VIDEO.CONNECT_SUCCEEDED)
          })

          // console.warn(`AV: ${this.videoSettings.activateVideo}`, this.videoSettings);
          const statsInterval = this.$store.state.currentHospital.video_metrics_interval
          const roomType = this.meetingMetaData.test_mode ? 'test' : 'meeting'
          const camera = this.meetingMetaData.is_ptz ? 'ptz' : 'internal'
          await room.connect(joinData, roomType, camera, {
            audio: this.meetingMetaData.is_preview ? false : true,
            // limit video being sent b/c it is either displayed small or in kiosk mode where it's limited by CPU power on device
            video: this.videoSettings.activateVideo &&  !this.meetingMetaData.is_preview ? { width: 480, aspectRatio: 1.7777777778, frameRate: { min: 15 } } : false,
          }, statsInterval, this.videoSettings.visitorName);
          console.log('room', room);
        } else {
          console.error("Unable to open video room", err);
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Video session could not connect or was disconnected. Please check that monitoring has not been stopped and your network connection is functioning'
          });
          this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_CONNECT_FAILED)
          this.connecting = false
          clearTimeout(this.connectionTimeout)
        }
      }
    },
    async saveVideoMetrics(metricType, payload) {
      const deviceId = this.meetingMetaData.device?.id,
            divisionId = this.meetingMetaData.patient_monitor?.division_id || this.meetingMetaData.patient?.division_id
      if (!deviceId || !divisionId) {
        return;
      }

      const postData = payload.map(pl => {
        return {
          timestamp: pl.timestamp,
          metric_type: metricType,
          video_device_id: deviceId,
          division_id: divisionId,
          metrics: pl.metrics
        }
      })
      to(this.http.post('/videos/save_metrics', { video_device_metrics: postData }, {
        handleErrors: false,
        skipLoading: true,
        skipDivisions: true,
        skipInactivityCheck: true
      }))
    },
    stopVideo() {
      console.log("stopping video...");
      if (!this.isTrainingVideo) {
        clearTimeout(this.connectionTimeout);
        clearTimeout(this.testingTimer)
        // clean up
        if (this.videoRoom) {
          console.log("video room found. disconnecting...");
          // disconnect streams from video html elements so they can be closed properly
          document.getElementById("webrtc-view__local-view").srcObject = null;
          document.getElementById('webrtc-view__patient-view').srcObject = null;

          this.otherParticipants.forEach((p) => {
            const pVid = document.getElementById(p.participantId);
            if (pVid) {
              pVid.srcObject = null;
              console.debug(`participant ${p.participantId} video element cleared`);
            }
          });

          // make sure we disconnect from the room after closing tracks and cleaning up elements
          this.videoRoom.disconnect();
          console.log('disconnected from video')
          this.videoRoom = null;
        }
        this.otherParticipants.splice(0, this.otherParticipants.length);
        if (this.meetingId) {
          to(this.$http.put(`/meetings/${this.meetingId}/leave`))
        }
      }
      this.connecting = false;
      this.setPatientCameraIsPlaying(false)
      this.muted = false;
      this.$emit('close')
    },
    async sendSignal(signalPayload) {
      signalPayload.patient_monitor_id = this.meetingMetaData.patient_monitor?.id;
      signalPayload.video_device_id = this.meetingMetaData.device.id;
      signalPayload.meeting_id = this.meetingId
      signalPayload.ptz = this.meetingMetaData.is_ptz
      console.debug("sending signal", signalPayload);
      const [err] = await to(this.http.post('/videos/signal', signalPayload));
      if (err) {
        console.warn("error sending signal", err);
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Video session could not connect or was disconnected. Please check that monitoring has not been stopped and your network connection is functioning'
        });
        this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_CONNECT_FAILED)
        this.stopVideo()
      }
    },
    toggleMute() {
      // toggle the local audio
      if (this.isTrainingVideo) {
        this.$refs.video.muted = this.muted
        this.muted = !this.muted
      } else {
        if (this.muted) {
          // this.videoRoom.localParticipant.audioTracks.forEach((pub) => pub.track.enable());
          this.videoRoom.unmuteLocalAudio();
          this.muted = false;
        } else {
          // this.videoRoom.localParticipant.audioTracks.forEach((pub) => pub.track.disable());
          this.videoRoom.muteLocalAudio();
          this.muted = true;
        }
      }
    },
    ...mapMutations('LiveVideoStore', ['setPatientCameraIsPlaying']),
    log(type) {
      if (!this.meetingMetaData.test_mode) {
        const videoType = this.meetingMetaData.is_preview ? 'meeting preview' : 'meeting'
        this.$logger.logLiveVideo(type, videoType, this.meetingMetaData?.patient?.id, this.meetingMetaData?.patient_monitor?.id, this.meetingId)
      }
    }
  },
  computed: {
    ...mapState('LiveVideoStore', ['isPatientCameraPlaying', 'requestVideoStart']),
    connectButtonText() {
      return this.cancelable ? "Cancel" : "Video Visit"
    },
    cancelable() {
      return this.connecting && this.videoRoom
    },
    isTrainingVideo() {
      return this.meetingMetaData?.device?.kind == 'trainingvideo'
    }
  },
  mounted() {
    if (this.startAutomatically) {
      this.startVideo()
    }
  },
  created() {
    // add a window listener to make sure we disconnect from the room if the user navigates away or closes the window
    window.addEventListener('beforeunload', this.stopVideo);
  },
  beforeUnmount() {
    // in the event the window wasn't unloaded (user just navigated somewhere else causing this component to be destroyed), need to
    // stop video explicitly.
    this.stopVideo(); // it's ok if this is called multiple times
    this.log(LOG_TYPES.LIVE_VIDEO.VIDEO_DISCONNECT_EXPECTED)
    // clean up the window listener.
    window.removeEventListener('beforeunload', this.stopVideo)
  },
  watch: {
    requestVideoStart: {
      handler(newValue) {
        if (newValue) {
          this.startVideo()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      connectionTimeout: null,
      testingTimer: null,
      testVideoDuration: 5 * 60 * 1000,
      connecting: false,
      /**
       * @type {?VideoRoomManager}
       */
      videoRoom: null,
      otherParticipants: [],
      muted: false,
      meetingId: null
    }
  }
}
</script>

<style scoped lang="scss">
  .webrtc-view__tracks {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .webrtc-view__not-connected {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/blurry_hospital.jpg");
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin-top: 10px;
    }
  }
  #webrtc-view__other-participants {
    position: absolute;
    bottom: 3px;
    height: 80px;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: inline-flex;
    overflow-x: auto;
    padding: 0 20px;
    justify-content: center;
  }
  :deep {
    #webrtc-view__other-participants video {
      height: 80px;
    }
    #webrtc-view__other-participants > div {
      margin-right: 20px;
    }
    #webrtc-view__patient-view {
      width: 100%;
    }
  }
  #webrtc-view__local-view {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 160px;
    z-index: 1;
    video {
      box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.48)
    }
  }
  .webrtc-view__video-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .webrtc-view__video-control {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
    margin: 0 10px;
    background-color: transparent;
    color: #fff !important;
    border: solid 1px #fff !important;
    opacity: 0;
    transition: all 0.1s linear;
    box-shadow: none !important;
    i {
      padding-top: 5px;
    }
  }
  .webrtc-view__video-control:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #4a4a4a;
  }
  .webrtc-view__connecting-text {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .webrtc-view__video-container {
    position: relative;
    overflow: hidden;
    &:hover .webrtc-view__video-control {
      opacity: 1;
    }
  }
  .video-ptz {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 999999;
  }
  .training-video {
    width: 100%;
  }
  .open-mic-warning {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 18;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #fff;
    padding: 3px;
    background: red;
  }
</style>
