<template>
  <div class="notifications">
    <div class="notifications__head">
      <span class="notifications__title">notifications</span>
      <div class="notifications__reload" @click="reloadData">
        <i class="mdi mdi-reload"></i>
      </div>
    </div>
    <div class="notifications__content">
      <div class="notifications__tabs">
        <b-tabs v-model="listMode" @update:modelValue="applyFilter">
          <b-tab-item value="active" label="Active"></b-tab-item>
          <b-tab-item value="archived" label="Archived"></b-tab-item>
        </b-tabs>
      </div>
      <div class="notifications__list">
        <div class="notifications__loading-wrapper" v-if="isLoading">
          <b-loading :model-value="true" :is-full-page="false"></b-loading>
        </div>
        <template v-else>
          <template v-if="filteredNotifications.length">
            <router-link class="notifications__item"
                         :to="{ name: 'patient-monitors.monitoring', params: { id: notification.related_to.patient_monitor_id }, query: { division_id: notification.division_id } }"
                         :key="index" v-for="(notification, index) in filteredNotifications" @click="$emit('close')">
              <div class="notifications__item-body">
                {{ notification.message }}
              </div>
              <div class="notifications__item-bottom">
                <span :title="$helpers.formatDate(notification.created_at)" :key="reloadKey">{{ DateTime.fromISO(notification.created_at).toRelative() }}</span>
              </div>
            </router-link>
          </template>
          <div v-else class="notifications__empty">No {{ listMode }} notifications</div>
        </template>
      </div>
    </div>
    <div class="notifications__bottom-bar">
      <router-link :to="{ name: 'notifications' }" @click="$emit('close')">View All</router-link>
    </div>
  </div>
</template>

<script>
import to from "@/lib/to";
import { DateTime } from "luxon";

export default {
  name: "LdNotification",
  methods: {
    async reloadData() {
      this.isLoading = true
      const [err, response] = await to(this.$http.get('/notifications', {
        skipLoading: true
      }))
      if (!err) {
        this.notifications = response.data
        this.isLoading = false
        this.applyFilter(this.listMode)
        this.emitPendingNotifications()
      }
    },
    emitPendingNotifications() {
      this.$emit('count-changed', this.notifications.filter(x => !x.archived).length)
    },
    applyFilter(currentTab) {
      this.filteredNotifications = this.notifications.filter(x =>  x.archived == (currentTab == 'archived'))
    }
  },
  async mounted() {
    this.reloadData()
    const channelName = `private-encrypted-ld-${process.env.VUE_APP_ENVIRONMENT_NAME}-user-${this.$store.state.currentUser.id}`
    this.pusherUserChannel = this.$pusher.subscribe(channelName)
    this.pusherUserChannel.bind('group-notification', this.reloadData);
    setInterval(() => {
      this.reloadKey++
    }, 1000 * 60)
  },
  beforeUnmount() {
    this.pusherUserChannel?.unbind('group-notification', this.reloadData);
  },
  data() {
    return {
      notifications: [],
      filteredNotifications: [],
      DateTime,
      isLoading: false,
      reloadKey: 0,
      listMode: 'active',
      pusherUserChannel: null
    }
  }
}
</script>

<style scoped lang="scss">
  .notifications__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    line-height: 1;
  }
  .notifications__head {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 8px;
    background: #5048FF;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: #ffffff;
  }
  .notifications__reload {
    border: none;
    background: none;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
  }
  .notifications__list {
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
  }
  .notifications__item {
    padding: 10px;
    transition: background-color 0.1s;
    cursor: pointer;
    display: block;
    color: $main-text-color;
    &:hover {
      background-color: #f2f0fe;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #f2f0fe;
    }
  }
  .notifications__item-body {
    font-size: 13px;
    line-height: 17px;
    padding-bottom: 5px;
  }
  .notifications__item-bottom {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    color: #5764a7;
    display: flex;
    align-items: center;
    line-height: 1;
    span:not(:last-child, :first-child) {
      padding: 0 5px;
    }
  }
  .notifications__loading-wrapper {
    position: relative;
    height: 90px;
  }
  .notifications__empty {
    padding: 20px;
    text-align: center;
    font-size: 14px;
  }
  .notifications__tabs {
    :deep(.tab-content) {
      display: none;
    }
    :deep(.tabs a) {
      font-size: 14px;
    }
  }
  .notifications__content {
    border: 2px solid #5047fe;
  }
  .notifications__bottom-bar {
    background: #5048FF;
    padding: 4px 10px;
    a {
      color: #fff;
      font-size: 13px;
      letter-spacing: 1px;
    }
  }
</style>
