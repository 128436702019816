export default {
  namespaced: true,
  state: {
    connectedMonitors: {},
    localMicState: {
      enabled: true,
      monitorId: null
    },
    patientMicState: {
      enabled: true,
      monitorId: null
    }
  },
  mutations: {
    setLocalMicState(state, micState) {
      state.localMicState = micState
    },
    setPatientMicState(state, micState) {
      state.patientMicState = micState
    },
    setConnectedMonitors(state, { monitorId, isConnected }) {
      state.connectedMonitors[monitorId] = isConnected
    }
  },
  actions: {
    // empty action used as an event with subscribeAction
    hangup() {}
  }
}
