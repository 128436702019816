<template>
  <div>
    <Form v-slot="formProps" ref="form" as="div">
      <slot v-bind="formProps"></slot>
    </Form>
  </div>
</template>

<script>
import { Form } from 'vee-validate';

export default {
  name: "ValidationObserver",
  components: { Form }
}
</script>
