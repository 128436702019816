<template>
  <div class="ptz" v-if="element">
    <div class="ptz__buttons" :class="{'ptz__buttons--disabled': scaleVal == 1}">
      <div>
        <button class="ptz__button ptz__button--up" @mousedown="move('up')" @mouseup="cancelMovement">
          <i class="mdi mdi-menu-up"></i>
        </button>
      </div>
      <div>
        <button class="ptz__button ptz__button--left" @mousedown="move('left')" @mouseup="cancelMovement">
          <i class="mdi mdi-menu-left"></i>
        </button>
        <button class="ptz__button ptz__button--reset" @click="reset">
          <i class="mdi mdi-reload"></i>
        </button>
        <button class="ptz__button ptz__button--right" @mousedown="move('right')" @mouseup="cancelMovement">
          <i class="mdi mdi-menu-right"></i>
        </button>
      </div>
      <div>
        <button class="ptz__button ptz__button--down" @mousedown="move('down')" @mouseup="cancelMovement">
          <i class="mdi mdi-menu-down"></i>
        </button>
      </div>
    </div>
    <div class="ptz__scale">
      <div class="ptz__scale-body">
        <div class="ptz__scale-level" :class="{'ptz__scale-level--selected': scaleVal == 1}">
          <button @click="scale(1)">100%</button>
        </div>
        <div class="ptz__scale-level ptz__scale-level--separator"><span>•</span></div>
        <div class="ptz__scale-level" :class="{'ptz__scale-level--selected': scaleVal == 1.5}">
          <button @click="scale(1.5)">150%</button>
        </div>
        <div class="ptz__scale-level ptz__scale-level--separator"><span>•</span></div>
        <div class="ptz__scale-level" :class="{'ptz__scale-level--selected': scaleVal == 2}">
          <button @click="scale(2)">200%</button>
        </div>
        <div class="ptz__scale-level ptz__scale-level--separator"><span>•</span></div>
        <div class="ptz__scale-level" :class="{'ptz__scale-level--selected': scaleVal == 4}">
          <button @click="scale(4)">400%</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shortcut, { SHIFT } from "@/lib/Shortcut";
import {EventBus} from "@/lib/EventBus";

export default {
  name: "DigitalPtz",
  props: ['element', 'popupMode', 'widgetModel'],
  emits: ['scaleUpdated'],
  mixins: [
    shortcut(event => ['ArrowDown', 'ArrowLeft', 'ArrowUp', 'ArrowRight'].includes(event.key), function(e) {
      if (this.popupMode) {
        this.move(e.key.replace('Arrow', '').toLowerCase())
      }
    }),
    shortcut('+', SHIFT, function() {
      this.changeScale('increase')
    }),
    shortcut(event => ['-', '='].includes(event.key), function(e) {
      this.changeScale(e.key == '=' ? 'increase' : 'decrease')
    })
  ],
  methods: {
    move(d) {
      if (this.scaleVal == 1) {
        return;
      }
      clearTimeout(this.continiousMovementDelay)
      const _move = (direction) => {
        let newX = this.x, newY = this.y;
        if (direction == 'left') {
          newX += 20
        }
        if (direction == 'right') {
          newX -= 20
        }
        if (direction == 'up') {
          newY += 20
        }
        if (direction == 'down') {
          newY -= 20
        }

        let parentWidth = this.element.parentElement.offsetWidth
        let parentHeight = this.element.parentElement.offsetHeight
        let maxX = Math.abs((parentWidth - (this.element.offsetWidth  * this.scaleVal)) / 2)
        let maxY = Math.abs((parentHeight - (this.element.offsetHeight  * this.scaleVal)) / 2)

        this.x = Math.abs(newX) >= maxX ? maxX * Math.sign(newX) : newX
        this.y = Math.abs(newY) >= maxY ? maxY * Math.sign(newY) : newY
        this.buildTransform()
      }
      _move(d)
      this.continiousMovementDelay = setTimeout(() => {
        this.movementTimer = setInterval(() => {
          _move(d)
        }, 50)
      }, 500)
    },
    cancelMovement() {
      clearTimeout(this.continiousMovementDelay)
      clearInterval(this.movementTimer)
    },
    scale(val) {
      this.x = 0;
      this.y = 0;
      this.scaleVal = val
      this.buildTransform()
      this.$emit('scaleUpdated', val)
    },
    changeScale(type) {
      if (!this.popupMode) {
        return;
      }
      if (type == 'increase') {
        if (this.scaleLevels.length - 1 == this.scaleShortcutIndex) {
          return;
        } else {
          this.scale(this.scaleLevels[++this.scaleShortcutIndex])
        }
      } else if (type == 'decrease') {
        if (this.scaleShortcutIndex == 0) {
          return;
        } else {
          this.scale(this.scaleLevels[--this.scaleShortcutIndex])
        }
      }
    },
    buildTransform() {
      // eslint-disable-next-line vue/no-mutating-props
      this.element.style.transition = 'all 0.1s linear'
      // eslint-disable-next-line vue/no-mutating-props
      this.element.style.transform =`translateX(${this.x}px) translateY(${this.y}px) scale(${this.scaleVal})`
    },
    reset() {
      this.x = 0;
      this.y = 0;
      this.scaleVal = 1
      this.buildTransform()
      this.$emit('scaleUpdated', this.scaleVal)
    },
    blockSizeChanged({ widgetId, size }) {
      if (this.widgetModel.id == widgetId && size == 1) {
        this.reset()
      }
    }
  },
  created() {
    this.$watch('element', (newVal) => {
      if (newVal) {
        this.parentWidth = this.element.parentElement.offsetWidth
        this.parentHeight = this.element.parentElement.offsetHeight
      }
    });
    this.$watch('popupMode', (newVal) => {
      if (!newVal) {
        this.reset()
      }
    });
    document.addEventListener('keyup', this.cancelMovement)
    EventBus.$on('block-size-changed', this.blockSizeChanged)
  },
  unmounted() {
    EventBus.$off('block-size-changed', this.blockSizeChanged)
    document.removeEventListener('keyup', this.cancelMovement)
  },
  data() {
    return {
      scaleShortcutIndex: 0,
      scaleLevels: [1, 1.5, 2, 4],
      continiousMovementDelay: null,
      parentHeight: null,
      parentWidth: null,
      movementTimer: null,
      x: 0,
      y: 0,
      scaleVal: 1,
    }
  }
}
</script>

<style scoped lang="scss">
  .ptz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ptz__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.ptz__buttons--disabled {
      opacity: 0.3;
    }
  }
  .ptz__buttons > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ptz__buttons:not(.ptz__buttons--disabled) {
    .ptz__button {
      cursor: pointer;
      &:hover {
        background: #ffffffc7;
      }
    }
  }
  .ptz__button {
    width: 40px;
    height: 40px;
    border: none;
    background: #ffffff7a;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    i {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ptz__button--reset {
    font-size: 26px;
    padding-top: 4px;
  }
  .ptz__button--left {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .ptz__button--right {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .ptz__button--up {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .ptz__button--down {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .ptz__scale {
    margin-left: 20px;
  }
  .ptz__scale-body {
    width: 40px;
    height: 100%;
    background: #ffffff7a;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    overflow: hidden;
  }
  .ptz__scale-level {
    width: 100%;
    button {
      width: 100%;
      border: none;
      background: none;
      padding: 0;
      height: 30px;
      transition: all 0.1s linear;
      outline: none;
    }
    &:not(.ptz__scale-level--separator) button {
      &:hover {
        background: #ffffff7a;
      }
      cursor: pointer;
    }
  }
  .ptz__scale-level--separator {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      height: auto;
    }
  }
  .ptz__scale-level--selected {
    button {
      background: #ffffff7a;
    }
  }
</style>
