<template>
  <div class="secondary">
    <div class="value">
      <div class="division-color" :style="{ backgroundColor: item.division_color }"></div>
      <div>{{ item.division_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDivisionLine",
  props: ['item']
}
</script>

<style scoped>
  .division-color {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
  }
  .value {
    display: flex;
    align-items: center;
  }
</style>
