<template>
  <div class="search-bar" :class="searchClass">
    <div>
      <b-input v-model="query" ref="searchField" class="autocomplete" icon="magnify"
               @click="searchFocused" @input="search" @keydown.esc="hideResults" placeholder="Search">
      </b-input>
      <div class="division-switch" v-if="['readonly', 'visible'].includes($store.state.divisionPickerState) && $store.state.currentUser.divisions.length > 1">
        <b-checkbox-button v-model="checkboxModel" native-value="true" type="is-success" size="is-small" @update:modelValue="search">
          <span>Current Hospital</span>
        </b-checkbox-button>
      </div>
    </div>
    <div class="search-results" ref="searchResult">
      <b-loading :model-value="loading" :is-full-page="false"></b-loading>
      <div class="search-results__empty" v-if="requestError">
        {{ requestError.response.data.error }}
      </div>
      <ul v-else-if="numberOfResults > 0 && query.length > 0">
        <template v-for="(group, groupIndex) in data">
          <li :key="groupIndex" v-if="group.items.length > 0">
            <div class="group-header">
              <span class="group-header__title">{{ group.groupName }}</span>
              <div class="group-header__total">{{ group.items.length }} / {{ group.total }}</div>
            </div>
            <ul>
              <li v-for="(item, itemIndex) in group.items" :key="groupIndex + itemIndex"
                  :id="`result-${'' + groupIndex + itemIndex}`" class="search-result__item"
                  :class="{'focused': selectedElement.id == `result-${'' + groupIndex + itemIndex}`}">
                <template v-if="group.groupId == 'patients'">
                  <router-link class="search-results__patient" :to="{ name: $can('Edit', 'Patient') ? 'patients.overview.edit' : 'patients.view', params: { id: item.id }, query: { division_id: item.division_id } }">
                    <div class="is-flex is-flex-direction-column">
                      <span>{{ $helpers.mask('patient_name', item.display_name) }} {{ item.room_name ? `(${$helpers.mask('room_name', item.room_name)})` : '' }}</span>
                      <div class="secondary">
                        <div class="name">MRN:</div>
                        <div class="value">{{ $helpers.mask('mrn', item.mrn) }}</div>
                      </div>
                      <div class="secondary">
                        <div class="name">DOB:</div>
                        <div class="value">{{ item.dob ? $helpers.mask('dob', $helpers.formatDate(item.dob, 'L', true)) : 'N/A' }}</div>
                      </div>
                      <search-division-line :item="item"></search-division-line>
                    </div>
                    <div>
                      <div class="tag is-small" :class="item.status == 'active' ? 'is-success' : 'is-danger'">
                        {{ item.status }}
                      </div>
                    </div>
                  </router-link>
                </template>
                <template v-if="group.groupId == 'rooms'">
                  <router-link :to="{ name: 'hospital.rooms.edit', params: { id: item.id }, query: { division_id: item.division_id } }">
                    {{ item.name }}
                    <search-division-line :item="item"></search-division-line>
                  </router-link>
                </template>
                <template v-if="group.groupId == 'monitoring_sessions'">
                  <router-link :to="{ name: 'patient-monitors.monitoring', params: { id: item.id }, query: { division_id: item.division_id } }" class="search-results__patient-monitor">
                    <div>
                      <span>{{ $helpers.mask('patient_name', item.display_name) }}</span>
                      <div class="secondary">
                        <div class="name">MRN:</div>
                        <div class="value">{{ $helpers.mask('mrn', item.mrn) }}</div>
                      </div>
                      <search-division-line :item="item"></search-division-line>
                    </div>
                    <div class="search-results__patient-monitor-status">
                      <div class="tag is-small" :class="$helpers.styleForMonitoringStatus(item.status)">{{ item.status }}</div>
                      <span class="search-results__patient-monitor_time">{{ getMonitorStatus(item) }}</span>
                    </div>
                  </router-link>
                </template>
                <template v-if="group.groupId == 'units'">
                  <router-link :to="{ name: $can('Edit', 'Unit') ? 'hospital.units.edit' : 'hospital.units.view', params: { id: item.id }, query: { division_id: item.division_id } }">
                    {{ item.name }}
                    <search-division-line :item="item"></search-division-line>
                  </router-link>
                </template>
                <template v-if="group.groupId == 'groups'">
                  <router-link :to="{ name: $can('Edit', 'Group') ? 'user-management.groups.edit' : 'user-management.groups.view', params: { id: item.id } }">
                    {{ item.name }}
                  </router-link>
                </template>
                <template v-if="group.groupId == 'video_devices'">
                  <router-link :to="{ name: $can('Edit', 'VideoDevice') ? 'hospital.video_devices.edit' : 'hospital.video_devices.view', params: { id: item.id }, query: { division_id: item.division_id } }">
                    {{ item.name }}
                    <search-division-line :item="item"></search-division-line>
                  </router-link>
                </template>
              </li>
            </ul>
          </li>
        </template>
      </ul>
      <div v-else class="search-results__empty">
        <template v-if="loading">
          <div class="py-5"></div>
          <b-loading :model-value="true" :is-full-page="false"></b-loading>
        </template>
        <template v-else>
          <div v-if="query.length">Couldn't find anything</div>
          <div v-else>Type to start search</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import to from "@/lib/to";
  import shortcut from "@/lib/Shortcut"
  import { debounce } from "@/lib/helpers";
  import SearchDivisionLine from "@/components/search/SearchDivisionLine.vue";

  export default {
    name: "SearchBar",
    components: {SearchDivisionLine},
    mixins: [shortcut(event => ['/', 'Escape'].includes(event.key), function(e) {
      setTimeout(() => {
        const el = this.$refs.searchField.$el.getElementsByTagName('input')[0]
        if (e.key == '/') {
          el.focus()
          this.searchFocused()
        } else {
          el.blur()
          this.hideResults()
        }
      }, 0)
    })],
    data() {
      return {
        data: [],
        name: '',
        selected: null,
        searchClass: [],
        focusedIndex: -1,
        selectedElement: {},
        query: '',
        requestError: null,
        loading: false,
        checkboxModel: []
      }
    },
    watch: {
      focusedIndex(newValue) {
        const allElements = this.$refs.searchResult.querySelectorAll('.search-result__item')
        if (newValue !== null && allElements.length > 0 && this.focusedIndex >= 0) {
          this.selectedElement = allElements[this.focusedIndex]
          this.selectedElement.scrollIntoView({
            block: 'nearest'
          })
        }
      },
    },
    methods: {
      search: debounce(async function() {
        const str = this.query
        this.selectedElement = {}
        this.focusedIndex = -1;
        this.requestError = null
        if (str.length > 0) {
          this.loading = true
          const divisionId = this.isSearchInCurrentDivision ? this.$store.state.divisionId : '*'
          const [err, response] = await to(this.http.get(`/search?q=${str}`, {
            handleErrors: true,
            skipLoading: true,
            params: { division_id: divisionId }
          }));
          this.loading = false
          if (err) {
            this.requestError = err
            this.data = []
          } else {
            this.data = response.data
          }
        }
      }, 300),
      searchFocused() {
        this.searchClass = ['search--focused']
        document.addEventListener('click', this.clickListener)
        document.querySelectorAll('.search-bar input')[0].addEventListener('keydown', this.handleKeyDown)
      },
      hideResults() {
        this.searchClass = []
        document.querySelectorAll('.search-bar input')[0].removeEventListener('keydown', this.handleKeyDown)
      },
      clickListener(e) {
        const linkClicked = [...document.getElementsByClassName('search-result__item')].some(el => {
          return el.contains(e.target)
        })
        if (!document.getElementsByClassName('search-bar')[0].contains(e.target) || linkClicked) {
          this.searchClass = []
          document.removeEventListener('click', this.clickListener)
        }
      },
      handleKeyDown(e) {
        const DOWN = 40, UP = 38, ENTER = 13;
        let keyCode = e.keyCode;
        if (keyCode == DOWN) {
          if (this.focusedIndex != this.numberOfResults - 1) {
            this.focusedIndex++
          } else {
            this.focusedIndex = 0
          }
        } else if (keyCode == UP) {
          if (this.focusedIndex != 0) {
            this.focusedIndex--
          } else {
            this.focusedIndex = this.numberOfResults - 1
          }
        }

        if (keyCode == ENTER) {
          if (this.selectedElement.id) {
            const anchorEl = this.selectedElement.getElementsByTagName('a')[0]
            if (e.ctrlKey || e.metaKey) {
              window.open(anchorEl.href, '_blank', )
            } else {
              anchorEl.click()
            }
          }
          this.$refs.searchField.$el.getElementsByTagName('input')[0].blur()
        }
      },
      getMonitorStatus(monitor) {
        let text = 'Since ',
            date = null;

        switch (monitor.status) {
          case 'active':
            date = monitor.monitor_started_at
            break;
          case 'stopped':
            date = monitor.monitor_ended_at
            break;
          case 'paused':
            date = monitor.updated_at
            break;
          case 'created':
          case 'pending':
            date = monitor.updated_at
            break;
        }
        text += this.$helpers.formatDate(date)
        return text
      },
      aboveLimit(group) {
        return group.total > 10
      },
      isLast(index, items) {
        return index + 1 == items.length
      }
    },
    computed: {
      isSearchInCurrentDivision() {
        return this.checkboxModel.length > 0
      },
      numberOfResults() {
        return this.data.reduce((acc, obj) => {
          return acc + obj.items.length
        }, 0)
      },
      renderedElement() {
        return this.$refs.searchResult.querySelectorAll('a')
      }
    }
  }
</script>

<style scoped lang="scss">
  .search-results {
    position: absolute;
    opacity: 0;
    transition: all 0.1s linear;
    background: #ffffff;
    width: 100%;
    z-index: 999;
    border-radius: 0 0 17px 17px;
    padding: 0 0 10px 0;
    border-top: 1px solid #e3e7ea;
    box-shadow: 0px 3px 7px -4px #23337b;
    max-height: 0;
    overflow-y: scroll;
    > ul {
      font-size: 14px;
    }
    > ul > li > ul > li {
      font-size: 15px;
      line-height: 18px;
      margin: 2px 0 0;
      transition: all 0.1s linear;
      cursor: pointer;
      &.focused, &:hover {
        background: #e3f2fd;
      }
      a {
        padding: 7px 15px;
        color: $main-text-color;
        width: 100%;
        display: inline-block;
        overflow-wrap: anywhere;
      }
      .search-results__patient-monitor {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .search-results__patient-monitor-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tag {
          width: 60px;
          margin-right: 5px;
        }
      }
      .search-results__patient-monitor_time {
        font-size: 12px;
        width: 180px;
      }
    }
  }
  .group-header {
    background: #5048FF;
    padding: 5px 10px;
    display: flex;
    width: 100%;
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.6px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .group-header__total {
    margin-left: 5px;
    background: #3731ae;
    text-align: center;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 11px;
    letter-spacing: 1px;
  }
  .search-bar.search--focused .search-results {
    max-height: 400px;
    opacity: 1;
  }
  .search-bar {
    position: relative;
    margin: 0 auto;
    :deep(input) {
      box-shadow: none;
      outline: none !important;
      border-radius: 17px;
      /*background: #f5f6fc;*/
      /*border: 1px solid #eaebf7;*/
      border: none;
      transition: all 0.1s linear;
      box-shadow: 0px 0px 0px 1px #f5f6fc;
      &:focus {
        box-shadow: 0px 1px 29px 11px #f5f6fc;
      }
    }
  }
  :deep {
    input.autocomplete {
      padding-right: 128px;
    }
    .autocomplete .icon {
      left: 4px !important;
      top: 2px;
    }
    input {
      background: $background-level-0 !important;
      transition: all 0.1s linear !important;
    }
    .dropdown-content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid rgba(10, 10, 10, 0.1);
      border-top: none;
      box-shadow: none;
    }
    .dropdown-menu {
      margin-top: -4px;
    }
  }
  .search--focused :deep(input) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-results__empty {
    width: 100%;
    padding: 10px 10px 0 10px;
    text-align: center;
    font-size: 15px;
  }
  :deep(.secondary) {
    font-size: 13px;
    display: flex;
    opacity: 0.8;
    .name {
      font-weight: 500;
      margin-right: 5px;
    }
  }
  .search-results__patient {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .division-switch {
    position: absolute;
    right: 4px;
    top: 4px;
    :deep {
      .b-checkbox.button {
        border-radius: 16px;
        font-size: 12px;
      }
    }
  }
  .see-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px !important;
    font-size: 13px;
    color: #5048ff !important;
    font-weight: 500;
    border-top: 1px solid #dfe1e8;
    a.see-all__choise {
      border-radius: 6px;
      width: auto !important;
      padding: 2px 7px !important;
      margin: 0 5px;
      font-size: 12px;
      border: 1px solid #5048ff;
      transition: all 0.1s linear;
      &:hover {
        background: #5048ff;
        color: #FFFFFF;
      }
    }
  }
</style>
