<template>
  <div class="layout">
    <div class="layout__header">
      <header-bar></header-bar>
    </div>
    <div class="layout__content">
      <sidebar></sidebar>
      <div class="content-sidebar"></div>
      <div class="layout__container-wrapper">
        <div class="container">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Sidebar from "@/components/layout/Sidebar";
  import HeaderBar from "@/components/layout/HeaderBar";
  export default {
    name: "Default",
    components: {
      HeaderBar,
      Sidebar
    },
    data() {
      return {
        reduce: false
      }
    }
  }
</script>

<style scoped lang="scss">
  .layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
  }


  .main-container {
    flex: 1 1;
    overflow-y: auto;
  }
  .layout__content {
    flex: 1 1;
    display: flex;
    min-height: 1px;
  }
  .layout__container-wrapper {
    min-height: 1px;
    overflow-y: auto;
    flex: 1 1;
    padding: 0 43px 50px 43px;
    @include mobile {
      padding: 0 15px;
    }
  }
  .container {
    padding: 40px 0;
  }
  @media screen and (min-width: 1408px) {
    .container {
      max-width: 1344px;
    }
  }
  @media screen and (min-width: 1600px) {
    .container {
      max-width: 1536px;
    }
  }
  @media screen and (min-width: 1700px) {
    .container {
      max-width: 80%;
    }
    .app.page-patient-central,
    .app.page-patient-central-nursing-station,
    .app.page-infinity-watch,
    .app.page-patient-central-team-mode,
    .app.page-labeling-index,
    .app.page-manager-view {
      .container {
        max-width: 100% !important;
      }
    }
  }
</style>
