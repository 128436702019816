<template>
    <div class="ptz" v-if="element">
      <div class="ptz__buttons">
        <div>
          <button class="ptz__button ptz__button--up" @mousedown="startPanTilt('up')" @mouseup="stopPanTilt">
            <i class="mdi mdi-menu-up"></i>
          </button>
        </div>
        <div>
          <button class="ptz__button ptz__button--left" @mousedown="startPanTilt('left')" @mouseup="stopPanTilt">
            <i class="mdi mdi-menu-left"></i>
          </button>
          <button class="ptz__button ptz__button--reset" @click="reset">
            <i class="mdi mdi-reload"></i>
          </button>
          <button class="ptz__button ptz__button--right" @mousedown="startPanTilt('right')" @mouseup="stopPanTilt">
            <i class="mdi mdi-menu-right"></i>
          </button>
        </div>
        <div>
          <button class="ptz__button ptz__button--down" @mousedown="startPanTilt('down')" @mouseup="stopPanTilt">
            <i class="mdi mdi-menu-down"></i>
          </button>
        </div>
      </div>
      <div class="ptz__buttons ptz__zoom">
        <div>
            <button class="ptz__button ptz__button--zoom-out" @mousedown="startZoom('out')" @mouseup="stopZoom">
                <i class="mdi mdi-magnify-minus"></i>
            </button>
        </div>
        <div class="ptz__button ptz__button--separator"><span>•</span></div>
        <div>
        <button class="ptz__button ptz__button--zoom-in" @mousedown="startZoom('in')" @mouseup="stopZoom">
            <i class="mdi mdi-magnify-plus"></i>
        </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Ptz",
    props: ['element', 'popupMode', 'videoRoomManager'],
    methods: {
      startPanTilt(direction) {
        // TODO: there is a problem where if you accidentally drag while pushing the button, the cancel movement isn't called
        console.debug(`start ptz move ${direction}`)
        this.videoRoomManager.sendDeviceDataChannelMessage({
            type: 'ptz',
            action: 'start_pan_tilt',
            direction,
        })
      },
      stopPanTilt() {
        console.log("cancel movement")
        this.videoRoomManager?.sendDeviceDataChannelMessage({
            type: 'ptz',
            action: 'stop_pan_tilt'
        })
      },
      startZoom(direction) {
        this.videoRoomManager?.sendDeviceDataChannelMessage({
            type: 'ptz',
            action: 'start_zoom',
            direction
        })
        console.debug(`started zoom {direction}`);
      },
      stopZoom() {
        this.videoRoomManager?.sendDeviceDataChannelMessage({
            type: 'ptz',
            action: 'stop_zoom'
        })
        console.debug("stopped zoom")
      },
      reset() {
        this.scaleVal = 0
        this.ptzReset()
      },
      ptzReset() {
        this.videoRoomManager?.sendDeviceDataChannelMessage({
            type: 'ptz',
            action: 'reset'
        })
        console.debug("issued ptz reset")
      }
    },
    created() {
      this.$watch('element', (newVal) => {
        if (newVal) {
          this.parentWidth = this.element.parentElement.offsetWidth
          this.parentHeight = this.element.parentElement.offsetHeight
        }
      });
      this.$watch('popupMode', (newVal) => {
        if (!newVal) {
          this.reset()
        }
      });
    },
    data() {
      return {
        parentHeight: null,
        parentWidth: null
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .ptz {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ptz__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.ptz__buttons--disabled {
        opacity: 0.3;
      }
    }
    .ptz__buttons > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ptz__buttons:not(.ptz__buttons--disabled) {
      .ptz__button {
        cursor: pointer;
        &:hover {
          background: #ffffffc7;
        }
      }
    }
    .ptz__button--separator {
        cursor: default !important;
    }
    .ptz__button {
      width: 40px;
      height: 40px;
      border: none;
      background: #ffffff7a;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.1s linear;
      i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ptz__button--reset {
      font-size: 26px;
      padding-top: 4px;
    }
    .ptz__button--left {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    .ptz__button--right {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
    .ptz__button--up {
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
    }
    .ptz__button--down {
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
    .ptz__button--zoom-out {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .ptz__button--zoom-in {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .ptz__zoom {
      margin-left: 20px;
    }

    .ptz__button--separator {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: auto;
      }
      &:hover {
        background: #ffffff7a !important;
      }
    }
  </style>
  