import {createApp} from "vue";

let app = null
export default {
  create(options) {
    if (this.shared) {
      return this.shared
    }
    app = createApp(options)
    return app
  },
  get shared() {
    return app
  }
}
