<template>
  <div>
    <b-table-column v-for="(column, index) in columns" :key="index" v-bind="column.attrs" :sticky="isSticky(column)" :cell-class="getCellClass(column)" v-slot="{ row }">
      <template v-if="column.attrs.isCustomField && column.attrs.customFieldKind == 'select'">
        <div class="custom-field--select background-color" :class="`background-color--${choiceColor(column, row)}`">
          {{ row.custom_fields[column.attrs.customFieldKey] || 'N/A' }}
        </div>
      </template>
      <template v-else-if="column.attrs.isCustomField && column.attrs.customFieldKind == 'checkbox'">
        {{ row.custom_fields[column.attrs.customFieldKey]?.join(', ') || 'N/A' }}
      </template>
      <template v-else-if="column.attrs.isCustomField && column.attrs.customFieldKind == 'datetime'">
        {{ row.custom_fields[column.attrs.customFieldKey] ? $helpers.formatDate(row.custom_fields[column.attrs.customFieldKey]) : 'N/A' }}
      </template>
      <template v-else-if="column.formatter">
        {{ column.formatter(row) }}
      </template>
      <slot v-else-if="column.usesSlot" :name="slotNameFor(column)" :row="row"></slot>
      <p v-else>N/A</p>
    </b-table-column>
  </div>
</template>

<script>
export default {
  name: "DynamicTableColumns",
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    columnSettings: {
      type: Object,
      default: () => { return {} }
    },
    customFieldsModel: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    choiceColor(column, row) {
      if (!column.attrs?.customFieldOptions?.choiceColors) {
        return ''
      }
      return column.attrs.customFieldOptions?.choiceColors[row.custom_fields[column.attrs.customFieldKey]]
    },
    slotNameFor(column) {
      let name = 'field-'
      if (column.attrs.slotName) {
        name += column.attrs.slotName
      } else {
        name += column.attrs.field
      }
      return name
    },
    isSticky(column) {
      return !!this.columnSettings[column.attrs.field]?.is_sticky
    },
    isWrapped(column) {
      return !!this.columnSettings[column.attrs.field]?.is_wrapped
    },
    getCellClass(column) {
      const cssClasses = []
      if (column.attrs['cell-class']) {
        cssClasses.push(column.attrs['cell-class'])
      }
      if (this.isWrapped(column)) {
        cssClasses.push('wrap')
      }
      return cssClasses.join(' ')
    }
  }
}
</script>

<style scoped>
  .custom-field--select {
    display: inline-flex;
    padding: 3px 6px;
    border-radius: 4px;
  }
</style>
