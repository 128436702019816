<template>
  <template v-if="menu instanceof MenuSet">
    <li class="menu-set has-children" :id="`menu-set-${menu.id}`" v-if="menu.enabled" :data-expanded-default="menu.isExpanded">
      <VDropdown container=".sidebar-content" popper-class="ld-popper menu-set__popper" placement="right" :triggers="['hover']" :popper-triggers="['hover']" >
        <a href="#" class="section-divider__link"><i class="mdi" :class="menu.icon"></i><span>{{ menu.title }}</span></a>
        <template #popper>
          <div class="menu-set__popper-content">{{ menu.title }}</div>
        </template>
      </VDropdown>
      <template v-if="menu.children && menu.children.length">
        <ul class="menu-set__children">
          <menu-item v-for="(menuItem, index) in menu.children" :key="index" :menu="menuItem" :class="{'menu-set__direct-link': !menuItem.isGroup}"></menu-item>
        </ul>
      </template>
    </li>
  </template>
  <menu-item v-else-if="menu instanceof MenuItemClass" :menu="menu" class="menu-set__direct-link"></menu-item>
</template>

<script>
import MenuItem from "@/components/menu/MenuItem.vue";
import MenuSet from "@/lib/menu/MenuSet";
import MenuItemClass from "@/lib/menu/MenuItem";
export default {
  name: "MenuSet",
  computed: {
    MenuSet() {
      return MenuSet
    },
    MenuItemClass() {
      return MenuItemClass
    }
  },
  components: {MenuItem},
  props: ['menu']
}
</script>

<style scoped lang="scss">
:deep(span.b-tooltip) {
  width: 100%;
}
.menu-set__children {
  display: none;
}
.menu-set.expanded > .menu-set__children {
  display: block;
}
:deep {
  .has-children > ul {
    display: none;
  }
  .has-children.expanded > ul {
    display: block;
  }
}
.menu-set {
  width: 100%;
  margin: 5px 0;
}
.menu-set__popper-content {
  white-space: nowrap;
}
.section-divider__link {
  color: #FFFFFF;
  display: block;
  width: calc(100% + 30px);
  margin: 0 -15px;
  padding: 10px 28px 9px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2.4px;
  font-size: 12px;
  /* background: #3933c1; */
  margin-bottom: 5px;
  background: linear-gradient(90deg, #4971ff, transparent);
  i {
    display: none;
    font-size: 20px;
  }
}
</style>