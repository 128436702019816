export default {
  namespaced: true,
  state: {
    divisionsVisible: true,
    userNotificationsVisible: false,
    profileVisible: true,
    searchVisible: true,
    headerVisible: true,
    renderSidebar: true,
  },
  mutations: {
    setDivisonsVisible(state, value) {
      state.divisionsVisible = value
    },
    setUserNotificationsVisible(state, value) {
      state.userNotificationsVisible = value
    },
    setProfileVisible(state, value) {
      state.profileVisible = value
    },
    setSearchVisible(state, value) {
      state.searchVisible = value
    },
    setHeaderVisible(state, value) {
      state.headerVisible = value
    },
    setRenderSidebar(state, value) {
      state.renderSidebar = value
    }
  },
  actions: {
    externalLiveVideoMode(context, { enabled, mode }) {
      if (mode == 1) {
        context.commit('setDivisonsVisible', !enabled)
        context.commit('setUserNotificationsVisible', !enabled)
        context.commit('setSearchVisible', !enabled)
        context.commit('setSearchVisible', !enabled)
      } else if (mode == 2) {
        context.commit('setHeaderVisible', !enabled)
      }
      context.commit('setRenderSidebar', !enabled)
    }
  }
}
