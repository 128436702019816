<template>
  <component :is="withoutValidation ? 'div' : 'Form'" as="div" ref="form" v-slot="formProps" :validate-on-mount="validateOnMount">
    <!--
      @slot Header slot, used to render title of the page or anything else
    -->
    <div class="header" v-if="$slots.header">
      <slot name="header" v-bind="formProps || {}"></slot>
    </div>
    <div class="form-wrapper">
      <div class="form-box" :class="{ box: !this.withoutContentWrapper }">
        <!--
          @slot Default slot to render inputs
        -->
        <slot v-bind="formProps || {}"></slot>
      </div>
      <div v-if="$slots.sidebar">
        <slot name="sidebar"></slot>
      </div>
    </div>
    <!--
      @slot Footer slot - just like header, but at the bottom
    -->
    <slot name="footer" v-bind="formProps || {}"></slot>
  </component>
</template>

<script>
/**
 * This component is used to wrap insert and update forms for basic CRUD operations
 * It will make it easier to apply changes in all forms later when we'll need
 * @displayName Form Wrapper
 */
import { Form } from 'vee-validate';
export default {
  name: "LdForm",
  props: {
    withoutContentWrapper: { type: Boolean, default: false },
    withoutValidation: { type: Boolean, default: false },
    title: { type: String, default: '' },
    validateOnMount: { type: Boolean, default: false }
  },
  components: { Form },
}
</script>
<style scoped>
.header {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;
}
.form-wrapper {
  display: flex;
}
.form-box {
  flex: 1;
  margin-bottom: 0;
}
</style>
