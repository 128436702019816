import MenuItem from "@/lib/menu/MenuItem";

/**
 * @class MenuSet
 * @extends MenuItem
 */
class MenuSet extends MenuItem {
  isRoot = true
  isExpanded = false
  constructor(id, title, icon, expanded = false) {
    super(id, title, null, true, icon)
    this.isExpanded = expanded
  }
}
export default MenuSet