<template>
  <div>
    <table-user-settings v-if="tableId" :has-default-filters="Object.keys(defaultFilters).length > 0" :table-id="tableId"
                         v-model:default-filter="defaultSavedFilter" :should-load-default="shouldLoadDefaultSetting"
                         :order="orderBy" :per-page="+perPage" :default-per-page="PER_PAGE_NUM" :current-filters="cleanActiveFilters" @setting-changed="tableViewChanged"
                         :default-columns="columnsWithCustomFields" v-model:selected-columns="userSelectedColumns" @column-settings="settings => this.columnSettings = settings">
    </table-user-settings>
    <table-filters ref="tableFilters" v-if="filterable" :filter-settings="filterSettings" :available-filters="filtersWithCustomFields" :url-filters="activeFilters" @filters-changed="filtersChanged" @filters-cleared="clearFilter"></table-filters>
    <div v-if="$slots['after-filters']" class="after-filters">
      <slot name="after-filters"></slot>
    </div>
    <table-column-resizer :columns="columnsToDisplay">
      <b-table scrollable :key="tableReloadKey" ref="table" mobile-cards="mobile-cards" v-bind="{...$attrs, ...dynamicAttrs}" :default-sort="[orderBy.field, orderBy.direction]" v-on="{...dynamicEvents}">
        <template #empty>
          <p>No Data</p>
        </template>
        <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        <template #default>
          <dynamic-table-columns :custom-fields-model="customFieldsModel" :columns="columnsToDisplay" :column-settings="columnSettings">
            <template v-for="(_, slot) of fieldSlots" #[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
          </dynamic-table-columns>
          <slot name="static-columns" :key="reloadKey"></slot>
        </template>

        <template #bottom-left>
          <input-with-validation v-if="backendPaginable" class="per-page" b-name="select" field-label="Per Page" v-model="perPage" @update:modelValue="perPageChange">
            <option :key="x" v-for="x in [10, 25, 50, 100]">{{ x }}</option>
          </input-with-validation>
          <input-with-validation v-if="backendPaginable" class="go-to-page" field-label="Go To Page" b-name="input"
                                 v-model="goToPageVal" icon-right="keyboard-return"
                                 icon-right-clickable @icon-right-click="goToPage" @keyup.enter="goToPage">
          </input-with-validation>
        </template>
      </b-table>
    </table-column-resizer>
  </div>
</template>

<script>
  import TableFilters from "@/components/TableFilters";
  import InputWithValidation from "@/components/validation/InputWithValidation.vue";
  import TableUserSettings from "@/components/table/TableUserSettings.vue";
  import DynamicTableColumns from "@/components/table/DynamicTableColumns";
  import TableColumnResizer from "@/components/table/TableColumnResizer";
  import TableColumn from "@/models/TableColumn";
  import customFieldsFilterGenerator from "@/lib/CustomFieldsFilterGenerator";

  const PER_PAGE_NUM = 10
  export default {
    name: "BTableWrapper",
    components: {TableColumnResizer, DynamicTableColumns, TableUserSettings, InputWithValidation, TableFilters},
    props: {
      /**
       * Inner table indicates that table is being used as an additional component of the page.
       * meaning that, on that page there could be other tables too.
       * If this is the only component on page (such as monitor or patient list) we will update URL with given filters
       * and sort parameters
       */
      isInnerTable: {
        type: Boolean,
        default: false
      },
      backendPaginable: {
        type: Boolean,
        default: false
      },
      backendSortable: {
        type: Boolean,
        default: false
      },
      metadata: {
        type: Object
      },
      filterable: {
        type: Boolean,
        default: false
      },
      filters: {
        type: Array,
        default: () => []
      },
      // Using ld prefix to not interfere with buefy's attr
      ldDefaultSort: {
        type: Object,
        default: () => { return {} }
      },
      defaultFilters: {
        type: Object,
        default: () => { return {} }
      },
      /**
       * forceDefaultFilters - no matter URL params, we always apply default
       * alwaysAppendDefaultFilters - default filters will always be appended to last version of filters
       * deletableFilters - FALSE to prevent deleting filters
       * hiddenClearFiltersButton
       * hiddenNewFilterButton
       */
      filterSettings: {
        type: Object,
        default: () => { return {} }
      },
      tableId: {
        type: String,
        default: null
      },
      columns: {
        type: Array,
        default: () => []
      },
      customFieldsModel: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      dynamicAttrs() {
        let attrs = {}
        if (this.backendPaginable) {
          attrs['paginated'] = true
          attrs['backend-pagination'] = true
          attrs['current-page'] = this.currentPage
          attrs['total'] = this.metadata.count
          attrs['per-page'] = this.metadata.per_page
        }
        if (this.backendSortable) {
          attrs['backend-sorting'] = true
        }
        return attrs
      },
      dynamicEvents() {
        let events = {}
        if (this.backendPaginable) {
          events['page-change'] = this.pageChanged
        }
        if (this.backendSortable) {
          events['sort'] = this.sort
        }
        return events
      },
      shouldLoadDefaultSetting() {
        if (!this.hasReferer && !this.hasFiltersFromUrl) {
          return true
        }
        return this.hasReferer && !this.hasFiltersFromUrl
      },
      hasReferer() {
        return !this.$store.state.urlAccessedDirectly
      },
      cleanActiveFilters() {
        return this.filterQueryStringObject(this.activeFilters)
      },
      fieldSlots() {
        const slots = {}
        for (const [name, func] of Object.entries(this.$slots)) {
          if (name.startsWith('field-')) {
            slots[name] = func
          }
        }
        return slots
      },
      columnsWithCustomFields() {
        const newColumns = []
        if (this.columns.length && this.customFieldsModel) {
          for (const [field, { label, options, kind }] of Object.entries(this.customFields)) {
            newColumns.push(
              new TableColumn(
                {
                  sortable: true, field: `custom_fields->'${field}'`, label,
                  isChecked: false, isCustomField: true,
                  customFieldKind: kind, customFieldOptions: options,
                  customFieldKey: field
                },
                row => row.custom_fields[field] || 'N/A'
              )
            )
          }
        }
        return this.columns.filter(c => c.attrs.enabled).concat(newColumns)
      },
      filtersWithCustomFields() {
        if (this.customFieldsModel) {
          return this.filters.concat(customFieldsFilterGenerator(this.customFields))
        }
        return this.filters
      },
      customFields() {
        if (this.customFieldsModel) {
          return this.$store.getters["CustomFieldsStore/getFieldsFor"](this.customFieldsModel)
        }
        return {}
      }
    },
    data() {
      return {
        PER_PAGE_NUM,
        shouldUpdateUrl: true,
        activeFilters: {},
        currentPage: 1,
        orderBy: {},
        firstLoad: true,
        perPage: 10,
        goToPageVal: 1,
        hasFiltersFromUrl: false,
        currentSavedFilterId: null,
        defaultSavedFilter: undefined,
        userSelectedColumns: [],
        columnsToDisplay: [],
        reloadKey: Math.random(),
        tableReloadKey: Math.random(),
        columnSettings: {}
      }
    },
    watch: {
      '$route': {
        async handler(to, from) {
          if (to.name == from?.name) {
            const keys = Object.keys(to.query)
            if (keys.length == 0 || keys.length == 1 && keys.includes('division_id')) {
              this.clearFilter()
            }
            this.prepareFilters()
            /**
             * If division is changed, we need to reload dynamic filters. i.e filters whose data is loaded from backend
             * and data depends on division. for example available units in units filter should be different for each division
             *
             * reloadDynamicFilters() find all dynamic filters and load their data. returning filter itself and server response
             * for all dynamic filters.
             *
             * for each dynamic filter, we check if URL has values that are not in the response data (new division). if so, we remove
             * those values from activeFilters and  set needsToApplyChanges to true
             * so at the end of the loop we can apply changes which will change URL accordingly
             */

            const fromDivisionId = from?.query?.division_id
            const toDivisionId   = to?.query?.division_id
            if (fromDivisionId && toDivisionId && +toDivisionId != +fromDivisionId) {
              let needsToApplyChanges = false
              const dynamicFilterPromise = await this.$refs.tableFilters.reloadDynamicFilters()
              if (Array.isArray(dynamicFilterPromise)) {
                dynamicFilterPromise.forEach(promiseResult => {
                  const filterUrlValues = [...to?.query?.[promiseResult.filter.name] || []]
                  if (Array.isArray(filterUrlValues)) {
                    filterUrlValues.forEach((urlValue, index) => {
                      if (!promiseResult.response.data.map(x => x[promiseResult.filter.data.key]).includes(+urlValue)) {
                        const indexToDelete = this.activeFilters[promiseResult.filter.name].findIndex(x => x == urlValue)
                        if (indexToDelete > -1) {
                          this.activeFilters[promiseResult.filter.name].splice(indexToDelete, 1)
                        }
                        filterUrlValues[index] = null
                        needsToApplyChanges = true
                      }
                    })
                  }
                })

                if (needsToApplyChanges) {
                  this.applyChanges({ force: false, replace: true })
                  return
                }
              }
            }
            if (this.filterSettings.forceDefaultFilters) {
              this.applyChanges({ force: false })
            }

            this.emitLoadData()
          }
        },
        immediate: true
      },
      userSelectedColumns: {
        handler(newVal) {
          if (Array.isArray(newVal) && newVal.length > 0) {
            this.columnsToDisplay = this.columnsWithCustomFields.filter(c => newVal.includes(c.attrs.field)).sort((a, b) => newVal.indexOf(a.attrs.field) - newVal.indexOf(b.attrs.field))
          } else {
            this.columnsToDisplay = this.columnsWithCustomFields
          }
          /**
           * Reload slot (#static-columns) after assigning dynamic columns to make sure
           * that static columns are always rendered after dynamic
           */
          this.$nextTick(() => {
            this.reloadKey = Math.random()
          })
        },
        immediate: true,
        deep: true
      },
      tableReloadKey: {
        handler() {
          this.$nextTick(() => {
            this.applyTableClass()
          })
        },
        immediate: true
      },
      columnSettings: {
        handler(newSettings) {
          if (Object.keys(newSettings).length > 0) {
            this.tableReloadKey = Math.random()
          }
        },
        deep: true,
      }
    },
    methods: {
      applyTableClass() {
        if (this.$refs.table) {
          // in vue 3 $attrs now include class and style. so it's not applied to root node automatically
          this.$refs.table.$el.classList.add(this.isInnerTable ? 'is-inner-table' : 'is-list-table')
        }
      },
      emitLoadData() {
        if (this.$refs.table) {
          // using buefy's interal properties to reset current sorting columns between url changes
          // when sorting happens without direct interaction on the sort button (ex browser back/forward button)
          this.$refs.table.currentSortColumn = this.$refs.table.visibleColumns.find(x => x.field == this.orderBy.field)
          this.$refs.table.isAsc = this.orderBy.direction == 'asc'
        }

        let filters = {
          order_by: { field: this.orderBy.field, direction: this.orderBy.direction },
          ...this.activeFilters,
          per_page: this.perPage
        }
        if (this.filterSettings.alwaysAppendDefaultFilters) {
          filters = {...filters, ...this.defaultFilters}
        }
        this.$emit('load-data', { page: this.currentPage, filters })
      },
      clearFilter() {
        this.currentSavedFilterId = null
        this.currentPage = 1
        this.orderBy = {}
        this.activeFilters = []
        this.$emit('filters-changed')
        this.applyChanges()
        this.$refs.table.resetMultiSorting()
      },
      filtersChanged(filters) {
        this.currentPage = 1
        this.activeFilters = filters
        this.$emit('filters-changed')
        this.applyChanges()
      },
      pageChanged(page) {
        this.currentPage = page
        this.applyChanges()
      },
      sort(field, direction, applyChanges = true) {
        this.orderBy = {field, direction}
        this.currentPage = 1
        if (applyChanges) {
          this.applyChanges()
        }
      },
      /**
       * If URL changes, loading data handled by $route watcher.
       * If we don't update URL (for inner tables. patient monitor -> add) we need to
       * explicitly call load method
       */
      applyChanges({ force, replace } = { force: true, replace: false }) {
        if (this.shouldUpdateUrl) {
          const { menu_id, item_id, filter_id } = this.$route.query
          const routeObj = {
            name: this.$route.name,
            replace: this.firstLoad || replace,
            force,
            query: {
              page: this.currentPage,
              ...this.activeFilters,
              order_by: {
                field: this.orderBy.field,
                direction: this.orderBy.direction
              },
              per_page: this.perPage,
            }
          }
          if (menu_id) {
            routeObj.query.menu_id = menu_id
          }
          if (item_id) {
            routeObj.query.item_id = item_id
          }
          if (this.tableId) {
            routeObj.query.filter_id = this.firstLoad && filter_id ? filter_id : this.currentSavedFilterId
          }
          if (!this.$route.meta.withoutDivision) {
            routeObj.query.division_id = this.$route.query.division_id
          }
          this.$router.push(routeObj)
          this.firstLoad = false
        } else {
          this.emitLoadData()
        }
      },
      prepareFilters() {
        this.orderBy = { field: this.ldDefaultSort.field, direction: this.ldDefaultSort.direction }

        // if it's inner table we ignore query params
        const q = this.isInnerTable ? {} : { ...this.$route.query }

        if (Object.prototype.hasOwnProperty.call(q, 'page')) {
          this.currentPage = +q['page']
          delete q['page']
        }
        if (Object.prototype.hasOwnProperty.call(q, 'order_by')) {
          this.orderBy = { direction: q.order_by.direction, field: q.order_by.field }
          delete q.order_by
        }
        if (Object.prototype.hasOwnProperty.call(q, 'per_page')) {
          this.perPage = q.per_page
          delete q.per_page
        }

        this.activeFilters = q
        // this.hasFiltersFromUrl = Object.keys(q).some(filterKey => {
        //   return this.filters.find(filter => filter.name == filterKey)
        // })

        // apply default filters only when URL doesn't have one
        const filtersOnlyQuery = {...this.$route.query}
        delete filtersOnlyQuery.division_id
        if (Object.keys(filtersOnlyQuery).length == 0 || this.filterSettings.forceDefaultFilters) {
          if (Object.keys(this.defaultFilters).length) {
            for (const [filterName, filterValue] of Object.entries(this.defaultFilters)) {
              if (!Object.prototype.hasOwnProperty.call(this.activeFilters, filterName)) {
                this.activeFilters[filterName] = filterValue
              }
            }
          }
          if (Object.keys(this.ldDefaultSort).length) {
            const currentlyHasOrder = Object.keys(this.orderBy).length > 0,
                hasFilters = Object.keys(this.activeFilters).length > 0;
            if (!currentlyHasOrder || (currentlyHasOrder && !hasFilters)) {
              this.sort(this.ldDefaultSort.field, this.ldDefaultSort.direction, false)
            }
          }
        }
      },
      perPageChange() {
        this.currentPage = 1
        this.applyChanges()
      },
      goToPage() {
        const totalPages = Math.ceil(this.metadata.count / (+this.perPage))
        const newPage = +this.goToPageVal
        if (newPage >= 1 && newPage <= totalPages) {
          this.currentPage = newPage
          this.applyChanges()
        }
      },
      tableViewChanged(view) {
        const filters = view?.metadata?.filters || this.defaultFilters,
              perPage = view?.metadata?.per_page || PER_PAGE_NUM,
              order   = view?.metadata?.order || this.ldDefaultSort;
        this.currentSavedFilterId = view?.id
        this.perPage = perPage
        if (order && Object.keys(order).length > 0) {
          this.sort(order.field, order.direction, false)
        }
        this.filtersChanged(filters)
      },
      setup() {
        this.applyChanges()
        /*// if there is no referer (i.e arrived directly on this URL via browser bookmark, link, direct typing)
        // we need to explicitly load data. Otherwise, it will be handled by $route watcher
        if (!this.hasReferer) {
          this.emitLoadData()
        }*/
      },
      filterQueryStringObject(qs) {
        const o = {}
        if (qs) {
          Object.keys(qs).forEach(filterKey => {
            if (this.filtersWithCustomFields.find(filter => filter.name == filterKey)) {
              o[filterKey] = qs[filterKey]
            }
          })
        }
        return o
      }
    },
    async created() {
      this.shouldUpdateUrl = !this.isInnerTable
      if (this.filtersWithCustomFields) {
        this.hasFiltersFromUrl = Object.keys(this.$route.query).some(filterKey => {
          return this.filtersWithCustomFields.find(filter => filter.name == filterKey)
        })
      }
      const filterId = this.$route.query.filter_id
      if (filterId) {
        this.currentSavedFilterId = filterId
      }
      this.prepareFilters()
      if (this.tableId) {
        if (!this.hasReferer && this.hasFiltersFromUrl) {
          this.setup()
        } else {
          this.$watch('defaultSavedFilter', (newVal) => {
            if (this.shouldLoadDefaultSetting) {
              this.tableViewChanged(newVal)
            } else {
              this.setup()
            }
          })
        }
      } else {
        this.setup()
      }
    },
    mounted() {
      this.applyTableClass()
    },
    beforeUnmount() {
      // eslint-disable-next-line vue/no-deprecated-events-api
      this.$off('reload-data')
    }
  }
</script>

<style lang="scss" scoped>

  .b-table.is-inner-table {
    overflow-x: hidden;
    :deep(.table) {
      .th-wrap {
        @extend %inner-header-label;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
      }
      thead th {
        border-width: 0 0 1px;
        border-color: #EBEEF5;
        vertical-align: bottom;
        position: relative;
      }
      td {
        font-size: 14px;
        padding: 17px 12px 12px;
        border-color: #EBEEF5;
        color: $main-text-color;
        vertical-align: middle;
        .action-button {
          margin-left: 10px;
        }
        button:not(.custom-button) {
          width: 26px;
          height: 26px;
          border-radius: 13px;
          padding: 0;
          font-size: 15px;
          margin-top: -3px;
        }
      }
    }
  }
  .b-table.is-list-table,
  .b-table.is-inner-table {
    :deep(.table) {
      padding-bottom: 20px;
      thead tr th {
        white-space: nowrap;
      }
      tbody tr td {
        //max-width: 1px;
        white-space: nowrap;
        &.wrap {
          overflow-wrap: anywhere !important;
          white-space: break-spaces;
        }
      }
      .is-sortable {
        span.icon {
          width: 10px;
          height: 12px;
          font-size: 14px;
        }
        &:not(.is-current-sort) {
          span.icon {
            visibility: visible !important;
            transform: translateY(50%) !important;
            i:before {
              content: "\F0E79";
            }
          }
        }
      }
    }
  }
  .b-table.is-list-table {
    :deep(.table) {
      border: none;
      background: transparent;
      min-width: 100% !important;
      th {
        font-size: 12px;
        background: $background-level-0;
        font-weight: 500;
        border: none;
        color: $secondary-text-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 1em 2.05em 1em 0.75em;
        position: relative;
      }

      td {
        background: #fff;
      }
      tr.detail {
        box-shadow: none;
      }

      tbody {
        tr {
          height: 60px;
          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          @include mobile {
            &:not(:last-child) {
              border-bottom: 1px solid $tetriary-text-color;
            }
            padding-bottom: 20px;
            margin-bottom: 30px;
            &:not(.detail):not(.is-empty):not(.table-footer) td:before {
              font-weight: 500;
            }
          }
        }
      }

      tbody tr td {
        color: $main-text-color;
        font-size: 14px;
        border-color: $background-level-0;
        box-shadow: inset 0px -10px 0px 0px $background-level-0;
        padding-top: 15px;
        padding-bottom: 20px;
        border-bottom-width: 0;
        vertical-align: middle;
        line-height: 17px;
        .button:not(.custom-button) {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          font-size: 17px;
        }

        &.is-sticky {
          //border-radius: 0 !important;
          //box-shadow: 1px 5px 4px 0px #e2e5eb !important;
        }
        @include mobile {
          box-shadow: none;
          padding-top: 20px;
          padding-bottom: 15px;
          &:first-child {
            border-radius: 10px 10px 0 0;
          }
          &:last-child {
            border-radius: 0 0 10px 10px;
          }
        }
      }
      .detail-container {
        ul {
          display: inline-flex;
          align-items: center;
          li {
            margin: 10px 20px 10px 0;
          }
          flex-wrap: wrap;
        }
      }
    }
  }

  .b-table :deep(.pagination-list) {
    list-style-type: none;
    margin: 0;
    li {
      margin-top: 0;
    }
  }
  .b-table :deep(.pagination-link.pagination-previous) {
    order: 1
  }

  .per-page, .go-to-page {
    margin-top: -20px;
    margin-bottom: 0 !important;
    margin-right: 10px;
    :deep {
      select, input {
        width: 90px;
        background: #fff;
      }
    }
  }
  :deep {
    .table-wrapper {
      margin-bottom: 30px;
    }
  }
  .after-filters {
    margin: -20px 0 30px 0;
  }
  :deep(.table-sticky-action) {
    right: 0;
  }
</style>
