<template>
  <div>
    <b-field class="has-addons">
      <b-radio-button :key="index" v-for="(filter, index) in filter.enumValues" v-model="radioButton" :native-value="filter.key" type="is-primary" @change="emitModel">
        <span>{{ filter.value }}</span>
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "TableFilterRadioGroup",
  props: ['filter', 'modelValue'],
  watch: {
    modelValue: {
      handler(value) {
        this.radioButton = value
      },
      immediate: true
    }
  },
  methods: {
    emitModel() {
      this.$emit('update:modelValue', this.radioButton)
    }
  },
  data() {
    return {
      radioButton: ''
    }
  }
}
</script>

<style scoped>
.field {

}
</style>
