import moment from "moment-timezone";
import { mask } from "@/lib/Mask";
import pluralize from "pluralize";

let instance = null
let timeZone = null
const _helpers = function() {
  var $this = this.config.globalProperties;
  return {
    passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{10,}$/,
    passwordValidationMessage: 'Password must be at least 10 characters long and include uppercase letter, lowercase letter, digit and special character.',
    redirectWithMessage: ({ to, messageText, messageType, duration }) => {
      $this.$router.push(to)
      $this.$buefy.toast.open({
        message: messageText,
        type: messageType || 'is-success',
        duration: duration || 4000
      })
    },
    getDate({ date, withoutTimezone = false} = {}) {
      let m = moment(date)
      if (!withoutTimezone && timeZone) {
        m = m.tz(timeZone)
      }
      return m
    },
    formatDate(date, format = 'lll', withoutTimezone = false) {
      return this.getDate({ date, withoutTimezone }).format(format)
    },
    getAge(date) {
      return moment().diff(date, 'years')
    },
    /**
     * Capitalize first char
     * @param {string} str
     * @returns {string}
     */
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    /**
     * Returns css class to be identify specific monitoring status.
     * @param {string} status
     * @return {string}
     */
    styleForMonitoringStatus(status) {
      const classes = {
        'stopped': 'is-danger',
        'pending': 'is-info',
        'active': 'is-success',
        'paused': 'is-warning'
      }
      return classes[status] || ''
    },
    debounce,
    downloadBlob(blob, name) {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    },
    /**
     * Audited generated revisions array includes full object as a first element.
     * Every other object records only changes from previous one.
     * This functions goes through the provided array and applies changes on top of each other
     * as a result, instead of individual changes, we get full object state for each revision.
     *
     * @param {array} revisions Audited gem's generated revisions array
     * @returns {*[]}
     */
    modifyRevisionsInPlace(revisions) {
      const modifiedRevisions = []
      for (let i = 0; i < revisions.length; i++) {
        let currentRev = revisions[i]
        if (currentRev.action == 'create') {
          modifiedRevisions.push({...currentRev.audited_changes, username: currentRev.username, edited_at: currentRev.created_at })
        } else {
          const newRevision = {...modifiedRevisions[i - 1]}
          for (const [field, values] of Object.entries(currentRev.audited_changes)) {
            newRevision[field] = values[1]
          }
          newRevision.username = currentRev.username
          newRevision.edited_at = currentRev.created_at
          modifiedRevisions.push(newRevision)
        }
      }
      // last element becomes most updated state which we probably already display in UI
      modifiedRevisions.pop()
      return modifiedRevisions.reverse() // reversing array to have eary updates at the top
    },
    sanitizeInputForRegExp(input) {
      if (!input) {
        return
      }

      return String(input).replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
    },
    mask,
    pluralize
  }
}

const getHelpers = function(vuePrototype) {
  if (instance == null) {
    instance = _helpers.bind(vuePrototype)()
  }
  return instance
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export default {
  install: function(app, { store }) {
    store.watch(state => {
      return [state.currentUser, state.divisionId]
    }, ([currentUser, divisionId]) => {
      if (currentUser && divisionId) {
        timeZone = currentUser.timezone
      }
    }, { immediate: true })
    app.config.globalProperties['$helpers'] = getHelpers(app)
  }
}

