import { createApp } from 'vue'
export default {
  install(app, options = {}) {
    if (!options.key || options.key.length === 0) {
      console.warn("Please enter a Help Scount Key");
    }
    const root = createApp()
    let isInjected = false,
        isBeaconReady = false,
        callbackQueue = []


    /**
     * HelpScout's main javascript actually loads two more javascript files asynchronously
     * so we don't have a way to detect when all scripts are loaded.
     *
     * window.Beacon and window.Beacon.readyQueue are temporary placeholders of the actual function from SDK.
     * Apparently when all HelpScout javascript files are loaded it will take calls from window.Beacon.readyQueue and
     * execute them.
     * Then window.Beacon placeholder is replaced by SDK with real function
     */
    const load = () => {
      window.Beacon = (method, options, data) => {
        window.Beacon.readyQueue.push({ method, options, data })
      }
      window.Beacon.readyQueue = []

      root.Beacon = window.Beacon
      const first = document.getElementsByTagName("script")[0];
      first.parentNode.insertBefore(root._script, first);
      isInjected = true

      window.Beacon('init', options.key)
      window.Beacon('on', 'ready', () => {
        isBeaconReady = true
        root.Beacon = window.Beacon
      })
      root._script.onload = () => {
        for (const callback of callbackQueue) {
          callback()
        }
        callbackQueue = []
      }
    };


    root.inject = ({ onError } = {}) => {
      if (isInjected) {
        throw new Error('Help Scout script is already injected')
      }
      root._script = document.createElement("script");
      root._script.type = "text/javascript";
      root._script.async = true;
      root._script.src = 'https://beacon-v2.helpscout.net'
      if (onError) {
        root._script.onerror = onError
      }
      load();
    }

    root.onReady = (callback) => {
      if (isInjected && isBeaconReady) {
        callback()
      } else {
        callbackQueue.push(callback)
      }
    }

    app.config.globalProperties['$helpScout'] = root
  }
};
