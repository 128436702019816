<template>
  <div class="field-wrapper" :class="cssClass" :style="style">
    <Field ref="field" :name="$attrs['name'] || randomName" v-slot="{meta, field, value, handleChange, errors}" :rules="rules" v-model="model" :label="validationLabel || fieldLabel">
      <b-field :label="fieldLabel" :type="{'is-danger': meta.validated && !meta.valid}" :class="{'required': isRequired(), 'has-errors':  meta.validated && !meta.valid, 'has-sublabel': sublabel}">
        <template #message v-if="rules">
          <div>
            <error-message :name="field.name"></error-message>
          </div>
        </template>
        <template #default>
          <p class="field-sublabel" v-if="sublabel">{{ sublabel }}</p>
          <div class="is-flex is-flex-direction-column component-wrapper">
            <component v-if="bName" :is="`b-${bName}`" v-bind="{...$attrs, ...dynamicAttrs}" @update:modelValue="modelChange"
                       :model-value="value">
              <!--
                @slot Used for b-select component to provide options
              -->
              <slot v-if="['select', 'checkbox', 'radio', 'slider', 'upload', 'switch'].includes(bName)"></slot>
            </component>
            <slot v-if="$slots.custom" name="custom" v-bind="{ handleChange, value, meta, field, errors }"></slot>
          </div>
        </template>
      </b-field>
    </Field>
  </div>
</template>


<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
/**
 * Component used to make it easer to place input with VeeValidation attached
 * @displayName Input With Validation
 */
export default {
  name: "InputWithValidation",
  components: { Field, Form, ErrorMessage },
  emits: ['update:modelValue'],
  inheritAttrs: false,
  props: {
    /**
     * Name of Buefy component which is directly used in <component>.
     * Prefix 'b-' is automatically applied
     */
    bName: {
      type: String,
    },
    /**
     * Label for input field
     */
    fieldLabel: {
      type: String,
      default: ''
    },
    /**
     * Validation rules
     * @see https://logaretm.github.io/vee-validate/guide/rules.html#rules
     */
    rules: {
      type: [String, Object],
      default: () => undefined
    },
    /**
     * Used to make this component accept v-model attribute
     */
    modelValue: {
      type: [String, Number, Boolean, Date, Array, File]
    },
    /**
     * Validation label used in validation error messages. If not provided, fieldLabel will be used
     * example {validationLabel} field is required
     */
    validationLabel: {
      type: String
    },
    /**
     * Custom messages object to override VeeValidate's default ones.
     * Key of the object should be rule as provided in rules prop and value is message you want to display
     */
    messages: {
      type: Object,
      default: () => { return {} }
    },
    /**
     * Text displayed below the label
     */
    sublabel: {
      type: String,
      default: ''
    },
    style: {
      type: [String, Array, Object],
      default: ''
    },
    class: {
      type: [String, Array, Object],
      default: ''
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    cssClass() {
      return this.class
    }
  },
  methods: {
    modelChange(val) {
      this.$refs.field.handleChange(val)
      this.$emit('update:modelValue', val)
    },
    isRequired() {
      if (this.rules) {
        if (typeof this.rules === 'string') {
          return this.rules.indexOf('required') !== -1
        } else {
          return Object.prototype.hasOwnProperty.call(this.rules, 'required')
        }
      } else {
        return false
      }
    },
    processFailedRules() {
      return ''
      // let messages = '';
      // for (const [rule, message] of Object.entries(rules)) {
      //   messages += `<span>${this.messages?.[rule] || message}</span>`
      // }
      // return messages
    },
  },
  mounted() {
    // this.$watch('$refs.provider.failedRules', (newVal) => {
    //   for (const [key,] of Object.entries(newVal)) {
    //     let customMessage = this.messages[key]
    //     if (customMessage)  {
    //       newVal[key] = customMessage
    //     }
    //   }
    //   this.$emit('failed-rules', newVal)
    // })
  },
  beforeMount() {
    if (['datepicker', 'datetimepicker'].includes(this.bName)) {
      this.dynamicAttrs['locale'] = 'en-US'
    }
  },
  data() {
    return {
      randomName: uuidv4(),
      dynamicAttrs: {}
    }
  }
}
</script>

<style scoped lang="scss">
  .field-wrapper {
    margin-bottom: 20px;
    //&:not(:last-child) {
    //  margin-bottom: 20px;
    //}
    :deep {
      .field.has-sublabel {
        label {
          margin-bottom: 0 !important;
        }
        .field-body .field {
          display: flex;
          flex-direction: column;
        }
        .field-sublabel {
          font-size: 12px;
          margin: 3px 0 8px;
          color: #475260;
          font-weight: 400;
          letter-spacing: 0.3px;
        }
      }
      .help {
        //min-height: 13px;
      }
      .field.required > label {
        display: flex;
        align-items: center;
        &:after {
          content: '\F06C4';
          display: inline-block;
          color: $danger;
          padding-left: 5px;
          font: normal normal normal 8px/7px "Material Design Icons";
          margin-top: -7px;
        }
      }
    }
  }
</style>
