import store from "@/store"
import to from "@/lib/to";
export const LOG_TYPES = Object.freeze({
  LIVE_VIDEO: {
    VIDEO_CONNECT_FAILED: 'video.connect_failed',
    VIDEO_CONNECT_FAILED_ALREADY_CONNECTED: 'video.connect_failed_already_connected',
    VIDEO_RECONNECTED: 'video.reconnected',
    VIDEO_DISCONNECT_EXPECTED: 'video.disconnect_expected',
    VIDEO_DISCONNECT_UNEXPECTED: 'video.disconnect_unexpected',
    CONNECT_ALL_CLICKED: 'video.connect_all_clicked',
    CONNECT_STARTED: 'video.connect_started',
    CONNECT_SUCCEEDED: 'video.connect_succeeded',
  },
  PATIENT_MONITOR: {
    TALK_CLICKED: 'patient_monitor.talk_clicked',
    TALK_CLOSED: 'patient_monitor.talk_closed',
    PATIENT_MONITOR_CURTAIN_OPENED: 'patient_monitor.curtain_opened',
    PATIENT_MONITOR_CURTAIN_CLOSED: 'patient_monitor.curtain_closed'
  },
  USER: {
    USER_AUTHENTICATED: 'user.authenticated',
    USER_LOGGED_OUT: 'user.logged_out',
    USER_SESSION_EXPIRED: 'user.session_expired'
  }
})

export function createLoggerInstance(http) {
  const sendRequest = (payload) => {
    payload.session_id = store.state.uniqueSessionId
    return to(http.post('/audit_logs?division_id=*', payload, { skipLoading: true, skipDivisions: true }))
  }
  return {
    logLiveVideo(type, videoType, patient_id, monitorId = null, meetingId = null) {
      return sendRequest({ category: type, patient_monitor_id: monitorId, video_type: videoType, meeting_id: meetingId, patient_id: patient_id })
    },
    logPatientMonitor(type, monitorId) {
      return sendRequest({ category: type, patient_monitor_id: monitorId })
    },
    logUser(type) {
      return sendRequest({ category: type })
    }
  }
}
