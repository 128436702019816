<template>
  <b-table-wrapper :table-id="tableId" :columns="columns" :data="data" :metadata="metadata" :filters="filters" :default-filters="defaultFilters" :ld-default-sort="defaultSort" backend-paginable filterable backend-sortable @load-data="getVideoDevices" v-bind="tableAttributes">
    <template #field-status="{ row }">
      <div class="tags are-small">
        <div class="tag" :class="statusToClass[row.status]">{{ row.status }}</div>
      </div>
    </template>
    <template #static-columns>
      <b-table-column field="__" sticky header-class="table-sticky-action" cell-class="table-sticky-action"  width="100" v-slot="{ row }" v-if="!isStandaloneComponent">
        <table-action-button v-if="canEditVideoDevice" tooltip-text="Edit"
                             :icon-class="['mdi mdi-file-document-edit-outline']"
                             :link="{ name: 'hospital.video_devices.edit', params: { id: row.id } }">
        </table-action-button>
      </b-table-column>
    </template>
  </b-table-wrapper>
</template>

<script>
import to from "@/lib/to";
import {FilterType, TableFilter} from "@/models/TableFilter";
import TableActionButton from "@/components/table/TableActionButton";
import BTableWrapper from "@/components/table/BTableWrapper";
import TableColumn from "@/models/TableColumn";

export default {
  name: "VideoDevicesTable",
  components: {BTableWrapper, TableActionButton},
  props: {
    isStandaloneComponent: {
      type: Boolean,
      default: false
    },
    tableAttributes: {
      type: Object,
      default: () => { return {} }
    },
    apiEndpoint: {
      type: String,
      default: '/video_devices'
    },
    filters: {
      type: Array,
      default: () => {
        return [
          new TableFilter({ name: 'name', displayName: "Name", type: FilterType.TEXT }),
          new TableFilter({
            name: 'status', displayName: "Status", type: FilterType.ENUM ,
            enumValues: [
              {key: 'offline', value: 'Offline'},
              {key: 'online', value: 'Online'},
              {key: 'error', value: 'Error'}
            ]
          }),
          new TableFilter({
            name: 'kind', displayName: "Type", type: FilterType.ENUM ,
            enumValues: [
              {key: 'fixed', value: 'Fixed'},
              {key: 'mobilecart', value: 'Mobile Cart'},
              {key: 'mobile3rdparty', value: 'Mobile 3rd Party'},
              {key: 'trainingvideo', value: 'Training Video'}
            ]
          }),
          new TableFilter({
            name: 'service_status', displayName: "Service Status", type: FilterType.ENUM ,
            enumValues: [
              {key: 'in_service', value: 'In Service'},
              {key: 'decommissioned', value: 'Decommissioned'}
            ]
          }),
          new TableFilter({ name: 'room', displayName: 'Room Name', type: FilterType.TEXT })
        ]
      }
    },
    tableId: {
      type: String,
      required: false,
      default: 'video-devices-list'
    }
  },
  methods: {
    async getVideoDevices({ page, filters } = {}) {
      const [err, response] = await to(this.http.get(this.apiEndpoint, { params: { page, ...filters }}))
      if (!err) {
        const { data: { results, metadata } } = response
        this.data = results.map(device => {
          let inUse = false
          if (device.patient_monitors.length) {
            const activeMonitors = device.patient_monitors.filter(x => x.status !== 'stopped')
            inUse = activeMonitors.length == 1 ? this.$helpers.mask('room_name', activeMonitors[0].room.name) : false
          }
          device.inUse = inUse
          return device
        })
        this.metadata = metadata
      }
    },
    async changeFrameProcessing(videoDeviceId, frame_processing) {
      await to(this.$http.post(`/video_devices/${videoDeviceId}/frame_processing`, { frame_processing }))
    },
    async changeFrameInterval(videoDeviceId, frame_upload_interval) {
      await to(this.$http.post(`/video_devices/${videoDeviceId}/frame_upload_interval`, { frame_upload_interval }))
    },
    humanizeServiceStatus(status) {
      let humanText = status;
      if (this.serviceStatus[status]) {
        humanText = this.serviceStatus[status]
      }
      return humanText
    }
  },
  data() {
    return {
      canViewVideoDevice: this.$can('View', 'VideoDevice'),
      canEditVideoDevice: this.$can('Edit', 'VideoDevice'),
      columns: [
        new TableColumn({ sortable: true, field: 'video_devices.status', label: 'Status', slotName: 'status' }, null, true),
        new TableColumn({ sortable: true, field: 'video_devices.name', label: 'Name' }, row => row.name),
        new TableColumn({ sortable: true, field: 'video_devices.id', label: 'ID', 'cell-class': 'no-wrap' }, row => row.id),
        new TableColumn({ sortable: true, field: 'video_devices.kind', label: 'Type', 'cell-class': 'no-wrap' }, row => row.kind),
        new TableColumn({ sortable: true, field: 'rooms.name', label: 'Room' }, row => row.room ? this.$helpers.mask('room_name', row.room.name) : 'N/A'),
        new TableColumn({ sortable: true, field: 'video_devices.version', label: 'Version' }, row => row.version ? row.version : 'N/A'),
        new TableColumn({ sortable: true, field: "state->'pending_software_update'", label: 'Update' }, row => row.state?.pending_software_update == undefined ? "N/A" : row.state.pending_software_update),
        new TableColumn({ sortable: true, field: 'video_devices.state_updated_at', label: 'State Updated', 'cell-class': 'no-wrap' }, row => row.state_updated_at ? this.$helpers.formatDate(row.state_updated_at) : 'N/A'),
        new TableColumn({ sortable: true, field: 'video_devices.service_status', label: 'Service Status' }, row => this.humanizeServiceStatus(row.service_status)),
        new TableColumn({ sortable: true, field: 'video_devices.ip_address', label: 'IP Address', 'cell-class': 'no-wrap' }, row => row.ip_address || 'N/A'),
        new TableColumn({ sortable: true, field: 'video_devices.serial_number', label: 'Serial #', 'cell-class': 'no-wrap' }, row => row.serial_number || 'N/A'),
        new TableColumn({ sortable: true, field: 'video_devices.mac_address', label: 'MAC Address', 'cell-class': 'no-wrap' }, row => row.mac_address || 'N/A'),
        new TableColumn({ sortable: true, field: 'video_devices.frame_upload_interval', label: 'Frame Upload Interval', isChecked: false }, row => row.state?.frame_upload_interval || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'edge_processing_enabled'", label: 'Edge Pipeline', isChecked: false }, row => row.state?.edge_processing_enabled ? 'Enabled' : 'Disabled'),
        new TableColumn({ sortable: true, field: "state->'kiosk_mode'", label: 'Kiosk Mode', isChecked: false }, row => row.state?.kiosk_mode ? 'Enabled' : 'Disabled'),
        new TableColumn({ sortable: true, field: "state->'video_profile'", label: 'Video Profile', isChecked: false }, row => row.state?.video_profile || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'metrics'->'net_wlan_ip'", label: 'Network Interface', isChecked: false }, row => row.network_interface || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'metrics'->'wifi_country_code'", label: 'WiFi Country', isChecked: false }, row => row.state?.metrics?.wifi_country_code || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'metrics'->'wifi_dfs_channels_disabled'", label: 'WiFi DFS Disabled', isChecked: false }, row => row.state?.metrics?.wifi_dfs_channels_disabled || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'metrics'->'wifi_frequency'", label: 'WiFi Frequency', isChecked: false }, row => row.wifi_frequency_band || 'N/A'),
        new TableColumn({ sortable: true, field: "state->'metrics'->'wifi_signal_strength'", label: 'WiFi Signal', isChecked: false }, row => row.state?.metrics?.wifi_signal_strength || 'N/A'),
        new TableColumn({ sortable: true, field: "video_devices.description", label: 'Device Description', isChecked: false }, row => row.description || 'N/A'),
        new TableColumn({ sortable: true, field: 'divisions.name', label: 'Hospital Name', isChecked: false }, row => row.division.name )
      ],
      keysToHuman: {
        frame_processing: {
          off: 'Off',
          upload: 'Upload Frames',
          upload_and_pipeline: 'Upload Frames and Enable Pipeline'
        }
      },
      data: [],
      metadata: {},
      statusToClass: {
        'online': 'is-success',
        'offline': 'is-warning',
        'error': 'is-danger'
      },
      defaultFilters: {
        'service_status': ['in_service'],
        'kind': ['fixed', 'mobilecart', 'mobile3rdparty']
      },
      serviceStatus: {
        in_service: 'In Service',
        decommissioned: 'Decommissioned'
      },
      defaultSort: {
        field: 'video_devices.status', direction: 'asc'
      }
    }
  }
}
</script>
<style scoped>
.description {
  max-width: 500px;
  line-height: 17px;
}
:deep(.no-wrap) {
  white-space: nowrap !important;
}
</style>
