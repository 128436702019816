import { createStore } from 'vuex'
import LiveVideoStore from "@/store/LiveVideoStore";
import LiveVideoWidgetStore from "@/store/LiveVideoWidgetStore";
import UIStore from "@/store/UIStore";
import TableFiltersStore from "@/store/TableFiltersStore";
import CustomFieldsStore from "@/store/CustomFieldsStore";
import CentralMonitoringStore from "@/store/CentralMonitoringStore";

export default createStore({
  state: {
    sidebarIsOpen: false,
    isLoading: false,
    authLoading: true,
    token: null,
    networkErrors: [],
    currentUser: null,
    currentHospital: null,
    divisionId: null,
    divisionPickerState: 'visible',
    urlAccessedDirectly: false,
    uniqueSessionId: null
  },
  mutations: {
    setDivisionId(state, divisionId) {
      state.divisionId = !divisionId || divisionId == '*' ? '*' : +divisionId
    },
    setDivisionPickerState(state, pickerState) {
      if (!['hidden', 'visible', 'readonly'].includes(pickerState)) {
        throw new Error("Division picker state can only be 'disabled', 'enabled', or 'readonly'")
      }
      state.divisionPickerState = pickerState
    },
    setAuthLoading(state, authLoadingState) {
      state.authLoading = authLoadingState
    },
    toggleSidebar(state) {
      state.sidebarIsOpen = !state.sidebarIsOpen
    },
    toggleLoading(state, { loadingState }) {
      state.isLoading = typeof loadingState === 'undefined' ? !state.isLoading : loadingState
    },
    setToken(state, { token }) {
      state.token = token
    },
    setUniqueSessionId(state, { id }) {
      state.uniqueSessionId = id
    },
    setNetworkErrors(state, payload) {
      state.networkErrors = payload
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    setCurrentHospital(state, hospital) {
      state.currentHospital = hospital
    },
    setGridColumnNumber(state, gridColNumber) {
      state.currentUser.grid_column_number = gridColNumber
    },
    setUrlAccessedDirectly(state, isDirect) {
      state.urlAccessedDirectly = !!isDirect
    }
  },
  actions: {
  },
  modules: {
    LiveVideoStore,
    LiveVideoWidgetStore,
    UIStore,
    TableFiltersStore,
    CustomFieldsStore,
    CentralMonitoringStore
  }
})
