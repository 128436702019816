import to from "@/lib/to";
import WebRTCIssueDetector from "webrtc-issue-detector";
const semver = require('semver');

const WAV_SAMPLE_RATE = 16000;  // create the wav with this sample rate
const TRANSLATE_INTERVAL = 3000; // pass the recorded audio to the API at this interval (ms)
const TRANSLATE_IGNORE = ['Thank you.', '.', 'Thank you for watching.', 'Bye-bye.', 'Bye.'];
const VIDEO_FREEZE_THRESHOLD_MS = 20000;  // if video is frozen for this long, disconnect

class WebRTCConnectionIssueDetector {
  detector;
  constructor(issueListener, networkScoresListener, statsIntervalMs) {
    console.debug("stats interval", statsIntervalMs);
    const params = {
      onIssues: issueListener,
      onNetworkScoresUpdated: networkScoresListener,
      logger: console,
      autoAddPeerConnections: false,
      getStatsInterval: statsIntervalMs || 10000,
    }
    this.detector = new WebRTCIssueDetector(params);
  }

  setPeerConnection(pc) {
    try {
      this.detector.handleNewPeerConnection(pc);
      console.debug("peer connection set for stats reporting");
    } catch (err) {
      console.error("error setting peer connection for issue detector", err);
    }
  }

  stop() {
    try {
      if (this.detector) {
        this.detector.stopWatchingNewPeerConnections();
      }
    } catch (err) {
      console.warn("error stopping stats reporter", err);
    }
  }
}

class Participant {
  mediaStream;
  localStream;  // for a separate local stream for a peer with different media constraints (currently just kiosk)
  dataChannel;
  issueDetector;
  constructor(participantId, peerConn, isPatient) {
    this.participantId = participantId;
    this.peerConn = peerConn;
    this.isPatient = isPatient;
  }

  /**
   * Mutes this participant's incoming audio (outgoing audio is uneffected)
   */
  muteAudio() {
    this.setAudioEnabled(false);
  }

  /**
   * Unmutes this participant's incoming audio (outgoing audio is uneffected)
   */
  unmuteAudio() {
    this.setAudioEnabled(true);
  }

  setAudioEnabled(isEnabled) {
    if (this.mediaStream) {
      this.mediaStream.getAudioTracks().forEach((trk) => {
        trk.enabled = isEnabled;
      });
    }
  }

  enableIssueDetection(peerConn, issueListener, networkScoresListener, statsIntervalMs) {
    this.issueDetector = new WebRTCConnectionIssueDetector(issueListener, networkScoresListener, statsIntervalMs);
    this.issueDetector.setPeerConnection(peerConn);
    console.info(`enabled issue detection for ${this.participantId} with interval ${statsIntervalMs}ms`);
  }

  /**
   * Disconnects the participant's resources
   * @param {string} notifyPeerId The peer id of the room participant to notify other 
   * participants of the disconnect or null to not notify
   */
  disconnect(notifyPeerId = null) {
    console.debug(`disconnecting participant ${this.participantId}`, notifyPeerId, this.dataChannel);
    if (this.issueDetector) {
      this.issueDetector.stop();
      console.debug(`stopped issue detection for ${this.participantId}`);
    }
    if (this.dataChannel) {
      if (notifyPeerId) {
        // notify other participants that this participant is disconnecting
        try {
          this.dataChannel.send(JSON.stringify({
            peer_id: notifyPeerId,  // send the peer id of the participant that is disconnecting
            type: 'user-bye'
          }));
          console.debug(`sent user-bye from ${notifyPeerId} to ${this.participantId}`);
        } catch (err) {
          console.warn(`error sending user-bye to ${this.participantId}`, err);
        }
        const dataChannel = this.dataChannel;
        setTimeout(() => {
          // close after a slight delay to make sure the message goes through
          dataChannel.close();
          console.debug("data channel closed");
        }, 100);
      } else {
        this.dataChannel.close();
        console.debug("data channel closed");
      }
    }
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((trk) => {
        trk.stop();
        console.debug(`stopped remote ${trk.kind} track (${trk.id}) for ${this.participantId}`, trk);
      });
    } else if (this.participantId !== 'kiosk-viewer') {  // kiosk-viewer is a special participant that doesn't send a media stream
      console.warn(`participant ${this.participantId} had no media stream while disconnecting`);
    }
    if (this.localStream) {
      console.log(`disconnecting local stream for ${this.participantId}`, this.localStream);
      this.localStream.getTracks().forEach((track) => {
        track.stop();
        console.debug(`stopped local stream ${track.kind} track (${track.id})`, track);
      });
    } else {
      console.debug(`participant ${this.participantId} had no local stream while disconnecting`);
    }
    if (this.peerConn) {
      this.peerConn.close();
      console.debug(`closed peer connection to ${this.participantId}`);
    } else {
      console.warn(`participant ${this.participantId} had no peer connection while disconnecting`);
    }
    this.mediaStream = null;
    this.peerConn = null;
    this.dataChannel = null;
    this.issueDetector = null;
  }
}

export default class VideoRoomManager {
  static EVENT_TYPES = {
    localMediaStreamConnected: 'localMediaStreamConnected',
    disconnected: 'disconnected',
    participantDisconnected: 'participantDisconnected',
    participantTrackAdded: 'participantTrackAdded',
    participantJoined: 'participantJoined',
    signalRequired: 'signalRequired',
    nudgeReceived: 'nudgeReceived',
    inferenceReceived: 'inferenceReceived',
    webrtcIssue: 'webrtcIssue',
    webrtcNetworkScoreUpdated: 'webrtcNetworkScoreUpdated',
    connectSucceeded: 'connectSucceeded',
  }
  participantId;  // my id in this video room channel
  localMediaConstraints;
  localMediaStream;
  roomType;  // meeting|monitoring|test
  pendingIceCandidates = new Map();
  eventListeners = new Map();
  remoteParticipants = new Map();
  userDisplayName = "";
  deviceParticipant;
  captionRecorder;
  videoDeviceId;
  turnServers = [];
  statsIntervalMs;  // default stats interval
  webrtcIssuesQueue = { stats: [], warning: [] }
  webrtcIssuesIntervalTime = 5 * 60 * 1000 // 5 minutes
  webrtcIssuesInterval = null
  patientVideoFrozenAt = null

  /**
   * Connects the current user to the patient device in a video room
   * @param {object} joinData The result of /videos/join
   * @param {string} roomType "meeting", "monitoring", or "test"
   * @param {string} camera "internal" or "ptz"
   * @param {number} statsInterval interval to collect webrtc issues
   * @param {object} mediaConstraints webrtc media constraints passed to getUserMedia()
   * @param {string} userDisplayName The display name of the user
   */
  async connect(joinData, roomType, camera, mediaConstraints, statsInterval = 60, userDisplayName = "guest") {
    console.debug("room stats interval", statsInterval);
    this.localMediaConstraints = mediaConstraints;
    this.roomType = roomType;
    this.participantId = joinData.peer_id;
    this.userDisplayName = userDisplayName;
    this.turnServers = joinData.turn_servers || [];
    this.videoDeviceId = joinData.device_id.split('-').slice(-1)[0];  // device_id is in the format "video_device-<id>"
    this.statsIntervalMs = statsInterval * 1000;
    console.debug(`set participant id = ${this.participantId}. video device = ${this.videoDeviceId}`);
    const [err] = await to(this.loadLocalMediaStream(this.localMediaConstraints))
    if (err) {
      console.error("unable to get media access", err);
      return Promise.reject(err)
    } else {
      const participant = await this.createParticipant(joinData.device_id, true, joinData.sdp, joinData.ice_candidates, this.statsIntervalMs, null);
      this.deviceParticipant = participant;
      const peerConn = participant.peerConn;

      console.info(`connecting to ${joinData.device_id} (version ${joinData.device_version})...`);

      // TODO: remove this when all video devices are updated to support trickle ICE
      if (!joinData.device_version || semver.lt(joinData.device_version, "1.4.9")) {
        console.warn(`video device version ${joinData.device_version} is too old for trickle ICE`);
        console.debug("remote sdp set. creating answer...");
        const localSdpAnswer = await peerConn.createAnswer();
        console.debug("local descr", localSdpAnswer);
        await peerConn.setLocalDescription(localSdpAnswer);

        const localIceCandidates = [];
        peerConn.onicecandidate = (event) => {
          if (event.candidate == null) {
            // with trickle ICE, a null indicates there are no more ICE candidates
            console.debug("ICE Candidate was null, ICE processing is done", localIceCandidates);
            // SDP and ICE collected - signal response back to video device
            this.emit(VideoRoomManager.EVENT_TYPES.signalRequired, {
              video_type: this.roomType,
              signal_type: 'sdp-answer',
              sdp: localSdpAnswer,
              ice_candidates: localIceCandidates,
            });
            return;
          }
          localIceCandidates.push(event.candidate);
        };
      } else {
        console.debug("remote sdp set. creating answer...");
        const localSdpAnswer = await peerConn.createAnswer();
        console.debug("local descr", localSdpAnswer);
        this.emit(VideoRoomManager.EVENT_TYPES.signalRequired, {
          video_type: this.roomType,
          signal_type: 'sdp-answer',
          sdp: localSdpAnswer,
        });
        let ice_candidates_batch = [];
        peerConn.onicecandidate = (event) => {
          if (event.candidate != null) {
            console.debug("local ICE Candidate", event.candidate);
            if (ice_candidates_batch) {
              ice_candidates_batch.push(event.candidate);
              if (ice_candidates_batch.length === 1) {
                // typically the first 2-3 ICE candidates are cached and returned immediately one after another
                // so wait a tiny bit before sending the initial batch
                setTimeout(() => {
                  this.emit(VideoRoomManager.EVENT_TYPES.signalRequired, {
                    video_type: this.roomType,
                    signal_type: 'ice-candidates',
                    ice_candidates: ice_candidates_batch,
                  });
                  ice_candidates_batch = null;
                }, 40);
              }
            } else {
              this.emit(VideoRoomManager.EVENT_TYPES.signalRequired, {
                video_type: this.roomType,
                signal_type: 'ice-candidates',
                ice_candidates: [event.candidate],
              });
            }
          } else {
            // with trickle ICE, a null indicates there are no more ICE candidates
            console.debug("local ICE Candidate was null, ICE processing is done");
          }
        };
        await peerConn.setLocalDescription(localSdpAnswer);
      }


      if (this.statsIntervalMs != 0) {
        this.webrtcIssuesInterval = setInterval(() => {
          if (this.webrtcIssuesQueue.warning.length) {
            this.emit(VideoRoomManager.EVENT_TYPES.webrtcIssue, this.webrtcIssuesQueue.warning);
          }
          if (this.webrtcIssuesQueue.stats.length) {
            this.emit(VideoRoomManager.EVENT_TYPES.webrtcNetworkScoreUpdated, this.webrtcIssuesQueue.stats);
          }
          this.webrtcIssuesQueue.warning = []
          this.webrtcIssuesQueue.stats = []
        }, this.webrtcIssuesIntervalTime)
      }

    }
  }

  /*
   * Invite a browser peer to create a connection with this browser peer. This is used in
   * the meeting context where multiple users can connect to a video device.
   */
  async invitePeerParticipant(peerId) {
    const participant = await this.createParticipant(peerId, false, null, null);
    if (participant) {
      console.log(`generating offer for ${peerId}`);
      const peerConn = participant.peerConn;
      const offer = await peerConn.createOffer();
      await peerConn.setLocalDescription(offer);
      const descr = peerConn.localDescription;
      console.debug("local offer descr", descr);

      const localIceCandidates = [];
      peerConn.onicecandidate = (event) => {
        if (event.candidate == null) {
          // with trickle ICE, a null indicates there are no more ICE candidates
          console.debug("ICE Candidate was null, ICE processing is done", localIceCandidates);
          const offerPayload = JSON.stringify({
            peer_id: peerId,
            sender_peer_id: this.participantId,
            type: 'peer-sdp-offer',
            sdp: {
              type: 'offer',
              sdp: descr.sdp
            },
            ice_candidates: localIceCandidates,
          });
          // signal through the video device data channel
          this.deviceParticipant.dataChannel.send(offerPayload);
          console.info(`sent peer offer to ${peerId}`);
          return;
        }
        localIceCandidates.push(event.candidate);
      };
    } else {
      console.warning(`Participant ${peerId} already connected`);
    }
  }

  /**
   * Disconnects from the room
   */
  disconnect(disconnectReason = null) {
    console.debug(`disconnecting remote participants. reason: ${disconnectReason}`);
    this.remoteParticipants.forEach((participant) => {
      participant.disconnect(this.participantId);
    });
    this.remoteParticipants.clear();
    this.pendingIceCandidates.clear();

    // clean up all resources
    if (this.localMediaStream) {
      this.localMediaStream.getTracks().forEach((track) => {
        track.stop();
        console.debug(`stopped local ${track.kind} track (${track.id})`, track, this.localMediaStream);
      });
    }

    this.localMediaStream = null;
    this.deviceParticipant = null;
    if (this.recorderTimer) {
      clearInterval(this.recorderTimer);
      this.recorderTimer = null;
    }
    try {
      this.emit(VideoRoomManager.EVENT_TYPES.disconnected, disconnectReason);
    } finally {
      this.eventListeners.clear();
      clearInterval(this.webrtcIssuesIntervalTime)
    }
  }

  sendDeviceDataChannelMessage(msg) {
    console.info("sending device data channel message", msg, this.deviceParticipant)
    this.deviceParticipant.dataChannel.send(JSON.stringify(msg));
  }

  onWebRTCIssue(issues) {
    console.warn(`device ${this.videoDeviceId} webrtc issue: ${JSON.stringify(issues)}`, issues);
    this.webrtcIssuesQueue.warning.push({ timestamp: Date.now(), metrics: issues })
  }

  onWebRTCNetworkScoresUpdated(scores) {
    // https://www.webrtc-developers.com/webrtc-statistics-using-getstats/#display-a-quality-indicator
    /* example payload:
    {
      inbound: 4.403401591252691,
      statsSamples: {
        inboundStatsSample: {
          avgJitter: 0.002,
          packetLoss: 0,
          rtt: 2
        }
      }
    }
    */
    console.debug(`device ${this.videoDeviceId} webrtc score: ${scores.inbound}`, scores);
    // only tracking inbound
    if (scores.inbound) {
      this.webrtcIssuesQueue.stats.push({ timestamp: Date.now(), metrics: scores })
    }
  }

  /**
   * Adds an event callback
   * @param {string} eventType The type of event
   * @param {string} eventCallback A callback
   */
  on(eventType, eventCallback) {
    this.eventListeners.set(eventType, eventCallback);
    console.debug(`added event listener for ${eventType}`);
  }

  emit(eventType, ...eventArgs) {
    const callback = this.eventListeners.get(eventType);
    if (callback) {
      // console.debug(`emitting event ${eventType}`, eventArgs);
      // unwrap varargs and call callback
      callback.apply(null, eventArgs);
    }
  }

  /**
   * Mutes the local audio (outgoing) stream. Any incoming audio is uneffected.
   */
  muteLocalAudio() {
    this.setLocalAudioEnabled(false);
  }

  /**
   * Unmutes the local audio (outgoing) stream. Any incoming audio is uneffected.
   */
  unmuteLocalAudio() {
    this.setLocalAudioEnabled(true);
  }

  setLocalAudioEnabled(isEnabled) {
    if (this.localMediaStream) {
      this.localMediaStream.getAudioTracks().forEach((trk) => {
        trk.enabled = isEnabled;
      });
    }
  }

  async setVolume(volume, http) {
    console.debug('setting volume', volume)
    if (volume >= 60 && volume <= 100) {
      if (this.videoDeviceId) {
        await http.post(`/video_devices/${this.videoDeviceId}/volume`, {volume}, {
          skipLoading: true,
          handleErrors: false
        });
      }
      return volume
    }
    return null
  }

  async loadLocalMediaStream(constraints) {
    console.debug("asking for local media access", constraints);
    if (this.localMediaStream) {
      // this is likely a problem where the local stream wasn't closed and cleared properly
      console.warn("local media stream already exists");
    }
    if (constraints && (constraints.audio !== false || constraints.video !== false)) {
      const [err, mediaSteam] = await to(navigator.mediaDevices.getUserMedia(constraints))
      if (err) {
        return Promise.reject(err)
      } else {
        this.localMediaStream = mediaSteam
        console.debug("got media stream");
        this.emit(VideoRoomManager.EVENT_TYPES.localMediaStreamConnected, this.localMediaStream);
      }
    }
  }

  onParticipantDisconnected(participant, peerConnDisconnected) {
    console.debug(`participant ${participant.participantId} was disconnected. room type = ${this.roomType}. peer disconnect? ${peerConnDisconnected}`);
    if (peerConnDisconnected) {
      participant.disconnect();
      this.emit(VideoRoomManager.EVENT_TYPES.participantDisconnected, participant);
      this.remoteParticipants.delete(participant.participantId);
    } else {
      console.warn(`device ${participant.participantId} participant was disconnected.`);
    }
  }

  /*
   * handler for a remote SDP answer from another participant (non-patient) that we sent
   * an offer to
   */
  async onPeerAnswer(remoteParticipantId, remoteSdp, remoteIceCandidates) {
    const participant = this.remoteParticipants.get(remoteParticipantId);
    if (!participant) {
      console.error(`no remote participant found for ${remoteParticipantId}`);
    }
    const peerConn = participant.peerConn;
    console.debug("creating remote sdp...");
    const remoteDesc = new RTCSessionDescription(remoteSdp);
    await peerConn.setRemoteDescription(remoteDesc);
    console.debug("remote description set");

    remoteIceCandidates.forEach((cand) => {
      peerConn.addIceCandidate(cand);
      console.debug(`added ice candidate to ${remoteParticipantId} connection`, cand);
    });
  }

  /*
   * Handler for a browser peer (non-patient) offer
   */
  async onPeerOffer(remoteParticipantId, remoteSdp, remoteIceCandidates, mediaRequest) {
    let localStream = null;
    if (mediaRequest) {
      console.debug("got media request from peer", mediaRequest);
      // assuming this is for kiosk viewer only for now - need a new stream with different constraints
      if (this.localMediaConstraints.video === false || mediaRequest.video === false) {
        console.debug("video not requested or user has not allowed video");
      } else {
        const [err, mediaStream] = await to(navigator.mediaDevices.getUserMedia({
          audio: false,
          // don't ask for more access than originally requested
          video: mediaRequest.video,
        }));
        if (err) {
          console.error("error getting media stream for requested video constraints", mediaRequest.video, err);
        } else {
          localStream = mediaStream;
        }
      }
    }

    const participant = await this.createParticipant(remoteParticipantId, false, remoteSdp, remoteIceCandidates, this.statsIntervalMs, localStream);
    const peerConn = participant.peerConn;
    const localSdpAnswer = await peerConn.createAnswer();
    console.debug("local descr", localSdpAnswer);
    await peerConn.setLocalDescription(localSdpAnswer);

    const localIceCandidates = [];
    peerConn.onicecandidate = (event) => {
      if (event.candidate == null) {
        // with trickle ICE, a null indicates there are no more ICE candidates
        console.debug("ICE Candidate was null, ICE processing is done", localIceCandidates);
        // relay the peer answer through the device data channel
        const payload = {
          type: 'peer-sdp-answer',
          peer_id: remoteParticipantId,
          sender_peer_id: this.participantId,
          sdp: localSdpAnswer,
          ice_candidates: localIceCandidates,
        };
        this.deviceParticipant.dataChannel.send(JSON.stringify(payload));
        console.info(`sent peer sdp answer to ${remoteParticipantId}`);
        return;
      }
      localIceCandidates.push(event.candidate);
    };
  }

  async startPatientTranscription(httpClient) {
    // find the patient participant in the map of remote participants
    const patientParticipant = Array.from(this.remoteParticipants.values()).find((p) => p.isPatient);
    if (!patientParticipant) {
      console.warn("no patient participant found");
      return;
    }

    const stream = patientParticipant.mediaStream;
    if (!stream) {
      console.warn("no patient stream found");
      return;
    }

    const audioCtx = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: WAV_SAMPLE_RATE,
      channelCount: 1,
      echoCancellation: false,
      autoGainControl: true,
      noiseSuppression: true,
    });
    const recorder = new MediaRecorder(stream);
    // keep track of the first buffer because it contains the audio header
    let firstBuffer = null;
    let firstBufferDecodeOffset = 0;
    recorder.ondataavailable = (dataEvent) => {
      // console.debug('js: audio data available, size: ' + dataEvent.data.size);
      // keep the header b/c decodeAudioData needs a valid audio file which includes a header
      let decodeBuffers;
      if (firstBuffer !== null) {
        decodeBuffers = [firstBuffer, dataEvent.data];
      } else {
        firstBuffer = dataEvent.data;
        decodeBuffers = [dataEvent.data];
      }
      const blob = new Blob(decodeBuffers, { type: 'audio/ogg; codecs=opus' });  // assuming opus for webrtc audio
      blob.arrayBuffer().then((buf) => {
        // decode the audio data to get the raw audio data
        audioCtx.decodeAudioData(buf, (audioBuffer) => {
          // whisper API doesn't support opus so convert to wav
          const offlineCtx = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);
          const source = offlineCtx.createBufferSource();
          source.buffer = audioBuffer;
          source.connect(offlineCtx.destination);
          source.start(0);
          offlineCtx.startRendering().then(async (renderedBuffer) => {
            let audioBuf;
            if (firstBufferDecodeOffset === 0) {
              // keep track of the where the first buffer ends
              audioBuf = renderedBuffer;
              firstBufferDecodeOffset = renderedBuffer.length;
            } else {
              // we only want the new part of the buffer without the header
              audioBuf = audioCtx.createBuffer(1, renderedBuffer.length - firstBufferDecodeOffset, renderedBuffer.sampleRate);
              const c = audioBuf.getChannelData(0);
              c.set(renderedBuffer.getChannelData(0).slice(firstBufferDecodeOffset), 0);
            }
            
            // convert the raw audio data to wav
            const wavBlob = this.bufferToWave(audioBuf, audioBuf.duration * audioBuf.sampleRate);
            const form = new FormData();
            form.append('model', 'whisper-1');
            form.append('file', wavBlob, "audio.wav");
            const [err, response] = await to(httpClient.post('/videos/translate', form, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              skipLoading: true
            }));
            if (!err) {
              const { data: captionResponse } = response
              const captionText = captionResponse.text;
              // TODO: this is a hack workaround. ideally this is fixed by whisper or we use VAD to filter out silence
              // need to filter out hallucinations - see https://github.com/huggingface/transformers/issues/24512
              if (!TRANSLATE_IGNORE.includes(captionText.trim())) {
                console.log(`cc: ${captionText}`);
                this.emit("caption", captionText);
              } else {
                console.warn(`ignoring caption of silence: ${captionText}`);
              }
            }
          });
        });
      });
    }

    recorder.start(TRANSLATE_INTERVAL);
  }

  // Convert an AudioBuffer to a Blob using WAVE representation
  // adapted from https://russellgood.com/how-to-convert-audiobuffer-to-audio-file/
  bufferToWave(abuffer, len) {
    var numOfChan = abuffer.numberOfChannels,
        length = len * numOfChan * 2 + 44,
        buffer = new ArrayBuffer(length),
        view = new DataView(buffer),
        channels = [], i, sample,
        offset = 0,
        pos = 0;

    // write WAVE header
    setUint32(0x46464952);                         // "RIFF"
    setUint32(length - 8);                         // file length - 8
    setUint32(0x45564157);                         // "WAVE"

    setUint32(0x20746d66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit (hardcoded in this demo)

    setUint32(0x61746164);                         // "data" - chunk
    setUint32(length - pos - 4);                   // chunk length

    // write interleaved data
    for(i = 0; i < abuffer.numberOfChannels; i++)
      channels.push(abuffer.getChannelData(i));

    while(pos < length) {
      for(i = 0; i < numOfChan; i++) {             // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
        view.setInt16(pos, sample, true);          // write 16-bit sample
        pos += 2;
      }
      offset++                                     // next source sample
    }

    // create Blob
    return new Blob([buffer], {type: "audio/wav"});

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  }

  enableInference() {
    this.deviceParticipant.dataChannel.send(JSON.stringify({
      type: 'enable-inference'
    }));
    console.info("enabled inference");
  }

  disableInference() {
    this.deviceParticipant.dataChannel.send(JSON.stringify({
      type: 'disable-inference'
    }));
    console.info("disable inference");
  }

  buildRTCIceServer(url) {
    const re = /^(turns?:)\/\/([^:]+):([^@]+)@(.+)$/
    const matches = url.match(re);
    if (!matches) {
      console.error(`invalid turn server url: ${url}`);
      return null;
    }
    return {
      username: decodeURIComponent(matches[2]),
      credential: decodeURIComponent(matches[3]),
      urls: `${matches[1]}${matches[4]}`,
    };
  }

  async createParticipant(remoteParticipantId, isPatient, remoteSdp, remoteIceCandidates, statsIntervalMs = null, localStream = null) {
    console.info(`connecting to ${remoteParticipantId}...`);
    if (this.remoteParticipants.has(remoteParticipantId)) {
      console.error(`already have an existing connection to ${remoteParticipantId}`);
      return null;
    }
    // create a peer conn as quickly as possible because we can get related ICE messages
    // at any time now.
    console.debug("creating peer connection...");
    const config = {
      bundlePolicy: 'max-bundle',
      iceCandidatePoolSize: 8,
      iceTransportPolicy: 'all',
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
    };
    if (this.turnServers) {
      // unfortunately a proper url is not supported so we need to parse the url to construct a RTCIceServer
      for (const url of this.turnServers) {
        const iceServer = this.buildRTCIceServer(url);
        if (iceServer) {
          config.iceServers.push(iceServer);
        }
      }
    }
    const thisRoom = this;
    const peerConn = new RTCPeerConnection(config);
    const participant = new Participant(remoteParticipantId, peerConn, isPatient);
    this.remoteParticipants.set(remoteParticipantId, participant);
    console.debug(`added peer connection for ${remoteParticipantId}`);

    // track listener must be added before setting remote description or else we lose events
    peerConn.ontrack = (event) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/track_event
      participant.mediaStream = event.streams[0];
      console.debug("got remote track event", event, participant);

      if (isPatient && event.track.kind === 'video') {
        // detect when video is frozen. note that muted in this context means it's not receiving video
        event.track.onmute = (event) => {
          console.warn(`patient ${remoteParticipantId} video track for ${remoteParticipantId} was muted/frozen`, event);
          thisRoom.patientVideoFrozenAt = Date.now();
          setTimeout(() => {
            console.debug(`checking if video is still frozen... ${thisRoom.patientVideoFrozenAt}`);
            if (thisRoom.patientVideoFrozenAt && Date.now() - thisRoom.patientVideoFrozenAt >= (VIDEO_FREEZE_THRESHOLD_MS - 250)) {
              console.warn(`patient ${remoteParticipantId} video track for ${remoteParticipantId} is still frozen - disconnecting`);
              thisRoom.disconnect("patient-video-frozen");
            } else if (thisRoom.patientVideoFrozenAt) {
              console.info(`patient ${remoteParticipantId} video track for ${remoteParticipantId} is still frozen - assuming another check is coming`)
            } else {
              console.info(`patient ${remoteParticipantId} video track for ${remoteParticipantId} is no longer frozen`);
            }
          }, VIDEO_FREEZE_THRESHOLD_MS);
        }
        event.track.onunmute = (event) => {
          console.info(`patient ${remoteParticipantId} video track for ${remoteParticipantId} was unmuted`, event);
          thisRoom.patientVideoFrozenAt = null;
        }

        console.debug(`added video mute listener for ${remoteParticipantId}`);
      }

      this.emit(VideoRoomManager.EVENT_TYPES.participantTrackAdded, participant);
    }

    if (remoteSdp) {
      console.debug("creating remote sdp...");
      const remoteDesc = new RTCSessionDescription(remoteSdp);
      await peerConn.setRemoteDescription(remoteDesc);
      console.debug("remote description set");
    }

    if (remoteIceCandidates) {
      remoteIceCandidates.forEach((cand) => {
        peerConn.addIceCandidate(cand);
        console.debug(`added ice candidate to ${remoteParticipantId} connection`, cand);
      });
    }

    if (localStream) {
      // got a custom local stream
      participant.localStream = localStream;
      console.info(`set local stream for participant ${remoteParticipantId}`);
    } else {
      // no custom local stream, so load the shared one; does not need to be set on participant
      if (!this.localMediaStream) {
        await this.loadLocalMediaStream(this.localMediaConstraints);
      }
      localStream = this.localMediaStream;
    }

    // no local stream is only needed for "test video device"
    if (localStream) {
      console.info(`adding local media tracks for participant ${remoteParticipantId}`, localStream.getTracks());
      localStream.getTracks().forEach((track) => {
        // need to intially mute local audio or else we get brief audio output
        if (this.roomType === 'monitoring' && track.kind === 'audio') {
          console.debug("muting local audio track");
          track.enabled = false;
        }
        console.debug(`peer ${remoteParticipantId} added local ${track.kind} track (${track.id}))`, localStream);
        peerConn.addTrack(track, localStream);
      });
    }

    peerConn.onconnectionstatechange = () => {
      console.debug(`${participant.participantId} connection state changed to ${peerConn.connectionState}`);
      if (peerConn.connectionState === 'connected') {
        if (statsIntervalMs === 0) {
          console.info('issue detection disabled. stats interval set to 0');
        } else {
          participant.enableIssueDetection(peerConn, (issues) => {
            thisRoom.onWebRTCIssue(issues);
          }, (scores) => {
            thisRoom.onWebRTCNetworkScoresUpdated(scores);
          }, statsIntervalMs);
        }

        thisRoom.emit(VideoRoomManager.EVENT_TYPES.connectSucceeded, thisRoom);
      } else if (peerConn.connectionState === 'disconnected' || peerConn.connectionState === 'failed') {
        console.error(`${participant.participantId} connection unexpectedly closed`);
        this.onParticipantDisconnected(participant, true);
      }
    }
    peerConn.onsignalingstatechange = () => {
      console.debug(`${participant.participantId} signaling state changed to ${peerConn.signalingState}`);
    }
    peerConn.onicecandidateerror = (errEvent) => {
      console.error(`ICE CANDIDATE ERROR: (${errEvent.errorCode}) ${errEvent.errorText}`, errEvent);
    }
    peerConn.oniceconnectionstatechange = () => {
      console.debug(`${participant.participantId} ice connect state changed to ${peerConn.iceConnectionState}`);
    }
    peerConn.onicegatheringstatechange = () => {
      console.debug(`${participant.participantId} ice gather state changed to ${peerConn.iceGatheringState}`);
    }
    peerConn.onnegotiationneeded = () => {
      console.info(`negotiation is needed with ${participant.participantId}`);
    }

    // set up data channel listener. the video device data channel is used to relay signaling messages
    // between browser peers.
    peerConn.ondatachannel = (event) => {
      console.debug("data channel event", event);
      participant.dataChannel = event.channel;
      // add channel event listeners
      participant.dataChannel.onmessage = async (event) => {
        console.debug("data channel message", event);
        const payload = JSON.parse(event.data);
        switch (payload.type) {
          case "generate-offer":
            await this.invitePeerParticipant(payload.peer_id);
            break;
          case "peer-sdp-offer":
            await this.onPeerOffer(payload.sender_peer_id, payload.sdp, payload.ice_candidates, payload.media_request);
            break;
          case "peer-sdp-answer":
            await this.onPeerAnswer(payload.sender_peer_id, payload.sdp, payload.ice_candidates);
            break;
          case "ai-nudge":
            console.info("received ai-nudge", payload);
            this.emit(VideoRoomManager.EVENT_TYPES.nudgeReceived, payload);
            break;
          case "inference":
            console.info("received inference", payload);
            this.emit(VideoRoomManager.EVENT_TYPES.inferenceReceived, payload);
            break;
          case "get-display-info": {
            // a browser peer is requesting this user's display name
            const displayName = this.userDisplayName;
            participant.dataChannel.send(JSON.stringify({
              "type": "display-info-answer",
              displayName,
            }));
            break;
          }
          case "user-bye":
            // assuming the other peer disconnection logic can handle this
            console.info(`received user-bye from ${payload.peer_id}`);
            break;
          default:
            console.error(`unexpected data channel payload type: ${payload.type}`);
        }
      }
      participant.dataChannel.onopen = (event) => {
        console.debug("data channel open", event);
      }
      participant.dataChannel.onclose = (event) => {
        console.debug("data channel closed", event);
      }
    }

    return participant;
  }
}
