import AppInstance from "@/lib/AppInstance";
import { Ability } from "@casl/ability";

export function applyPermissions(permissions) {
  const transformedPermissions = permissions.map( x => {
    x['subject'] = x['resource']
    return x
  })
  AppInstance.shared.config.globalProperties.$ability.update((new Ability(transformedPermissions)).rules)
}
