import Axios from 'axios'
import store from '@/store'
import * as qs from "qs";
import * as Sentry from "@sentry/vue";

const axiosInstance = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1`,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: 'brackets'
    })
  },
  timeout: 15000
});
const queue = []
let errors = []
let loadingThrottleTimer = null, inactivityError = false;
export default function(router) {
  const dequeue = () => {
    queue.pop()
    clearTimeout(loadingThrottleTimer)
    loadingThrottleTimer = setTimeout(() => {
      if (queue.length == 0) {
        store.commit('toggleLoading', { loadingState: false })
        store.commit('setNetworkErrors', errors)
        errors = []
      }
    }, 200)
  }
  axiosInstance.interceptors.request.use((config) => {
    if (inactivityError) {
      throw new Axios.Cancel()
    }
    if (!config.skipLoading) {
      queue.push(1)
      store.commit('toggleLoading', { loadingState: true })
    }
    config.headers['Authorization'] = `Bearer ${store.state.token}`
    config.headers['Session-Id'] = store.state.uniqueSessionId
    if (!Object.hasOwn(config, 'params')) {
      config.params = {}
    }

    if (!config.skipDivisions) {
      let divisionId = '*'
      if (['readonly', 'visible'].includes(store.state.divisionPickerState)) {
        divisionId = config.params.division_id || store.state.divisionId || '*'
      } else {
        divisionId = config.params.division_id || '*'
      }
      if (!router.currentRoute.value.meta.withoutDivision) {
        config.params.division_id = divisionId
      }
    }

    return config
  });
  axiosInstance.interceptors.response.use((response) => {
    if (!response.config.skipLoading) {
      dequeue()
    }
    return response
  }, (error) => {
    if (!(error instanceof Axios.Cancel)) {
      if (error.code == 'ECONNABORTED') {
        Sentry.captureException(error, { tags: { 'error-type': 'timeout' } })
        errors = [error]
      } else if (error?.response?.data?.type == 'Authenticator::InactivityTimeout') {
        if (!inactivityError) {
          errors = [error]
          inactivityError = true
        }
      } else {
        if (!error.config.handleErrors) {
          errors.push(error)
        }
        if (!error.response || !error.response?.data) {
          Sentry.captureException(error, { tags: { 'error-type': 'empty-response' } })
        }
      }
      dequeue()
      return Promise.reject(error)
    }
  })
  return axiosInstance
}
