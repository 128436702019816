<template>
  <div class="filter-autocomplete">
    <b-autocomplete ref="autocomplete" open-on-focus keep-first :model-value="modelValue" @update:modelValue="onModelUpdate" :data="filteredResults" field="name">
      <template #default="{ option }">
        <p @click.stop="dropdownItemClick(option)" class="custom-dropdown-item">{{ option.name }}</p>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import to from "@/lib/to";

export default {
  name: "AutocompleteTableFilter",
  props: ['filter', 'modelValue'],
  computed: {
    filteredResults() {
      if (this.modelValue) {
        return this.endpointResults.filter(result => {
          return result.name.toLowerCase().includes(this.modelValue.toLowerCase())
        })
      } else {
        return this.endpointResults
      }
    },
  },
  methods: {
    dropdownItemClick(option) {
      this.onModelUpdate(option)
      if (this.$refs?.autocomplete) {
        this.$refs.autocomplete.isActive = false
      }
    },
    onModelUpdate(val) {
      this.$emit('update:modelValue', val?.name || val)
    }
  },
  async mounted() {
    const [err, response] = await to(this.$http.get(this.filter.apiEndpoint, { skipDivisions: true, skipLoading: true }))
    if (!err) {
      this.endpointResults = response.data
    }
  },
  data() {
    return {
      endpointResults: [],
    }
  }
}
</script>


<style scoped lang="scss">
  .filter-autocomplete :deep {
    .dropdown-item {
      padding: 0 !important;
    }
    .custom-dropdown-item {
      padding: 0.275rem 1rem;
    }
  }
</style>